import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-developer-webhook',
	templateUrl: './developer-webhook.component.html',
})
export class DeveloperWebhookComponent implements OnInit {

	constructor() { }

	ngOnInit(): void { }

}
