<form>
	<ui-modal modalTitle="Clone Product">
		<ui-form *ngIf="options" labelWidth="10rem">
			<ui-form-control label="SKU">
				<input class="form-control" [(ngModel)]="options.sku" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
			</ui-form-control>
			<ui-form-control label="Model">
				<input class="form-control" [(ngModel)]="options.model" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
			</ui-form-control>
			<ui-form-control label="Short description">
				<input class="form-control" [(ngModel)]="options.short_description" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
			</ui-form-control>
			<ui-form-control label="Long description">
				<textarea rows="5" class="form-control" [(ngModel)]="options.long_description" [ngModelOptions]="{ standalone: true }"></textarea>
			</ui-form-control>
			<hr>
			<ui-form-control label="Options">
				<div class="mt-2"></div>
				<label *ngIf="options.allowed.accessories" class="custom-control custom-checkbox">
					<input type="checkbox" class="custom-control-input" [(ngModel)]="options.clone.accessories" [ngModelOptions]="{ standalone: true }">
					<span class="custom-control-label">Copy accessories</span>
				</label>
				<label *ngIf="options.allowed.bom" class="custom-control custom-checkbox">
					<input type="checkbox" class="custom-control-input" [(ngModel)]="options.clone.bom" [ngModelOptions]="{ standalone: true }">
					<span class="custom-control-label">Copy bill of materials</span>
				</label>
				<label *ngIf="options.allowed.bundle" class="custom-control custom-checkbox">
					<input type="checkbox" class="custom-control-input" [(ngModel)]="options.clone.bundle" [ngModelOptions]="{ standalone: true }">
					<span class="custom-control-label">Copy bundle options</span>
				</label>
				<label *ngIf="options.allowed.labour" class="custom-control custom-checkbox">
					<input type="checkbox" class="custom-control-input" [(ngModel)]="options.clone.labour" [ngModelOptions]="{ standalone: true }">
					<span class="custom-control-label">Copy labour</span>
				</label>
				<label *ngIf="options.allowed.subscription" class="custom-control custom-checkbox">
					<input type="checkbox" class="custom-control-input" [(ngModel)]="options.clone.subscription" [ngModelOptions]="{ standalone: true }">
					<span class="custom-control-label">Copy subscriptions</span>
				</label>
				<label *ngIf="options.allowed.documents" class="custom-control custom-checkbox">
					<input type="checkbox" class="custom-control-input" [(ngModel)]="options.clone.documents" [ngModelOptions]="{ standalone: true }">
					<span class="custom-control-label">Copy documents</span>
				</label>
				<label *ngIf="options.allowed.suppliers" class="custom-control custom-checkbox">
					<input type="checkbox" class="custom-control-input" [(ngModel)]="options.clone.suppliers" [ngModelOptions]="{ standalone: true }">
					<span class="custom-control-label">Copy suppliers</span>
				</label>
				<label *ngIf="options.allowed.counters" class="custom-control custom-checkbox">
					<input type="checkbox" class="custom-control-input" [(ngModel)]="options.clone.counters" [ngModelOptions]="{ standalone: true }">
					<span class="custom-control-label">Copy counters</span>
				</label>
				<label *ngIf="options.allowed.warehouses" class="custom-control custom-checkbox">
					<input type="checkbox" class="custom-control-input" [(ngModel)]="options.clone.warehouses" [ngModelOptions]="{ standalone: true }">
					<span class="custom-control-label">Copy warehouse settings</span>
				</label>
			</ui-form-control>
		</ui-form>

		<button type="button" class="btn btn-medium" (click)="modal.close()" ui-modal-footer>Cancel</button>
		<button type="submit" class="btn btn-primary" (click)="cloneButton()" ui-modal-footer>Save and Clone product</button>
	</ui-modal>
</form>
