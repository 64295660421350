import { Component, ViewChild } from '@angular/core';
import { AppService } from 'app/app.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';
import { ModalEditContactDeliveryAddressComponent } from '../modal-edit-contact-delivery-address/modal-edit-contact-delivery-address.component';

declare const Mangler: any;


@Component({
	selector: 'modal-edit-customer-contact',
	templateUrl: './modal-edit-customer-contact.component.html'
})
export class ModalEditCustomerContactComponent extends UIModalBaseComponent {

	@ViewChild(ModalEditContactDeliveryAddressComponent) editDeliveryAddressModal: ModalEditContactDeliveryAddressComponent;

	data;
	editedDeliveryAddress = null;
	disabled = false;
	editable = true;

	constructor(
		public app: AppService
	) {
		super();
		this.editable = !this.app.orgInfo?.disallow_contact_edit;
	}

	open(mdata: any) {
		this.data = Mangler.clone(mdata);
		this.modal.open();
	}

	saveButton() {
		if (!this.data.contact_first_name && !this.data.contact_surname) {
			this.app.notifications.showDanger('Please enter contact name.');
			return;
		}
		this.modal.close(this.data);
	}

	deleteDeliveryAddress(address) {

		this.app.dialog.pop('Delete Delivery Address', 'Are you sure you want to delete the address ?', ['Cancel', '*Delete'], button => {
			if (button === 1) {
				address.archived = 1;
				this.checkPrimary();
			}
		});
	}

	setPrimary(address) {
		if (!address) return;
		if (!this.editable) return;

		this.data.delivery_address.forEach(c => { c.is_primary = 0; });
		address.is_primary = 1;
		if (address.archived) this.checkPrimary();
	}

	checkPrimary() {
		const primary = Mangler.findOne(this.data.delivery_address, { is_primary: 1 });
		if (!primary || primary.archived) {
			const first = Mangler.findOne(this.data.delivery_address, { archived: 0 });
			if (first) this.setPrimary(first);
		}
	}

	editDeliveryAddress(address) {
		this.editedDeliveryAddress = address;
		this.editDeliveryAddressModal.open(address);
	}

	addDeliveryAddress() {
		this.editedDeliveryAddress = null;
		this.editDeliveryAddressModal.open({
			id: 'new',
			address_line_1: '',
			address_line_2: '',
			address_line_3: '',
			posttown: '',
			county: '',
			postcode: '',
			country: '',
			is_primary: 0,
			archived: 0
		});
	}

	editDeliveryAddressModalClosed(data) {
		if (data) {
			if (this.editedDeliveryAddress) {
				Mangler.merge(this.editedDeliveryAddress, data);
			} else {
				this.data['delivery_address'].push(data);
			}
			this.checkPrimary();
		}
	}

	deleteContact() {
		const contactName = ((this.data.contact_first_name || '') + ' ' + (this.data.contact_surname || '')).trim();

		this.app.dialog.pop('Delete Contact', 'Are you sure you want to delete contact "' + contactName + '"?', ['Cancel', '*Delete'], button => {
			if (button === 1) {
				this.data.archived = 1;
				this.modal.close(this.data);
			}
		});
	}
}

