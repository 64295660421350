import { Component, EventEmitter, OnInit, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { cloneDeep } from 'lodash';

@Component({
	selector: 'app-filter',
	templateUrl: './filter.component.html'
})
export class FilterComponent implements OnInit, OnChanges {

	@Output() filterChanged: EventEmitter<any> = new EventEmitter();
	@Input() filterData;

	attributes = [
		{ id: 'inStock', name: 'In Stock' },
		{ id: 'brands', name: 'Brands' }
	];

	templateCategories = {
		brands: {
			values: [],
			isExpandable: true,
			isExpanded: false,
			defaultEntriesToShow: 5,
			hasSearch: true
		},
		inStock: {
			values: [],
			isExpandable: false,
			isExpanded: false,
			defaultEntriesToShow: 1,
			hasSearch: false
		}
	};
	categories;
	selectedFilters: any = {};
	searchFilters: any = {};
	categoriesBackup;

	ngOnInit(): void {
		this.searchFilters = '';
		this.selectedFilters = {};
		this.categories = cloneDeep(this.templateCategories);
		this.constructFilters();
		this.categoriesBackup = cloneDeep(this.categories);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.filterData && changes.filterData.currentValue) {
			this.constructFilters();
		}
	}

	constructFilters() {
		const brandSet = new Set<string>();
		const inStockSet = new Set<string>();
		this.selectedFilters = {};

		this.filterData.forEach(item => {
			// Populate Brand values
			if (item.manufacturer_name && item.manufacturer_name !== '') {
				brandSet.add(item.manufacturer_name);
			}

			// Populate inStock values
			if (item.supplier_stock && item.supplier_stock.some(stockItem => stockItem.stock !== null && stockItem.stock > 0)) {
				inStockSet.add('Yes');
			}
		});

		this.categories = cloneDeep(this.templateCategories);
		// Convert sets to arrays and populate categories
		this.categories['brands'].values = Array.from(brandSet).map(value => ({ value, isSelected: false }));
		this.categories['inStock'].values = Array.from(inStockSet).map(value => ({ value, isSelected: false }));

		// Remove filters with empty values
		if (this.categories['brands'].values.length === 0) {
			delete this.categories['brands'];
		}
		if (this.categories['inStock'].values.length === 0) {
			delete this.categories['inStock'];
		}

		this.sortFilterValues();
		this.categoriesBackup = cloneDeep(this.categories);

	}

	// Helper method to get object keys
	getObjectKeys(obj) {
		return Object.keys(obj);
	}

	isEmptyObject(obj) {
		return this.getObjectKeys(obj).length === 0;
	}

	// Function to sort filter values in ascending order
	sortFilterValues() {
		this.getObjectKeys(this.categories).forEach(category => {
			this.categories[category].values.sort((a, b) => a.value.localeCompare(b.value));
		});
	}

	// Function to expand all filter values
	expandAllValues(category: string) {
		this.categories[category].isExpanded = true;
	}

	collapseAllValues(category: string) {
		this.categories[category].isExpanded = false;
	}

	filterChange(filter, category) {
		if (!this.selectedFilters[category]) {
			this.selectedFilters[category] = [];
		}

		if (!filter.isSelected) {
			this.selectedFilters[category].push(filter.value);
		} else {
			const indexToRemove = this.selectedFilters[category].indexOf(filter.value);
			if (indexToRemove !== -1) {
				this.selectedFilters[category].splice(indexToRemove, 1);
			}

			if (this.selectedFilters[category].length === 0) {
				delete this.selectedFilters[category];
			}
		}

		this.filterChanged.emit(this.selectedFilters);
	}

	filterSearch(category: string, event: any) {
		const searchTerm = event.target.value.toLowerCase();
		const originalValues = cloneDeep(this.categoriesBackup[category]); // Store original values

		if (searchTerm.trim() === '') {
			this.categories[category].values = [...this.categoriesBackup[category].values]; // Reset to original values
			return;
		}

		this.categories[category].values = [...originalValues.values.filter(filter =>
			filter.value.toLowerCase().includes(searchTerm)
		)];
	}

}
