import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { Pagination } from 'app/shared/pagination';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';
import { Observable, Subject, of, throwError } from 'rxjs';
import { debounceTime, distinctUntilChanged, catchError, switchMap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { SystemService } from '../../system/system.service';

@Component({
	selector: 'app-modal-global-search',
	templateUrl: './modal-global-search.component.html',
	styleUrls: ['./modal-global-search.component.scss']
})
export class ModalGlobalSearchComponent extends UIModalBaseComponent implements OnInit {
	searchQuery: string = '';
	searchResults: string[];
	backupSearchResults: string[] = [];
	recentSearches: string[] = [];
	pagination = new Pagination();
	loading = false;
	content;
	private searchInputSubject = new Subject<string>();
	private searchObservable: Observable<any>;
	@Output() searchResultsChanged: EventEmitter<any> = new EventEmitter();
	showRecentSearches = false;
	tabs: any = {
		products: { id: 'products', title: 'Products', disabled: false, icon: 'wq wq-cart-outline' },
		customers: { id: 'customers', title: 'Customers', disabled: true, icon: 'wq wq-customer' },
		users: { id: 'users', title: 'Users', disabled: true, icon: 'wq wq-user' }
	};

	tabList = [];
	activeTab = 'products';


	constructor(private api: ApiService, private app: AppService, public systemService: SystemService) {
		super();
	}

	ngOnInit(): void {
		this.loadRecentSearches();
		this.tabList.push(this.tabs.products);
		this.tabList.push(this.tabs.customers);
		this.tabList.push(this.tabs.users);
		this.activeTab = 'products';
		this.showRecentSearches = false;
		this.searchResults = [];
		this.searchObservable = this.searchInputSubject
			.pipe(
				debounceTime(1000),
				distinctUntilChanged(),
				switchMap((query: string) => {
					this.searchResults = null;
					const trimmedQuery = this.searchQuery.trim();
					if (trimmedQuery === '') {
						this.searchResults = [];
						return of(null);
					}
					if (trimmedQuery !== '') {
						const index = this.recentSearches.indexOf(trimmedQuery);

						if (index !== -1) {
							this.recentSearches.splice(index, 1);
						}

						this.recentSearches.unshift(trimmedQuery);

						const maxRecentSearches = 5;
						if (this.recentSearches.length > maxRecentSearches) {
							this.recentSearches = this.recentSearches.slice(0, maxRecentSearches);
						}

						localStorage.setItem('recentSearches', JSON.stringify(this.recentSearches));
					}
					return this.performSearch(query);
				}),
				catchError((error) => throwError(error))
			);

		this.searchObservable.subscribe(
			(data) => {
				if (data != null) {
					this.constructDistributorList(data);
				}
				this.backupSearchResults = cloneDeep(this.searchResults);
				this.searchResultsChanged.emit(this.backupSearchResults);
			},
			(error) => {
				this.searchResults = [];
				this.app.notifications.showDanger(error.message);
			}
		);
	}

	onSearchInput(): void {
		this.searchInputSubject.next(this.searchQuery.trim());
	}

	private performSearch(query: string): Observable<any> {
		return new Observable((observer) => {
			this.api.globalSearch.searchProducts(
				query,
				(data) => {
					observer.next(data);
					observer.complete();
				},
				(error) => {
					observer.error(error);
					observer.complete();
				}
			);
		});
	}

	recentSearch(query): void {
		this.searchQuery = query;
		this.searchInputSubject.next(this.searchQuery.trim());
	}

	showRecentSearch() {
		this.showRecentSearches = true;
	}

	hideRecentSearch() {
		this.showRecentSearches = false;
	}

	loadRecentSearches(): void {
		const recentSearchesStr = localStorage.getItem('recentSearches');
		if (recentSearchesStr) {
			this.recentSearches = JSON.parse(recentSearchesStr);
		}
	}

	constructDistributorList(list): void {
		const map = new Map();

		list.searchResults.forEach(item => {
			map.set(item.ean, item);
		});

		list.suppliers.forEach(item => {
			const existingItem = map.get(item.ean);
			if (existingItem) {
				Object.assign(existingItem, item);
			}
		});

		const mergedArray = Array.from(map.values());

		mergedArray.forEach(item => {
			if (item.hasOwnProperty('supplier_stock')) {
				item.supplier_stock = JSON.parse(item.supplier_stock);
				item.supplier_stock.sort((a, b) => b.stock - a.stock || a.sell_price - b.sell_price);
			}
		});
		this.searchResults = mergedArray || [];
	}

	open(_data: any) {
		this.modal.open();
	}

	saveButton() {
		this.modal.close({
			content: this.content
		});
	}

	applyFilters(filterData) {
		this.searchResults = cloneDeep(this.backupSearchResults);
		if (!this.isEmptyObject(filterData)) {
			let filteredData = cloneDeep(this.searchResults);

			const selectedBrands = filterData['brands'] || [];
			if (selectedBrands.length > 0) {
				filteredData = filteredData.filter(item => selectedBrands.includes(item.manufacturer_name));
			}

			const isInStockSelected = filterData['inStock'] && filterData['inStock'].includes('Yes');
			if (isInStockSelected) {
				filteredData = filteredData.filter(item => item.supplier_stock && item.supplier_stock.some(supplier => supplier.stock > 0));
			}
			this.searchResults = cloneDeep(filteredData);
		}

	}
	isEmptyObject(obj) {
		return Object.keys(obj).length === 0;
	}

	addProduct(global, item) {
		this.app.globalSearchAdd.emit({ global, item });
		this.modal.close();
	}

	canAdd() {
		return !!this.app.globalSearchAdd.observers?.length;
	}

}
