import { Component } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

@Component({
  selector: 'app-modal-billing-upgrade-account',
  templateUrl: './modal-billing-upgrade-account.component.html'
})
export class ModalBillingUpgradeAccountComponent  extends UIModalBaseComponent {

	disabled = false;
	currentPackage = null;
	selectedPackage = null;
	data;

	constructor(
		public app: AppService,
		private api: ApiService
	) {
		super();
	}

	open(_mdata: any) {
		this.api.account.listPackages(result => {
			this.data = result;

			this.data.packages.forEach(p => {
				if (p.is_current) this.currentPackage = p;
			});
			this.selectedPackage = this.currentPackage;
			this.modal.open();
		}, error => {
			this.app.notifications.showDanger(error.message);
		});
	}

	upgradeButton() {
		if (this.selectedPackage && this.selectedPackage !== this.currentPackage) {
			this.disabled = true;
			this.api.account.upgradeAccount(this.selectedPackage.id, () => {
				this.disabled = false;
				this.currentPackage = this.selectedPackage;
				this.modal.close(true);
			}, error => {
				this.disabled = false;
				this.app.notifications.showDanger(error.message);
			});
		}
	}

}
