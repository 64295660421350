/**
 * Queue an action to be run at a later time. Each request overrides the last.
 */
export class DeferredAction {

	public count = 0;

	private timer;
	private waiting = false;

	// While in waiting mode, next actions won't be triggered right away, only the last one once wait mode is released.

	private nextDelay;
	private nextCallback;

	constructor() { }

	run(delay, callback) {
		this.cancel();
		this.count += 1;
		if (this.waiting) {
			this.nextDelay = delay;
			this.nextCallback = callback;
		} else {
			this.timer = setTimeout(callback, delay);
		}
		return this.count;
	}

	cancel() {
		clearTimeout(this.timer);
		this.timer = null;
	}

	wait() {
		this.cancel();
		this.waiting = true;
		this.nextDelay = null;
		this.nextCallback = null;
	}

	release() {
		this.waiting = false;
		if (this.nextCallback) {
			this.cancel();
			this.timer = setTimeout(this.nextCallback, this.nextDelay);
			// No need to increment count, as we've already returned it when calling run on the last action.
		}
		this.nextDelay = null;
		this.nextCallback = null;
	}

	isPending() {
		return this.timer || this.waiting;
	}

}
