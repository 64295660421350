import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'; // Adjust import if necessary

@Pipe({
	name: 'localizedDate',
	pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
	constructor(private translateService: TranslateService) { }

	transform(value: Date | string, format = 'mediumDate') {
		const lang = this.translateService.currentLang || 'en-GB';
		const datePipe = new DatePipe(lang);
		return datePipe.transform(value, format);
	}
}
