import { Component, Input, HostBinding } from '@angular/core';

@Component({
	selector: 'ui-icon-feature',
	template: `<i class="{{icon}}"></i>`,
	styles: [
		`:host {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
		}`
	]
})
export class UiIconFeatureComponent {

	@Input() color: string;
	@Input() icon: string;
	@Input() small: boolean = false; // New input to control the size

	@HostBinding('style.width') get width() {
		return this.small ? '2rem' : '3rem';
	}

	@HostBinding('style.height') get height() {
		return this.small ? '2rem' : '3rem';
	}

	@HostBinding('style.fontSize') get fontSize() {
		return this.small ? '1rem' : '1.375rem';
	}

	@HostBinding('class') get classNames() {
		return `bg-${this.color} text-white d-flex align-items-center justify-content-center`;
	}
}
