import { Component, Input } from '@angular/core';

@Component({
	selector: 'ui-label',
	template: `
		<span class="nowrap">
			<svg width="20" height="20" viewBox="0 0 74 50" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" [attr.fill]="label_color"  d="M64.6464 50.0039C65.7988 50.0041 66.9399 49.7772 68.0046 49.3363C69.0693 48.8954 70.0367 48.249 70.8515 47.4341C71.6664 46.6193 72.3128 45.6519 72.7537 44.5872C73.1946 43.5225 73.4215 42.3813 73.4213 41.229L73.4213 9.64307C73.4215 8.49068 73.1946 7.34955 72.7537 6.28486C72.3128 5.22016 71.6664 4.25276 70.8515 3.43789C70.0367 2.62304 69.0693 1.97668 68.0046 1.53575C66.9399 1.09482 65.7988 0.867957 64.6464 0.868105L26.4793 0.868108C25.327 0.868259 24.186 1.09538 23.1215 1.5365C22.057 1.97763 21.0899 2.62412 20.2752 3.43905L2.00068 21.7136C1.01344 22.7009 0.458808 24.0398 0.458807 25.436C0.458812 26.8322 1.01344 28.1712 2.00068 29.1584L20.2777 47.4355C21.0923 48.2504 22.0595 48.8969 23.124 49.338C24.1885 49.7791 25.3295 50.0063 26.4817 50.0064L64.6464 50.0039ZM23.1241 30.429C25.8787 33.1836 30.1222 33.2977 32.991 30.429C35.8622 27.5578 35.8051 23.2572 33.0505 20.5026C30.2389 17.6909 25.9358 17.6314 23.0646 20.5026C20.1958 23.3713 20.3149 27.6198 23.1241 30.429Z" />
			</svg>
			<span class="badge badge-secondary text-uppercase mx-1 p-2" style="color: {{label_color}}; background-color: #EDF0F3; font-size: 0.7rem; margin-bottom: 0.5rem;"> {{ label_text ? label_text : 'Label'}}</span>
		</span>
	`,
	styles: []
})
export class UILabelComponent {
	@Input() label_text: string;
	@Input() label_color: string;
	@Input() max_width: string = '10rem';
}
