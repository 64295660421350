import { Component, ViewChild } from '@angular/core';
import { AppService } from 'app/app.service';
import { MediaLibraryModalComponent } from 'app/media/media-library-modal/media-library-modal.component';
import { ModalSelectProductComponent } from 'app/modal/modal-select-product/modal-select-product.component';
import { BundleOptions } from 'app/shared/bundle-options';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

declare const Mangler: any;

@Component({
	selector: 'modal-edit-bundle-question',
	templateUrl: './modal-edit-bundle-question.component.html',
	styleUrls: ['./modal-edit-bundle-question.component.scss']
})
export class ModalEditBundleQuestionComponent extends UIModalBaseComponent {

	@ViewChild(MediaLibraryModalComponent) mediaLibraryModal;
	@ViewChild(ModalSelectProductComponent) productSelectModal: ModalSelectProductComponent;

	title = '';

	bundle: BundleOptions;
	question: any;

	selectedProduct = null;
	newVersion = false;
	productSelectAction = '';

	layerIterator = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

	constructor(
		public app: AppService
	) {
		super();
	}

	open(data: any) {
		this.bundle = data.bundle;
		this.question = data.question;
		this.title = this.question.question || 'New Question';

		this.selectedProduct = null;
		this.newVersion = false;

		this.modal.open();
	}

	saveButton() {
		if (this.question.question_id) {
			this.bundle.updateQuestion(this.question);
			if (this.newVersion) this.bundle.requireNewVersion();
		} else {
			this.bundle.addQuestion(this.question);
		}

		this.modal.close();
	}

	formatProductNumbers(item) {
		item.quantity = parseFloat(item.quantity) || 0;
		item.question_value = parseInt(item.question_value, 10) || 0;
		item.question_max_value = parseInt(item.question_max_value, 10) || 0;
	}

	formatCounterNumbers(item) {
		item.value = parseInt(item.value, 10) || 0;
	}

	formatQuestionNumbers(item) {
		item.default_value = parseInt(item.default_value, 10) || 0;
		item.min_value = (item.min_value === '' || item.min_value === null) ? null : (parseInt(item.min_value, 10) || 0);
		item.max_value = (item.max_value === '' || item.max_value === null) ? null : (parseInt(item.max_value, 10) || 0);
		item.parent_value = parseInt(item.parent_value, 10) || 0;
		item.parent_max_value = parseInt(item.parent_max_value, 10) || 0;
	}

	removeProduct(item) {
		this.app.dialog.pop('Remove product', 'Are you sure you want to remove product "' + item.short_description + '"?', ['Cancel', '*Delete'], btn => {
			if (btn === 1) {
				const i = this.question.products.indexOf(item);
				if (i !== -1) {
					this.question.products.splice(i, 1);
					this.question.products = this.question.products.slice();
					this.selectedProduct = null;
					this.newVersion = true;
				}
			}
		});
	}

	getQuestionMultiplyDescription(id) {
		if (id === null) return 'x 1';
		if (id === 0 || id === -1 || id === this.question.question_id) return 'x This answer';

		const q = Mangler.findOne(this.bundle.questions, { question_id: id });
		return q ? 'x ' + q.question : '';
	}

	getCondition(product) {
		const type = this.question.type;
		const mode = product.question_mode;
		const value = product.question_value || 0;
		const maxValue = product.question_max_value || 0;
		const field = 'Answer';

		let valueDescription = '';
		let valueList = [];
		let list = [];

		// Types: numeric, select, multi-select, checkbox
		// Modes: set, value, range, lt, gt, all, any

		switch (type) {
			case 'numeric':
				switch (mode) {
					case 'set': return field + ' is not 0';
					case 'value': return field + ' = ' + value;
					case 'range': return field + ' between ' + value + ' and ' + maxValue;
					case 'lt': return field + ' < ' + value;
					case 'gt': return field + ' > ' + value;

					default: return '';
				}

			case 'select':
				list = Mangler.find(this.question.select_options, { $where: o => !!(value & o.value) });
				valueList = list.map(o => '' + o.description);
				valueDescription = valueList.join(', ');

				switch (mode) {
					case 'set': return field + ' is set';
					case 'value': return field + ' is ' + valueDescription;
					case 'any': return field + ' is one of ' + valueDescription;

					default: return '';
				}

			case 'multi-select':
				list = Mangler.find(this.question.select_options, { $where: o => !!(value & o.value) });
				valueList = list.map(o => '' + o.description);
				valueDescription = valueList.join(', ');

				switch (mode) {
					case 'set': return field + ' is set';
					case 'value': return field + ' is exactly ' + valueDescription;
					case 'any': return field + ' has any of ' + valueDescription;
					case 'all': return field + ' has all of ' + valueDescription;

					default: return '';
				}

			case 'checkbox':
				switch (mode) {
					case 'set': return field + ' is checked';
					case 'value': return field + ' is ' + (value ? 'not checked' : 'checked');

					default: return '';
				}

			case 'product-select':
				return 'If selected';

			default:
				return '';
		}
	}

	questionTypeChanged(newType) {
		const oldType = this.question.type;

		this.question.default_value = 0;
		if (!this.question.new_question) this.newVersion = true;

		if (oldType === 'product-select') {
			// As values are product IDs, they need to be reset
			this.question.products.forEach(p => {
				p.question_mode = 'set';
				p.question_value = null;
				p.question_max_value = null;
			});
		} else if (newType === 'product-select') {
			// Set values to product IDs
			this.question.products.forEach(p => {
				p.question_mode = 'value';
				p.question_value = p.product_id;
				p.question_max_value = null;
			});

			// Clear list of options
			this.question.select_options = [];
		}
	}

	deleteSelectOption(o) {
		this.app.dialog.pop('Remove option', 'Are you sure you want to remove option "' + o.description + '"?', ['Cancel', '*Delete'], btn => {
			if (btn === 1) {
				const i = this.question.select_options.indexOf(o);
				if (i !== -1) {
					this.question.select_options.splice(i, 1);
					this.question.select_options = this.question.select_options.slice();
					this.newVersion = true;
				}
			}
		});
	}

	addSelectOption() {
		this.question.select_options.push(this.bundle.getNewQuestionSelectOptionData(this.question));
	}

	getFlag(value, flag) {
		return !!(value & flag);
	}

	setFlag(value, flag) {
		return value | flag;
	}

	unsetFlag(value, flag) {
		if (this.getFlag(value, flag)) return value - flag;
	}

	toggleFlag(value, flag) {
		if (this.getFlag(value, flag)) {
			return this.unsetFlag(value, flag);
		} else {
			return this.setFlag(value, flag);
		}
	}

	optionsDrop(event) {
		// Update data model
		const previousIndex = event.previousIndex;
		const currentIndex = event.currentIndex;

		if (previousIndex === currentIndex) return; // No change

		const item = this.question.select_options.splice(previousIndex, 1)[0];
		this.question.select_options.splice(currentIndex, 0, item);

		// Update display orders
		let i = 0;
		this.question.select_options.forEach(o => o.display_order = ++i);
	}

	removeImage() {
		this.question.image_id = null;
		this.question.image_url = null;
	}

	openMediaLibrary() {
		this.mediaLibraryModal.open({ type: 'product' });
	}

	mediaLibraryModalClosed(data) {
		if (data) {
			this.question.image_id = data.id;
			this.question.image_url = data.url;
		}
	}

	useProduct() {
		this.productSelectAction = 'useProduct';
		this.productSelectModal.open({
			is_bundle: 0
		});
	}

	addProducts() {
		this.productSelectAction = 'addProducts';
		this.productSelectModal.open({
			is_bundle: 0,
			multi_select: true
		});
	}

	getImageFromProduct() {
		this.productSelectAction = 'getImageFromProduct';
		this.productSelectModal.open({});
	}

	productSelectModalClosed(data) {
		let found;

		if (data) {
			switch (this.productSelectAction) {
				case 'addProducts':
					// Add products to question
					data.forEach(item => {
						found = !!Mangler.findOne(this.question.products, { product_id: item.id });
						if (!found) {
							this.question.products.push(this.bundle.getNewQuestionProductData(this.question, item));
							this.question.products = this.question.products.slice();
						}
					});
					break;

				case 'getImageFromProduct':
					// Get image from product
					this.question.image_id = data.image_id;
					this.question.image_url = data.image_url;
					break;

				case 'useProduct':
					const desc = [];
					if (data.sku) desc.push(data.sku);
					if (data.short_description) desc.push(data.short_description);

					this.question.question = desc.join(' - ');
					this.question.image_id = data.image_id;
					this.question.image_url = data.image_url;

					found = !!Mangler.findOne(this.question.products, { product_id: data.id });
					if (!found) {
						this.question.products.push(this.bundle.getNewQuestionProductData(this.question, data));
						this.question.products = this.question.products.slice();
					}

					break;
			}
		}
	}

	filenameFromUrl(url) {
		return url.split('/').pop();
	}

}
