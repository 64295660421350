<ui-grid-col *ngIf="app.routeData.navigation === 'main'" spaced class="mr-0 no-print">
	<ui-card widget style="width: 13.75rem;">
		<div class="m-nc navigation mb-4">
			<table *ngIf="app.org" class="table table-hover no-border">
				<tbody>
					<ng-container *ngFor="let item of getNavigation('main')">
						<tr *ngIf="item.type === 'header'">
							<td class="navigation-header">
								{{item.description}}
							</td>
						</tr>
						<tr *ngIf="item.type === 'link'">
							<td class="navigation-link" [ngClass]="{ 'active': isModuleSelected(item.path) }">
								<a [routerLink]="['/' + app.org, item.path]" [appTutorial]="getNavigationTutorialStep(item.path)">
									<i class="{{item.icon}} mr-2"></i>
									<span><b>{{item.description}}</b></span>
								</a>
							</td>
						</tr>
						<ng-container *ngIf="app.routeData.module === item.path && item.items">
							<tr *ngFor="let subitem of item.items; let first = first; let last = last" class="bg-white">
								<td class="navigation-subitem" [ngClass]="{ 'active': isPathSelected(subitem.path), 'first': first, 'last': last }">
									<a [routerLink]="subitem.path ? ['/' + app.org, item.path, subitem.path] : ['/' + app.org, item.path]" [appTutorial]="getNavigationTutorialStep(item.path + '/' + subitem.path)">
										<span>{{subitem.description}}</span>
									</a>
								</td>
							</tr>
						</ng-container>
					</ng-container>
				</tbody>
			</table>
		</div>
	</ui-card>
</ui-grid-col>

<ui-grid-col *ngIf="app.routeData.navigation === 'settings'" spaced class="mr-0 no-print">
	<ui-card widget style="width: 13.75rem;">
		<div class="m-nc navigation mb-4">
			<table *ngIf="app.org" class="table table-hover no-border">
				<tbody>
					<tr>
						<td class="navigation-link">
							<a [routerLink]="['/' + app.org]">
								<i class="wq wq-chevron-left mr-2"></i>
								<span><b>Back to WeQuote</b></span>
							</a>
						</td>
					</tr>
					<ng-container *ngFor="let item of getNavigation('settings')">
						<tr *ngIf="item.type === 'header'">
							<td class="navigation-header">
								{{item.description}}
							</td>
						</tr>
						<tr *ngIf="item.type === 'link'">
							<td class="navigation-link" [ngClass]="{ 'active': isPathSelected(item.path) }">
								<a [routerLink]="['/' + app.org, 'settings', item.path]">
									<i class="{{item.icon}} mr-2"></i>
									<span><b>{{item.description}}</b></span>
								</a>
							</td>
						</tr>
						<ng-container *ngIf="isPathSelected(item.path) && item.items">
							<tr *ngFor="let subitem of item.items; let first = first; let last = last" class="bg-white">
								<td class="navigation-subitem" [ngClass]="{ 'active': isSubpathSelected(subitem.path), 'first': first, 'last': last }">
									<a [routerLink]="subitem.path ? ['/' + app.org, 'settings', item.path, subitem.path] : ['/' + app.org, 'settings', item.path]">
										<span>{{subitem.description}}</span>
									</a>
								</td>
							</tr>
						</ng-container>
					</ng-container>
				</tbody>
			</table>
		</div>
	</ui-card>
</ui-grid-col>

<ui-grid-col *ngIf="app.routeData.navigation === 'quote'" spaced class="mr-0 no-print">
	<ui-card widget style="width: 5.625rem;">
		<div *ngIf="app.org" class="mx-n2 d-flex flex-column mb-4">
			<ng-container *ngFor="let item of getNavigation('quote'); let last;">
				<a *ngIf="item.path" class="py-2 text-center" [routerLink]="['/' + app.org, 'quote', app.routeParams.quote, item.path]" [replaceUrl]="true" (mouseenter)="hover = item" (mouseleave)="hover = null" [appTutorial]="getQuoteTutorialStep(item.path)">
					<i class="{{item.icon}} h1" [ngClass]="{ 'text-primary': hover === item || app.routeData.path === item.path, 'text-muted': !(hover === item || app.routeData.path === item.path) }"></i><br>
					<span class="d-inline-block text-tiny mt-2" [ngClass]="{ 'text-primary': hover === item || app.routeData.path === item.path, 'text-dark': !(hover === item || app.routeData.path === item.path) }">{{item.description}}</span>
				</a>
			</ng-container>
			<a *ngIf="app.routeParams.quote" class="py-2 text-center selectable position-relative" (mouseenter)="hover = 'notes'" (mouseleave)="hover = null" (click)="openQuoteNotes()">
				<i class="wq wq-notes h1" [ngClass]="{ 'text-primary': hover === 'notes', 'text-muted': hover !== 'notes' }"></i><br>
				<span class="d-inline-block text-tiny mt-2" [ngClass]="{ 'text-primary': hover === 'notes', 'text-dark': hover !== 'notes' }">Notes and Documents</span>
				<span *ngIf="quoteNoteCount" class="badge badge-primary count-badge p-1 rounded-circle">{{quoteNoteCount}}</span>
			</a>
		</div>
	</ui-card>
</ui-grid-col>

<ui-grid-col *ngIf="app.routeData.navigation === 'project'" spaced class="mr-0 no-print">
	<ui-card widget style="width: 5.625rem;">
		<div *ngIf="app.org" class="mx-n2 d-flex flex-column mb-4">
			<ng-container *ngFor="let item of getNavigation('project'); let last;">
				<a *ngIf="item.path" class="py-2 text-center" [routerLink]="['/' + app.org, 'project', app.routeParams.project, item.path]" [replaceUrl]="true" (mouseenter)="hover = item" (mouseleave)="hover = null">
					<i class="{{item.icon}} h1" [ngClass]="{ 'text-primary': hover === item || app.routeData.path === item.path, 'text-muted': !(hover === item || app.routeData.path === item.path) }"></i><br>
					<span class="d-inline-block text-tiny mt-2" [ngClass]="{ 'text-primary': hover === item || app.routeData.path === item.path, 'text-dark': !(hover === item || app.routeData.path === item.path) }">{{item.description}}</span>
				</a>
			</ng-container>
			<a *ngIf="app.routeParams.project" class="py-2 text-center selectable position-relative" (mouseenter)="hover = 'exporter'" (mouseleave)="hover = null" (click)="openProjectExporter()">
				<i class="wq wq-export h1" [ngClass]="{ 'text-primary': hover === 'exporter', 'text-muted': hover !== 'exporter' }"></i><br>
				<span class="d-inline-block text-tiny mt-2" [ngClass]="{ 'text-primary': hover === 'exporter', 'text-dark': hover !== 'exporter' }">Export</span>
			</a>
			<a *ngIf="app.routeParams.project" class="py-2 text-center selectable position-relative" (mouseenter)="hover = 'notes'" (mouseleave)="hover = null" (click)="openProjectNotes()">
				<i class="wq wq-notes h1" [ngClass]="{ 'text-primary': hover === 'notes', 'text-muted': hover !== 'notes' }"></i><br>
				<span class="d-inline-block text-tiny mt-2" [ngClass]="{ 'text-primary': hover === 'notes', 'text-dark': hover !== 'notes' }">Notes and Documents</span>
				<span *ngIf="projectNoteCount" class="badge badge-primary count-badge p-1 rounded-circle">{{projectNoteCount}}</span>
			</a>
		</div>
	</ui-card>
</ui-grid-col>

<modal-activity-log></modal-activity-log>
<modal-project-exporter></modal-project-exporter>
