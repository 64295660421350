import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
	selector: 'modal-alert-popup',
	templateUrl: './modal-alert-popup.component.html',
	styleUrls: ['./modal-alert-popup.component.scss'],
	animations: [
		trigger('fadeInOut', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('300ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				animate('300ms', style({ opacity: 0 })),
			]),
		]),
	],
})

export class ModalAlertPopupComponent extends UIModalBaseComponent {

	message = '';
	disabled = false;
	data = null;
	currentIndex = 0;
	alert;

	constructor(
		private api: ApiService,
		public app: AppService,
		private router: Router
	) {
		super();
	}

	open(data: any = null) {
		this.data = data;
		this.alert = this.data[0];
		this.modal.open();
		this.dismissAlert(this.alert.id);
	}

	openButton(button_url) {
		window.open(button_url, '_blank');
	}

	nextAlert() {
		if (this.currentIndex < this.data.length - 1) {
			this.currentIndex++;
			this.alert = this.data[this.currentIndex];
			if (!this.alert.dissmissed) this.dismissAlert(this.alert.id);
		}
	}

	prevAlert() {
		if (this.currentIndex > 0) {
			this.currentIndex--;
			this.alert = this.data[this.currentIndex];
		}
	}

	dismissAlert(id) {
		this.api.alert.dismiss(id, () => {
			this.api.refreshAlerts();
		});
	}

	dismissAllByLocation() {
		this.app.dialog.pop('Dismiss All Alerts', 'Are you sure you want to dismiss all alerts? You can view all notifications by clicking the bell icon in the top right', ['Cancel', '*Dismiss'], button => {
			if (button === 1) {
				this.disabled = true;
				this.api.alert.dismissAllByLocation(this.app.routeData?.id, () => {
					this.api.refreshAlerts();
					this.disabled = false;
					this.app.notifications.showSuccess('All Alerts Dismissed.');
					this.modal.close();
				}, error => {
					this.disabled = false;
					this.app.notifications.showDanger(error.message);
				});
			}
		});
	}

	viewAllAlerts() {
		this.router.navigate(['/', this.app.org, 'alert']);
	}

	goToAlert(index: number) {
		this.currentIndex = index;
		this.alert = this.data[this.currentIndex];
		if (!this.alert.dissmissed) this.dismissAlert(this.alert.id);
	}

}
