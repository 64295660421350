<div class="mr-3">
	<ui-grid-row>
		<ui-grid-col>
			<h1 class="pb-1">Customer</h1>
		</ui-grid-col>
	</ui-grid-row>
	<h2 class="pt-2 pb-2">Requests</h2>
	<ui-grid-row>
		<ui-grid-col>
			<ui-card widget header="customer/list">
				<div>
					<i>List all customers created by the organisation</i>
				</div>
				<div class="pt-2">
					<hr>
					<h4>Request</h4>
					<table>
						<tr>
							<td class="p-3 border-right">
								type
							</td>
							<td class="pl-2">GET</td>
						</tr>
						<tr>
							<td class="p-3 border-right">
								parameters
							</td>
							<td class="pl-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2 pr-2 border-right">Required</th>
										<th class="pl-2, pr-2 text-center">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">page</td>
										<td class="pl-2 pr-2 border-right">number</td>
										<td class="pl-2 pr-2 border-right">false</td>
										<td class="pl-3 pr-2 text-left">The list function supports paging and will only show 10 results at a time. Use the <code>page</code> parameter to see more results</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td class="pl-3 border-right">
								response
							</td>
							<td class="pl-2">
								Responses from this endpoint include a list of basic customer information, sorted by most recent first, 10 per page. For a mode detailed list use <code>customer/get</code>
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2, pr-2 text-center ">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">id</td>
										<td class="pl-2 pr-2 text-center border-right">number</td>
										<td class="pl-3 pr-2 text-left">The id number of your newly created customer</td>
									</tr>

									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">name</td>
										<td class="pl-2 pr-2 border-right">string</td>
										<td class="pl-3 pr-2 text-left">The Customer's Name</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">account_no</td>
										<td class="pl-2 pr-2 border-right">string</td>
										<td class="pl-3 pr-2 text-left">Your account number for the customer. WeQuote requires this to find customers on some 3rd party services</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td class="p-3 border-right">
								Errors
							</td>
							<td class="pl-2">
								Please see the errors section in <a href="/developer">getting started</a> for more information
							</td>
						</tr>
					</table>

				</div>
			</ui-card>
		</ui-grid-col>
	</ui-grid-row>

	<ui-grid-row class="pt-4">
		<ui-grid-col>
			<ui-card widget header="customer/create">
				<div>
					<i>Create a customer with the supplied information</i>
				</div>
				<div class="pt-2">
					<hr>
					<h4>Request</h4>
					<table>
						<tr>
							<td class="p-3 border-right">
								type
							</td>
							<td class="pl-2">POST</td>
						</tr>
						<tr>
							<td class="p-3 border-right">
								parameters
							</td>
							<td class="pl-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2 pr-2 border-right">Required</th>
										<th class="pl-2, pr-2 text-center">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">name</td>
										<td class="pl-2 pr-2 border-right">string</td>
										<td class="pl-2 pr-2 border-right">true</td>
										<td class="pl-3 pr-2 text-left">The Customer's Name</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">account_no</td>
										<td class="pl-2 pr-2 border-right">string</td>
										<td class="pl-2 pr-2 border-right">false</td>
										<td class="pl-3 pr-2 text-left">Your account number for the customer.</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">address_line_1<br>
											address_line_2<br>
											address_line_3<br>
											postcode<br>
											posttown<br>
											county<br>
											country</td>
										<td class="pl-2 pr-2 border-right">string</td>
										<td class="pl-2 pr-2 border-right">false</td>
										<td class="pl-3 pr-2 text-left">Your customers Address</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">phone_number</td>
										<td class="pl-2 pr-2 border-right">string</td>
										<td class="pl-2 pr-2 border-right">false</td>
										<td class="pl-3 pr-2 text-left">Your Customer's Contact phone number</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">email_address</td>
										<td class="pl-2 pr-2 border-right">string</td>
										<td class="pl-2 pr-2 border-right">false</td>
										<td class="pl-3 pr-2 text-left">The Customer's contact email address</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">website_url</td>
										<td class="pl-2 pr-2 border-right">string</td>
										<td class="pl-2 pr-2 border-right">false</td>
										<td class="pl-3 pr-2 text-left">The URL of the customer's website</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">notes</td>
										<td class="pl-2 pr-2 border-right">string</td>
										<td class="pl-2 pr-2 border-right">false</td>
										<td class="pl-3 pr-2 text-left">Any internal notes you want to send to WeQuote while creatig the customer. Only your organisation can read this</td>
									</tr>

								</table>
							</td>
						</tr>
						<tr>
							<td class="pl-3 border-right">
								response
							</td>
							<td class="pl-2">
								Responses from this endpoint include all the fields sent in your request in addition to the fields listed below.
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2, pr-2 text-center ">Description</th>
									</tr>
									<tr class="border-bottom text-left">
										<td class="pr-2 border-right">id</td>
										<td class="pl-2 pr-2 text-center border-right">number</td>
										<td class="pl-3 pr-2 text-left">The id number of your newly created customer</td>
									</tr>
									<tr class="border-bottom text-left">
										<td class="pr-2 border-right">user_id</td>
										<td class="pl-2 pr-2 text-center border-right">number</td>
										<td class="pl-3 pr-2 text-left">The id number of the user who created the customer</td>
									</tr>
									<tr class="border-bottom text-left">
										<td class="pr-2 border-right">organisation_id</td>
										<td class="pl-2 pr-2 text-center border-right">number</td>
										<td class="pl-3 pr-2 text-left">The id number of your organisation</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td class="p-3 border-right">
								Errors
							</td>
							<td class="pl-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Code</th>
										<th class="pl-2 pr-2 border-right">Text</th>
										<th class="pl-2, pr-2 text-center">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right"></td>
										<td class="pl-2 pr-2 border-right">Please provide a name for the customer</td>
										<td class="pl-3 pr-2 text-left">Your request's POST body did not include a JSON object with the <code>name</code> parameter</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right"></td>
										<td class="pl-2 pr-2 border-right">Please provide an account number for the customer</td>
										<td class="pl-3 pr-2 text-left">Your request's POST body did not include a JSON object with the <code>account_no</code> parameter</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right"></td>
										<td class="pl-2 pr-2 border-right">Could not create customer</td>
										<td class="pl-3 pr-2 text-left">Something unexpected has gone wrong. This is most likely an internal server error. Please reach out to us for extra support</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>

				</div>
			</ui-card>
		</ui-grid-col>
	</ui-grid-row>

	<ui-grid-row class="pt-4">
		<ui-grid-col>
			<ui-card widget header="customer/find">
				<div>
					<i>find a customer with the supplied information</i>
				</div>
				<div class="pt-2">
					<hr>
					<h4>Request</h4>
					<table>
						<tr>
							<td class="p-3 border-right">
								type
							</td>
							<td class="pl-2">GET</td>
						</tr>
						<tr>
							<td class="p-3 border-right">
								parameters
							</td>
							<td class="pl-2">
								If both parameters are used, WeQuote will only return results that match both parameters.
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2 pr-2 border-right">Required</th>
										<th class="pl-2, pr-2 text-center">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">name</td>
										<td class="pl-2 pr-2 border-right">string</td>
										<td class="pl-2 pr-2 border-right">false</td>
										<td class="pl-3 pr-2 text-left">The name you supplied for the customer.</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">account_no</td>
										<td class="pl-2 pr-2 border-right">string</td>
										<td class="pl-2 pr-2 border-right">false</td>
										<td class="pl-3 pr-2 text-left">Your account number for the customer.</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td class="pl-3 border-right">
								response
							</td>
							<td class="pl-2">
								Responses from this endpoint include all the fields sent in your request in addition to the fields listed below.
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2, pr-2 text-center ">Description</th>
									</tr>
									<tr class="border-bottom text-left">
										<td class="pr-2 border-right">Contact Information</td>
										<td class="pl-2 pr-2 text-center border-right">JSON</td>
										<td class="pl-3 pr-2 text-left">If a customer is found, WeQuote will respond with a JSON object of the customer, with the same information uncluded in the response section of customer/create. Otherwise WeQuote will simply return an empty array</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td class="p-3 border-right">
								Errors
							</td>
							<td class="pl-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Code</th>
										<th class="pl-2 pr-2 border-right">Text</th>
										<th class="pl-2, pr-2 text-center">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right"></td>
										<td class="pl-2 pr-2 border-right">Please provide an account number or id</td>
										<td class="pl-3 pr-2 text-left">Your request's GET parameters did not include an <code>id</code> or <code>account_no</code> in the URL</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>
				</div>
			</ui-card>
		</ui-grid-col>
	</ui-grid-row>

	<ui-grid-row class="pt-4">
		<ui-grid-col>
			<ui-card widget header="customer/get">
				<div>
					<i>Retrieve a customer's information with their ID</i>
				</div>
				<div class="pt-2">
					<hr>
					<h4>Request</h4>
					<table>
						<tr>
							<td class="p-3 border-right">
								type
							</td>
							<td class="pl-2">GET</td>
						</tr>
						<tr>
							<td class="p-3 border-right">
								parameters
							</td>
							<td class="pl-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2 pr-2 border-right">Required</th>
										<th class="pl-2, pr-2 text-center">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">id</td>
										<td class="pl-2 pr-2 border-right">string</td>
										<td class="pl-2 pr-2 border-right">true</td>
										<td class="pl-3 pr-2 text-left">WeQuote's internal ID for the customer</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td class="pl-3 border-right">
								response
							</td>
							<td class="pl-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2, pr-2 text-center ">Description</th>
									</tr>
									<tr class="border-bottom text-left">
										<td class="pr-2 border-right">Contact Information</td>
										<td class="pl-2 pr-2 text-center border-right">JSON</td>
										<td class="pl-3 pr-2 text-left">If a customer is found, WeQuote will respond with a JSON object of the customer, with the same information uncluded in the response section of customer/create. Otherwise WeQuote will simply return an error</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td class="p-3 border-right">
								Errors
							</td>
							<td class="pl-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Code</th>
										<th class="pl-2 pr-2 border-right">Text</th>
										<th class="pl-2, pr-2 text-center">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right"></td>
										<td class="pl-2 pr-2 border-right">Please provide id</td>
										<td class="pl-3 pr-2 text-left">Your request's GET parameters did not include an <code>id</code> in the URL</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right"></td>
										<td class="pl-2 pr-2 border-right">Customer not found</td>
										<td class="pl-3 pr-2 text-left">The ID in your request did not relate to a WeQuote customer</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right"></td>
										<td class="pl-2 pr-2 border-right">Customer belongs to another organisation</td>
										<td class="pl-3 pr-2 text-left">The customer with the ID you provided does not belong to your organisation</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>
				</div>
			</ui-card>
		</ui-grid-col>
	</ui-grid-row>

</div>
