import { Component } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { DecimalPipe } from 'app/shared/decimal.pipe';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

declare const Mangler: any;

@Component({
	selector: 'modal-edit-pricing-structure',
	templateUrl: './modal-edit-pricing-structure.component.html'
})
export class ModalEditPricingStructureComponent extends UIModalBaseComponent {

	id;
	details;
	linkCount = 0;
	disabled = false;

	sample: any = {
		cost_price: 100.00,
		srp_price: 200.00,
		sell_price: 0,
		sell_message: ''
	};

	recordEditor = false;

	constructor(
		private app: AppService,
		private api: ApiService,
	) {
		super();
	}

	open(mdata: any) {
		this.id = mdata.id || 'new';
		this.details = null;
		this.linkCount = 0;
		this.disabled = false;
		this.sample = {
			cost_price: 100.00,
			srp_price: 200.00,
			sell_price: 0,
			sell_message: ''
		};

		if (mdata.record) {
			this.details = mdata.record;
			this.recordEditor = true;
			this.formatNumbers();
			this.modal.open();
			return;
		} else {
			this.recordEditor = false;
		}

		const success = data => {
			this.details = data.details || {};
			this.linkCount = data.product_count || 0;
			this.formatNumbers();
			this.modal.open();
		};

		const fail = error => {
			this.app.notifications.showDanger(error.message);
		};

		if (this.id === 'new') {
			this.api.product.newPricingStructure(success, fail);
		} else {
			this.api.product.getPricingStructure(this.id, success, fail);
		}
	}

	formatNumbers(addThousandSeparators: boolean = true) {
		this.details.pricing_value = DecimalPipe.transform(this.details.pricing_value, 2, 4, addThousandSeparators);
		this.details.decimal_places = DecimalPipe.transform(this.details.decimal_places, 0, 0, addThousandSeparators);
		this.details.minimum_price = DecimalPipe.transform(this.details.minimum_price, 2, 4, addThousandSeparators);

		this.sample.cost_price = DecimalPipe.transform(this.sample.cost_price, 2, 4, addThousandSeparators);
		this.sample.srp_price = DecimalPipe.transform(this.sample.srp_price, 2, 4, addThousandSeparators);

		this.refreshSample(addThousandSeparators);
	}

	refreshSample(addThousandSeparators: boolean = true) {
		let cost = DecimalPipe.parse(this.sample.cost_price);
		if (this.details.base_price === 'srp_price') cost = DecimalPipe.parse(this.sample.srp_price);

		const method = this.details.pricing_method;
		const value = DecimalPipe.parse(this.details.pricing_value);
		const decimalPlaces = DecimalPipe.parse(this.details.decimal_places);
		const minimum = DecimalPipe.parse(this.details.minimum_price);

		let price = 0;
		let message = '';

		switch (method) {
			case 'custom':
				message = 'Custom';
				break;

			case 'bom':
				message = 'BOM';
				break;

			case 'markup':
				price = cost * (1 + value / 100);
				break;

			case 'margin':
				if (value >= 100) {
					message = 'Invalid margin';
				} else {
					price = cost / (1 - (value / 100));
				}
				break;

			case 'profit':
				price = cost + value;
				break;
		}

		if (method !== 'custom') {
			price *= Math.pow(10, decimalPlaces);
			price = Math.round(price);
			price /= Math.pow(10, decimalPlaces);
		}

		if (method !== 'custom' && method !== 'bom') price = Math.max(price, minimum);

		this.sample.sell_price = DecimalPipe.transform(price, 2, 4, addThousandSeparators);
		this.sample.sell_message = message;
	}

	save() {
		this.formatNumbers(false);
		const pricingDetails = Mangler.clone(this.details);
		this.formatNumbers();

		if (this.recordEditor) {
			this.modal.close(pricingDetails);
			return;
		}

		this.disabled = true;
		this.api.product.savePricingStructure(pricingDetails, savedId => {
			this.disabled = false;
			if (this.details.id === 'new') {
				this.app.notifications.showSuccess('Pricing level created.');
			} else {
				this.app.notifications.showSuccess('Pricing level updated.');
			}
			this.modal.close(savedId);
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

	delete() {
		if (this.id === 'new') return;

		this.disabled = true;
		this.api.product.deletePricingStructure(this.id, () => {
			this.disabled = false;
			this.app.notifications.showSuccess('Pricing level deleted.');
			this.modal.close('deleted');
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

}
