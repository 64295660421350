import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Pagination } from '../pagination';

@Component({
	selector: 'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

	@Input() pagination: Pagination;

	@Output() navigate = new EventEmitter();

	prevPage() {
		const oldPage = this.pagination.page;
		this.pagination.prevPage();
		const newPage = this.pagination.page;
		if (oldPage !== newPage) this.navigate.emit(this.pagination.page);
	}

	nextPage() {
		const oldPage = this.pagination.page;
		this.pagination.nextPage();
		const newPage = this.pagination.page;
		if (oldPage !== newPage) this.navigate.emit(this.pagination.page);
	}

	clickPage(page) {
		const oldPage = this.pagination.page;
		this.pagination.page = page;
		const newPage = this.pagination.page;
		if (oldPage !== newPage) this.navigate.emit(this.pagination.page);
	}

	changePageSize(size) {
		if (size === this.pagination.pageSize) return;
		this.pagination.pageSize = size;
		this.navigate.emit(this.pagination.page);
	}

}
