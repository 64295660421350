import { Component, ViewChild } from '@angular/core';
import { AppService } from 'app/app.service';
import { ModalSelectProductComponent } from 'app/modal/modal-select-product/modal-select-product.component';
import { BundleOptions } from 'app/shared/bundle-options';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

@Component({
	selector: 'modal-edit-bundle-counter',
	templateUrl: './modal-edit-bundle-counter.component.html'
})
export class ModalEditBundleCounterComponent extends UIModalBaseComponent {

	@ViewChild(ModalSelectProductComponent) productSelectModal: ModalSelectProductComponent;

	title = '';
	bundle: BundleOptions;
	counter: any;

	newVersion = false;

	constructor(
		public app: AppService
	) {
		super();
	}

	open(data: any) {
		this.bundle = data.bundle;
		this.counter = data.counter;
		this.title = this.counter.description;
		this.newVersion = false;
		this.modal.open();
	}

	saveButton() {
		if (this.counter.counter_id) {
			this.bundle.updateCounter(this.counter);
			if (this.newVersion) this.bundle.requireNewVersion();
		} else {
			this.bundle.addCounter(this.counter);
		}
		this.modal.close();
	}

	formatProductNumbers(item) {
		item.quantity = parseFloat(item.quantity) || 0;
		item.range_start = parseInt(item.range_start, 10) || 0;
		item.range_end = parseInt(item.range_end, 10) || 0;
	}

	removeProduct(item) {
		this.app.dialog.pop('Remove product', 'Are you sure you want to remove product "' + item.short_description + '"?', ['Cancel', '*Delete'], btn => {
			if (btn === 1) {
				const i = this.counter.products.indexOf(item);
				if (i !== -1) {
					this.counter.products.splice(i, 1);
					this.counter.products = this.counter.products.slice();
					this.newVersion = true;
				}
			}
		});
	}

	addProducts() {
		this.productSelectModal.open({
			is_bundle: 0,
			multi_select: true
		});
	}

	productSelectModalClosed(data) {
		if (data) {
			data.forEach(product => {
				this.counter.products.push(this.bundle.getNewCounterProductData(this.counter, product));
				this.counter.products = this.counter.products.slice();
			});
		}
	}

}
