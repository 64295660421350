import { Component, ViewChild } from '@angular/core';
import { AlertEmbedListComponent } from 'app/alert/alert-embed-list/alert-embed-list.component';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { CoreFeedbackModalComponent } from '../core-feedback-modal/core-feedback-modal.component';
import { CoreKnowledgebaseModalComponent } from '../core-knowledgebase-modal/core-knowledgebase-modal.component';
import { ModalGlobalSearchComponent } from 'app/modal/modal-global-search/modal-global-search.component';
import { ModalAlertPopupComponent } from 'app/modal/modal-alert-popup/modal-alert-popup.component';

@Component({
	selector: 'app-core-header',
	templateUrl: './core-header.component.html'
})
export class CoreHeaderComponent {

	@ViewChild(CoreFeedbackModalComponent) feedbackModal;
	@ViewChild(CoreKnowledgebaseModalComponent) knowledgeBaseModal;
	@ViewChild(AlertEmbedListComponent) alertList: AlertEmbedListComponent;
	@ViewChild(ModalGlobalSearchComponent) globalSearchModal: ModalGlobalSearchComponent;
	@ViewChild(ModalAlertPopupComponent) alertPopupModal: ModalAlertPopupComponent;

	showPaymentWarning = true;

	constructor(
		public app: AppService,
		private api: ApiService
	) { }

	showFeedbackModal() {
		this.feedbackModal.open();
	}

	showKnowledgebaseModal() {
		this.knowledgeBaseModal.open();
	}

	openAlerts() {
		this.alertList.refresh();
	}

	deleteSampleData() {
		let content = '<p>Your trial account comes with a set of sample quotes, systems and a demo catalogue. These will be automatically removed when you activate your account.</p>';
		content += '<p>You can choose to remove these any time if you\'d like to start putting your live data into the system. Doing so will also cancel any active tutorials for your account.</p>';
		content += '<p class="text-bold">Do you want to delete your sample data now?</p>';

		this.app.dialog.popHtml('Delete Sample Data', content, ['Cancel', '*Delete Sample Data'], btn => {
			if (btn === 1) {
				this.api.account.cleanUp('sample', () => {
					this.app.notifications.showSuccess('Sample data deleted.');
					window.location.reload();
				}, error => {
					this.app.notifications.showDanger(error.message);
				});
			}
		});
	}

	showGlobalSearchModal(data) {
		this.globalSearchModal.open(data);
	}

}
