import { Component } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

@Component({
	selector: 'app-alert-edit-modal',
	templateUrl: './alert-edit-modal.component.html',
	styleUrls: ['./alert-edit-modal.component.scss']
})
export class AlertEditModalComponent extends UIModalBaseComponent {

	id;
	data;
	title;
	accountType;

	disabled = false;
	routesList = [];

	constructor(
		public app: AppService,
		private api: ApiService,
	) {
		super();
	}

	open(id: any) {
		this.id = id;
		this.data = null;

		if (this.id === 'new') {
			this.api.alert.new(data => {
				this.data = data;
				this.accountType = data.account_list;
				this.title = 'New Alert';
				this.modal.open();
			}, error => {
				this.app.notifications.showDanger(error.message);
			});
		} else {
			this.api.alert.get(this.id, data => {
				this.data = data.details;
				this.accountType = data.account_list;
				this.title = 'Edit Alert';
				this.modal.open();
			}, error => {
				this.app.notifications.showDanger(error.message);
			});
		}

		this.api.wizard.getRoutes(routes => {
			this.routesList = routes;
		}, error => {
			this.app.notifications.showDanger(error.message);
		});

		this.modal.open();
	}

	saveButton() {
		this.disabled = true;
		this.api.alert.save(this.data, () => {
			this.disabled = false;
			this.app.notifications.showSuccess('Alert saved.');
			this.modal.close();
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

	deleteButton() {
		this.app.dialog.pop('Delete Alert', 'Are you sure you want to delete this alert?', ['Cancel', '*Delete'], button => {
			if (button === 1) {
				this.disabled = true;
				this.api.alert.delete(this.id, () => {
					this.disabled = false;
					this.app.notifications.showSuccess('Alert deleted.');
					this.modal.close();
				}, error => {
					this.disabled = false;
					this.app.notifications.showDanger(error.message);
				});
			}
		});
	}

	editContentModalClosed(data) {
		if (data) {
			this.data.content = data.content;
		}
	}

	openMediaLibrary() {
		const sub = this.app.onMediaLibrarySelect.subscribe(data => {
			sub.unsubscribe();
			if (data) {
				this.data.image_id = data.id;
				this.data.image_url = data.url;
			}
		});

		this.app.mediaLibrary.open({ type: 'asset' });
	}

	removeImage() {
		this.data.image_id = null;
		this.data.image_url = null;
	}

	toggleAccountType(account) {
		const accountId = account.id;
		const i = this.data.account_type.findIndex(a => a.id === accountId);

		if (i === -1) {
			this.data.account_type.push(account);
		} else {
			this.data.account_type.splice(i, 1);
			this.data.account_type = [...this.data.account_type];
		}
	}

	isAccountTypeSelected(accountId: string): boolean {
		return this.data.account_type.some(account => account.id === accountId);
	}

	typeColor(account) {
		if (this.isAccountTypeSelected(account.id)) {
			return 'btn-' + account.color;
		}
		return 'btn-outline-' + account.color;
	}

}
