import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalSelectProductComponent, ModalEditProductDirective } from './modal-select-product/modal-select-product.component';
import { ModalEditLabourCategoryComponent } from './modal-edit-labour-category/modal-edit-labour-category.component';
import { ModalEditSubscriptionCategoryComponent } from './modal-edit-subscription-category/modal-edit-subscription-category.component';
import { ModalSelectEntityComponent } from './modal-select-entity/modal-select-entity.component';
import { ModalEditProductTypeComponent } from './modal-edit-product-type/modal-edit-product-type.component';
import { ModalEditCategoryGroupComponent } from './modal-edit-category-group/modal-edit-category-group.component';
import { ModalEditCategoryComponent } from './modal-edit-category/modal-edit-category.component';
import { ModalEditLabourComponent } from './modal-edit-labour/modal-edit-labour.component';
import { ModalEditPricingStructureComponent } from './modal-edit-pricing-structure/modal-edit-pricing-structure.component';
import { ModalEditSubscriptionComponent } from './modal-edit-subscription/modal-edit-subscription.component';
import { ModalEditUnitComponent } from './modal-edit-unit/modal-edit-unit.component';
import { ModalEditEntityDirective, ModalEditEntityComponent } from './modal-edit-entity/modal-edit-entity.component';

import { FormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { UIModule } from 'app/ui/ui.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MediaModule } from 'app/media/media.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalEditContentComponent } from './modal-edit-content/modal-edit-content.component';
import { TutorialModule } from 'app/tutorial/tutorial.module';
import { ModalAddCategoryFromPresetsComponent } from './modal-add-category-from-presets/modal-add-category-from-presets.component';
import { ModalEditProjectComponent } from './modal-edit-project/modal-edit-project.component';
import { ModalSelectQuoteComponent } from './modal-select-quote/modal-select-quote.component';
import { ModalEditCustomerComponent } from './modal-edit-customer/modal-edit-customer.component';
import { ModalEditCustomerContactComponent } from './modal-edit-customer-contact/modal-edit-customer-contact.component';
import { ModalSelectCustomerComponent } from './modal-select-customer/modal-select-customer.component';
import { ModalLinkCustomerToAccountsComponent } from './modal-link-customer-to-accounts/modal-link-customer-to-accounts.component';
import { ModalActivityLogComponent } from './modal-activity-log/modal-activity-log.component';
import { ModalEditBundleCounterComponent } from './modal-edit-bundle-counter/modal-edit-bundle-counter.component';
import { ModalEditBundleQuestionComponent } from './modal-edit-bundle-question/modal-edit-bundle-question.component';
import { ModalCloneProductComponent } from './modal-clone-product/modal-clone-product.component';
import { ModalEditProductComponent } from './modal-edit-product/modal-edit-product.component';
import { ModalFileImporterComponent } from './modal-file-importer/modal-file-importer.component';
import { ModalReorderCategoryGroupsComponent } from './modal-reorder-category-groups/modal-reorder-category-groups.component';
import { ModalProjectExportComponent } from './modal-project-exporter/modal-project-exporter.component';
import { CalendarModule } from 'primeng/calendar';
import { LinkyModule } from 'ngx-linky';
import { ModalQuoteChooseProjectComponent } from './modal-quote-choose-project/modal-quote-choose-project.component';
import { ModalEditPaymentOptionComponent } from './modal-edit-payment-option/modal-edit-payment-option.component';
import { ModalSelectPaymentOptionComponent } from './modal-select-payment-option/modal-select-payment-option.component';
import { ModalEditProductCategoryGroupComponent } from './modal-edit-product-category-group/modal-edit-product-category-group.component';
import { ModalEditProductCategoryComponent } from './modal-edit-product-category/modal-edit-product-category.component';
import { ModalReorderProductCategoryGroupsComponent } from './modal-reorder-product-category-groups/modal-reorder-product-category-groups.component';
import { ModalEditProductCounterComponent } from './modal-edit-product-counter/modal-edit-product-counter.component';
import { ModalEditProductCounterGroupComponent } from './modal-edit-product-counter-group/modal-edit-product-counter-group.component';
import { ModalEditSnagComponent } from './modal-edit-snag/modal-edit-snag.component';
import { ModalSelectSnagComponent } from './modal-select-snag/modal-select-snag.component';
import { ModalEditProjectTimesheetComponent } from './modal-edit-project-timesheet/modal-edit-project-timesheet.component';
import { ModalEditContactDeliveryAddressComponent } from './modal-edit-contact-delivery-address/modal-edit-contact-delivery-address.component';
import { ModalGlobalSearchComponent } from './modal-global-search/modal-global-search.component';
import { ModalEditOptionLibraryComponent } from './modal-edit-option-library/modal-edit-option-library.component';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ModalSelectIconComponent } from './modal-select-icon/modal-select-icon.component';
import { ModalAlertPopupComponent } from './modal-alert-popup/modal-alert-popup.component';
import { ModalCsvImporterComponent } from './modal-csv-importer/modal-csv-importer.component';
import { ModalHelpFormulaComponent } from './modal-help-formula/modal-help-formula.component';
import { ModalEditLabelComponent } from './modal-edit-label/modal-edit-label.component';
import { ModalSalesDetailsComponent } from './modal-sales-details/modal-sales-details.component';
import { ModalViewEditSnagComponent } from './modal-view-edit-snagg/modal-view-edit-snagg.component';
import { ModalEditTaskComponent } from './modal-edit-task/modal-edit-task.component';
import { ModalTaskDetailsComponent } from './modal-task-details/modal-task-details.component';
import { ModalEditPhaseComponent } from './modal-edit-phase/modal-edit-phase.component';
import { ModalSelectProjectComponent } from './modal-select-project/modal-select-project.component';
import { ModalBillingUpgradeAccountComponent } from './modal-billing-upgrade-account/modal-billing-upgrade-account.component';
import { ModalEditDependencyComponent } from './modal-edit-dependency/modal-edit-dependency.component';
import { ModalCreateTaskMultiComponent } from './modal-create-task-multi/modal-create-task-multi.component';
import { ModalWorkOrderTaskListComponent } from './modal-work-order-task-list/modal-work-order-task-list.component';
import { ModalEditUserComponent } from './modal-edit-user/modal-edit-user.component';
import { RouterModule } from '@angular/router';
import { ModalSelectWorkOrderComponent } from './modal-select-work-order/modal-select-work-order.component';
import { ModalSelectCatalogueCountry } from './modal-select-catalogue-country/modal-select-catalogue-country.component';
import { ModalCreateManualWorkOrderComponent } from './modal-create-manual-work-order/modal-create-manual-work-order.component';
import { ModalProjectInvoiceComponent } from './modal-project-invoice/modal-project-invoice.component';
import { ModalProjectAddToInvoiceComponent } from './modal-project-add-to-invoice/modal-project-add-to-invoice.component';
import { ModalProjectRemoveFromInvoiceComponent } from './modal-project-remove-from-invoice/modal-project-remove-from-invoice.component';
import { ModalProjectInvoicedItemsComponent } from './modal-project-invoiced-items/modal-project-invoiced-items.component';
import { ModalProjectInvoiceAllocateComponent } from './modal-project-invoice-allocate/modal-project-invoice-allocate.component';
import { ModalEditProjectNotesComponent } from './modal-edit-project-notes/modal-edit-project-notes.component';
import { ModalContactQuotesProjectsListComponent } from './modal-contact-quotes-projects-list/modal-contact-quotes-projects-list.component';
import { ModalProjectActivitiesListComponent } from './modal-project-activities-list/modal-project-activities-list.component';
import { ModalEditDocumentComponent } from './modal-edit-document/modal-edit-document.component';

@NgModule({
	declarations: [
		ModalAddCategoryFromPresetsComponent,
		ModalEditCategoryComponent,
		ModalEditCategoryGroupComponent,
		ModalEditContentComponent,
		ModalEditEntityComponent,
		ModalEditLabourComponent,
		ModalEditLabourCategoryComponent,
		ModalEditPricingStructureComponent,
		ModalEditProductTypeComponent,
		ModalEditSubscriptionComponent,
		ModalEditSubscriptionCategoryComponent,
		ModalEditUnitComponent,
		ModalSelectEntityComponent,
		ModalSelectProductComponent,
		ModalEditProjectComponent,
		ModalSelectQuoteComponent,
		ModalEditCustomerComponent,
		ModalEditCustomerContactComponent,
		ModalSelectCustomerComponent,
		ModalLinkCustomerToAccountsComponent,
		ModalActivityLogComponent,
		ModalEditBundleCounterComponent,
		ModalEditBundleQuestionComponent,
		ModalCloneProductComponent,
		ModalEditProductComponent,
		ModalFileImporterComponent,
		ModalReorderCategoryGroupsComponent,
		ModalProjectExportComponent,
		ModalEditEntityDirective,
		ModalQuoteChooseProjectComponent,
		ModalEditPaymentOptionComponent,
		ModalSelectPaymentOptionComponent,
		ModalEditProductCategoryComponent,
		ModalEditProductCategoryGroupComponent,
		ModalReorderProductCategoryGroupsComponent,
		ModalEditProductCounterComponent,
		ModalEditProductCounterGroupComponent,
		ModalEditSnagComponent,
		ModalSelectSnagComponent,
		ModalEditProjectTimesheetComponent,
		ModalEditContactDeliveryAddressComponent,
		ModalGlobalSearchComponent,
		ModalEditOptionLibraryComponent,
		ModalSelectIconComponent,
		ModalAlertPopupComponent,
		ModalCsvImporterComponent,
		ModalHelpFormulaComponent,
		ModalEditLabelComponent,
		ModalSalesDetailsComponent,
		ModalAlertPopupComponent,
		ModalViewEditSnagComponent,
		ModalEditTaskComponent,
		ModalTaskDetailsComponent,
		ModalEditPhaseComponent,
		ModalSelectProjectComponent,
		ModalEditProductDirective,
		ModalBillingUpgradeAccountComponent,
		ModalEditDependencyComponent,
		ModalCreateTaskMultiComponent,
		ModalWorkOrderTaskListComponent,
		ModalSelectWorkOrderComponent,
		ModalEditUserComponent,
		ModalSelectCatalogueCountry,
		ModalCreateManualWorkOrderComponent,
		ModalProjectInvoiceComponent,
		ModalProjectAddToInvoiceComponent,
		ModalProjectRemoveFromInvoiceComponent,
		ModalProjectInvoicedItemsComponent,
		ModalProjectInvoiceAllocateComponent,
		ModalEditDocumentComponent,
		ModalEditProjectNotesComponent,
		ModalContactQuotesProjectsListComponent,
		ModalProjectActivitiesListComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		UIModule,
		DragDropModule,
		EditorModule,
		MediaModule,
		NgbModule,
		TutorialModule,
		CalendarModule,
		LinkyModule,
		ColorPickerModule,
		RouterModule
	],
	exports: [
		ModalAddCategoryFromPresetsComponent,
		ModalEditCategoryComponent,
		ModalEditCategoryGroupComponent,
		ModalEditContentComponent,
		ModalEditEntityComponent,
		ModalEditLabourComponent,
		ModalEditLabourCategoryComponent,
		ModalEditPricingStructureComponent,
		ModalEditProductTypeComponent,
		ModalEditSubscriptionComponent,
		ModalEditSubscriptionCategoryComponent,
		ModalEditUnitComponent,
		ModalSelectEntityComponent,
		ModalSelectProductComponent,
		ModalEditProjectComponent,
		ModalSelectQuoteComponent,
		ModalEditCustomerComponent,
		ModalEditCustomerContactComponent,
		ModalSelectCustomerComponent,
		ModalLinkCustomerToAccountsComponent,
		ModalActivityLogComponent,
		ModalEditBundleCounterComponent,
		ModalEditBundleQuestionComponent,
		ModalCloneProductComponent,
		ModalEditProductComponent,
		ModalFileImporterComponent,
		ModalReorderCategoryGroupsComponent,
		ModalProjectExportComponent,
		ModalQuoteChooseProjectComponent,
		ModalEditPaymentOptionComponent,
		ModalSelectPaymentOptionComponent,
		ModalEditProductCategoryComponent,
		ModalEditProductCategoryGroupComponent,
		ModalReorderProductCategoryGroupsComponent,
		ModalEditProductCounterComponent,
		ModalEditProductCounterGroupComponent,
		ModalEditSnagComponent,
		ModalSelectSnagComponent,
		ModalEditProjectTimesheetComponent,
		ModalGlobalSearchComponent,
		ModalEditOptionLibraryComponent,
		ModalSelectIconComponent,
		ModalAlertPopupComponent,
		ModalCsvImporterComponent,
		ModalHelpFormulaComponent,
		ModalEditLabelComponent,
		ModalSalesDetailsComponent,
		ModalViewEditSnagComponent,
		ModalEditTaskComponent,
		ModalTaskDetailsComponent,
		ModalEditPhaseComponent,
		ModalSelectProjectComponent,
		ModalBillingUpgradeAccountComponent,
		ModalEditDependencyComponent,
		ModalCreateTaskMultiComponent,
		ModalWorkOrderTaskListComponent,
		ModalSelectWorkOrderComponent,
		ModalEditUserComponent,
		ModalSelectCatalogueCountry,
		ModalCreateManualWorkOrderComponent,
		ModalProjectInvoiceComponent,
		ModalProjectAddToInvoiceComponent,
		ModalProjectRemoveFromInvoiceComponent,
		ModalProjectInvoicedItemsComponent,
		ModalProjectInvoiceAllocateComponent,
		ModalEditDocumentComponent,
		ModalEditProjectNotesComponent,
		ModalContactQuotesProjectsListComponent,
		ModalProjectActivitiesListComponent
	]
})
export class ModalModule { }
