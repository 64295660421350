<div class="bg-light p-3 mr-4" *ngIf="!isEmptyObject(categories)">
	<ng-container *ngFor="let attr of attributes">
		<div *ngIf="categories[attr.id]" class="mb-4">
			<div class="d-flex flex-row mb-1 align-items-center">
				<h4 class="flex-column mr-1 w-50">{{ attr.name }}</h4>
				<div class="input-group w-50" *ngIf="categories[attr.id].hasSearch">
					<input class="form-control rounded-pill flat-right text-uppercase text-tiny" type="text" placeholder="Search..." (input)="filterSearch(attr.id, $event)">
				</div>
			</div>

			<div *ngFor="let filter of categories[attr.id].values ; let i = index" [hidden]="categories[attr.id].isExpandable && !categories[attr.id].isExpanded && i >= categories[attr.id].defaultEntriesToShow">
				<label class="custom-control custom-checkbox m-0 mb-2 mr-3">
					<input type="checkbox" class="custom-control-input" (ngModelChange)="filterChange(filter, attr.id)" [(ngModel)]="filter.isSelected">
					<span class="custom-control-label nowrap text-dark">{{ filter.value }}</span>
				</label>
			</div>
			<ng-container *ngIf="categories[attr.id].values.length > categories[attr.id].defaultEntriesToShow">
				<div class="selectable text-primary text-uppercase text-tiny" *ngIf="categories[attr.id].isExpandable && !categories[attr.id].isExpanded" (click)="expandAllValues(attr.id)">Expand All</div>
				<div class="selectable text-primary text-uppercase text-tiny" *ngIf="categories[attr.id].isExpandable && categories[attr.id].isExpanded" (click)="collapseAllValues(attr.id)">Collapse All</div>
			</ng-container>
		</div>
	</ng-container>
</div>
