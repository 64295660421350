import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxEchartsModule } from 'ngx-echarts';

import { ApiService } from './api.service';
import { AuthGuardService } from './auth-guard.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppService } from './app.service';
import { ErrorComponent } from './error.component';
import { SharedModule } from './shared/shared.module';
import { TutorialModule } from './tutorial/tutorial.module';
import { UIModule } from './ui/ui.module';
import { AngularResizeEventModule } from 'angular-resize-event';

// Import Angular locale data
import { DatePipe, registerLocaleData, TitleCasePipe } from '@angular/common';
import localeENGB from '@angular/common/locales/en-GB';
import localeENUS from '@angular/common/locales/en';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MediaModule } from './media/media.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MobileBlockerComponent } from './mobile-blocker/mobile-blocker.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DeveloperModule } from './developer/developer.module';
import { PrimeNGConfig } from 'primeng/api';
registerLocaleData(localeENUS);
registerLocaleData(localeENGB);

@NgModule({
	declarations: [
		AppComponent,
		ErrorComponent,
		MobileBlockerComponent
	],
	imports: [
		SharedModule.forRoot(),
		BrowserModule,
		HttpClientModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		FormsModule,
		CoreModule,
		MediaModule,
		UIModule,
		AngularResizeEventModule,
		EditorModule,
		TutorialModule,
		NgxEchartsModule.forRoot({
			echarts: () => import('echarts')
		}),
		NgbModule,
		ClipboardModule,
		DeveloperModule,
		TranslateModule.forRoot()
	],
	providers: [
		AppService,
		ApiService,
		AuthGuardService,
		Title,
		TitleCasePipe,
		DatePipe,
		TranslateService,
		PrimeNGConfig,
		{ provide: LOCALE_ID, useValue: 'en-GB' }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
