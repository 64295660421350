import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-developer-customer',
	templateUrl: './developer-customer.component.html',
})
export class DeveloperCustomerComponent implements OnInit {

	constructor() { }

	ngOnInit(): void { }

}
