import { Component } from '@angular/core';
import { AppService } from 'app/app.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

@Component({
	selector: 'modal-help-formula',
	templateUrl: './modal-help-formula.component.html'
})
export class ModalHelpFormulaComponent extends UIModalBaseComponent {

	helpTabs = [
		{ id: 'variables', description: 'Variables' },
		{ id: 'operators', description: 'Operators' },
		{ id: 'advanced', description: 'Advanced' }
	];
	helpTab = 'variables';

	constructor(
		public app: AppService
	) {
		super();
	}

	open(_data: any) {
		this.modal.open();
	}

}
