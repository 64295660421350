<form>
	<ui-modal [modalTitle]="id === 'new' ? 'New Labour Type' : 'Labour Type Details'">
		<ng-container *ngIf="details">
			<ui-form labelWidth="12rem">
				<ui-form-control label="Labour type description">
					<input class="form-control" [(ngModel)]="details.description" (ngModelChange)="tutorialCheck()" [ngModelOptions]="{ standalone: true }" [appTutorial]="306" autocomplete="chrome-off" appAutoFocus>
				</ui-form-control>
				<ui-form-control label="Group">
					<select class="custom-select" [(ngModel)]="details.category_id" [ngModelOptions]="{ standalone: true }">
						<option *ngFor="let item of categories" [ngValue]="item.id">{{item.description}}</option>
					</select>
				</ui-form-control>
			</ui-form>

			<hr>

			<div class="m-n2 p-2" [appTutorial]="307">
				<div class="pb-3" [ngClass]="{ 'border border-lightgrey rounded px-3 mb-3': multiCurrency }">
					<div *ngIf="multiCurrency" class="d-flex mx-n3 mb-2 bg-light">
						<div *ngFor="let pp of details.prices; let index = index" class="px-3 py-2 selectable hoverable border-bottom d-flex align-items-center" [ngClass]="{ 'border-primary border-4': pp === selectedPrice, 'border-lightgrey': pp !== selectedPrice }" (click)="selectedPrice = pp">
							<span class="text-uppercase text-small text-bold" [ngClass]="{ 'text-secondary': pp !== selectedPrice && index > 0, 'text-primary': index === 0 }">{{pp.currency_id}}</span>
						</div>
						<div class="border-bottom d-flex align-items-center px-2">
							<ng-container *ngIf="currenciesNotAdded(); let clist">
								<ng-container *ngIf="clist.length">
									<div class="dropdown d-inline-block">
										<button type="button" class="btn btn-outline-success p-1 dropdown-toggle" data-toggle="dropdown" ngbTooltip="Add currency"><i class="wq wq-add"></i></button>
										<div class="dropdown-menu">
											<button *ngFor="let c of clist" type="button" class="dropdown-item dropdown-item-success" (click)="addCurrency(c)">{{c}}</button>
										</div>
									</div>
								</ng-container>
							</ng-container>
						</div>
						<div class="border-bottom flex-weight-1"></div>
						<ng-container *ngIf="!isPrimaryPrice()">
							<div class="border-bottom d-flex align-items-center">
								<button type="button" class="btn btn-outline-primary p-1" ngbTooltip="Set as primary currency for this labour type" (click)="setPrimaryPrice()"><i class="wq wq-arrow-left"></i></button>
							</div>
							<div class="border-bottom d-flex align-items-center px-2">
								<button type="button" class="btn btn-outline-danger p-1" ngbTooltip="Remove this currency" (click)="removePrice()"><i class="wq wq-delete"></i></button>
							</div>
						</ng-container>
					</div>

					<ui-form labelWidth="12rem">
						<ui-form-control>
							<div class="d-flex">
								<div class="col-form-label text-center flex-weight-1 mr-3">Hourly Rate</div>
								<div class="col-form-label text-center flex-weight-1">Day Rate</div>
							</div>
						</ui-form-control>
						<ng-container *ngIf="app.orgInfo?.role.can_see_costs">
							<ui-form-control label="Cost">
								<div class="d-flex">
									<input class="form-control flex-weight-1 text-right text-bold mr-3" [(ngModel)]="selectedPrice.hourly_cost" [ngModelOptions]="{ standalone: true }" (input)="recalculate('cost')" (change)="recalculate()" autocomplete="chrome-off">
									<input class="form-control flex-weight-1 text-right" [ngClass]="{ invisible: zeroHours }" [(ngModel)]="selectedPrice.dayCost" [ngModelOptions]="{ standalone: true }" (input)="recalculate('dayCost')" (change)="recalculate()" autocomplete="chrome-off">
								</div>
							</ui-form-control>
							<ui-form-control label="Markup %">
								<div class="d-flex">
									<input class="form-control flex-weight-1 text-right mr-3" [(ngModel)]="selectedPrice.markup" [ngModelOptions]="{ standalone: true }" (input)="recalculate('markup')" (change)="recalculate()" autocomplete="chrome-off">
									<div class="form-control flex-weight-1 invisible"></div>
								</div>
							</ui-form-control>
							<ui-form-control label="Margin %">
								<div class="d-flex">
									<input class="form-control flex-weight-1 text-right mr-3" [(ngModel)]="selectedPrice.margin" [ngModelOptions]="{ standalone: true }" (input)="recalculate('margin')" (change)="recalculate()" autocomplete="chrome-off">
									<div class="form-control flex-weight-1 invisible"></div>
								</div>
							</ui-form-control>
						</ng-container>
						<ui-form-control label="Price">
							<div class="d-flex">
								<input class="form-control flex-weight-1 text-right text-bold mr-3" [(ngModel)]="selectedPrice.hourly_price" [ngModelOptions]="{ standalone: true }" (input)="recalculate('price')" (change)="recalculate()" autocomplete="chrome-off">
								<input class="form-control flex-weight-1 text-right" [ngClass]="{ invisible: zeroHours }" [(ngModel)]="selectedPrice.dayPrice" [ngModelOptions]="{ standalone: true }" (input)="recalculate('dayPrice')" (change)="recalculate()" autocomplete="chrome-off">
							</div>
						</ui-form-control>
						<ui-form-control *ngIf="app.orgInfo?.role.can_see_costs" label="Profit">
							<div class="d-flex">
								<input class="form-control flex-weight-1 text-right mr-3" [(ngModel)]="selectedPrice.profit" [ngModelOptions]="{ standalone: true }" (input)="recalculate('profit')" (change)="recalculate()" autocomplete="chrome-off">
								<input class="form-control flex-weight-1 text-right" [ngClass]="{ invisible: zeroHours }" [(ngModel)]="selectedPrice.dayProfit" [ngModelOptions]="{ standalone: true }" (input)="recalculate('dayProfit')" (change)="recalculate()" autocomplete="chrome-off">
							</div>
						</ui-form-control>
					</ui-form>
				</div>

				<hr>

				<ui-form labelWidth="12rem">
					<ui-form-control label="Hours per day">
						<div class="d-flex">
							<div class="form-control flex-weight-1 mr-3 invisible"></div>
							<input class="form-control flex-weight-1 text-right" [(ngModel)]="details.hours_per_day" [ngModelOptions]="{ standalone: true }" (input)="recalculate('hours_per_day')" (change)="recalculate()" autocomplete="chrome-off">
						</div>
					</ui-form-control>
					<ui-form-control *ngIf="app.orgInfo?.allow_sales_management" label="Sales multiplier">
						<div class="d-flex">
							<div class="form-control flex-weight-1 mr-3 invisible"></div>
							<input class="form-control flex-weight-1 text-right" [(ngModel)]="details.sales_multiplier" [ngModelOptions]="{ standalone: true }" (change)="recalculate()" autocomplete="chrome-off">
						</div>
					</ui-form-control>
				</ui-form>

				<hr>

				<label class="custom-control custom-checkbox mt-2">
					<input type="checkbox" class="custom-control-input" [ngModel]="!!details.use_product_vat" (ngModelChange)="details.use_product_vat = $event ? 1 : 0" [ngModelOptions]="{ standalone: true }">
					<span class="custom-control-label">Use product's {{app.translatedVATTerm}} rate</span>
				</label>

				<div *ngIf="!details.use_product_vat" class="alert alert-info mb-0">This labour type will use the <b>labour {{app.translatedVATTerm}} rate</b> that's set on the quote line. Usually used on labour that is not closely linked to the installation of a product, and therefore not eligible for reduced {{app.translatedVATTerm}}.</div>

				<div *ngIf="details.use_product_vat" class="alert alert-info mb-0">This labour type will use the <b>product's {{app.translatedVATTerm}} rate</b> that's set on the quote line. Usually used on labour that is closely linked to the installation of a product, and therefore eligible for reduced {{app.translatedVATTerm}}.</div>

				<hr>

				<label class="custom-control custom-checkbox mt-2">
					<input type="checkbox" class="custom-control-input" [ngModel]="!!details.labour_for_subcontractor" (ngModelChange)="details.labour_for_subcontractor = $event ? 1 : 0" [ngModelOptions]="{ standalone: true }">
					<span class="custom-control-label">Labour for subcontractor</span>
				</label>
				<div class="text-info"><i class="wq wq-info mr-1"></i>Check this to include labour on purchase orders.</div>

			</div>
		</ng-container>

		<button *ngIf="canDelete" type="button" class="btn btn-danger" (click)="deleteLabour()" [disabled]="disabled" ui-modal-footer>Delete</button>
		<button *ngIf="canArchive" type="button" class="btn btn-danger" (click)="archiveLabour()" [disabled]="disabled" ui-modal-footer>Delete</button>
		<button *ngIf="canUnarchive" type="button" class="btn btn-danger" (click)="unarchiveLabour()" [disabled]="disabled" ui-modal-footer>Restore</button>
		<div class="flex-weight-1" ui-modal-footer></div>
		<button type="button" class="btn btn-medium" (click)="modal.close()" [disabled]="disabled" ui-modal-footer>Cancel</button>
		<button type="submit" class="btn btn-primary" (click)="save()" [disabled]="disabled" [appTutorial]="305" ui-modal-footer>Save</button>
	</ui-modal>
</form>
