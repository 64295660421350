import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'app/app.service';

@Component({
	selector: 'app-developer',
	templateUrl: './developer.component.html',
})
export class DeveloperComponent implements OnInit {

	navigation = [
		{ title: 'Getting Started', type: 'link', link: 'getting-started' },
		{ title: 'Modules', type: 'header' },
		{ title: 'Customer', type: 'link', link: 'customer' },
		{ title: 'Quote', type: 'link', link: 'quote' },
		{ title: 'Webhook', type: 'link', link: 'webhook' }
	];

	selected = null;

	constructor(public app: AppService, private route: ActivatedRoute) { }

	ngOnInit(): void {
		this.selected = this.route.firstChild.pathFromRoot[this.route.firstChild.pathFromRoot.length - 1].snapshot.url[0].path;
	}

}
