import { Component, HostBinding } from '@angular/core';

@Component({
	selector: 'ui-button-badge',
	template: `<ng-content></ng-content>`,
	styles: [
		`:host { min-width: 1.38rem; }`
	]
})
export class UIButtonBadgeComponent {
	@HostBinding('class') cls = 'd-inline-block rounded-circle p-1 my-n1 mr-n1 ml-1 text-center btn-style-invert';
}
