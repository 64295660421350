import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { MySQLDateToISOPipe } from 'app/shared/mysql-date-to-iso.pipe';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

declare const Mangler: any;

@Component({
	selector: 'app-core-quote-accept-modal',
	templateUrl: './core-quote-accept-modal.component.html'
})
export class CoreQuoteAcceptModalComponent extends UIModalBaseComponent {

	quota;
	disabled = false;

	record = {
		id: 0,
		accepted_date: null
	};

	constructor(
		public app: AppService,
		private api: ApiService,
		private router: Router
	) {
		super();
	}

	open(data: any) {
		this.record.id = data.quoteId;
		this.record.accepted_date = null;
		this.quota = null;

		this.api.quote.downloadQuota(this.record.id, quota => {
			this.quota = quota;
			this.record.accepted_date = MySQLDateToISOPipe.stringToDate(quota.today);
			this.modal.open();
		}, error => {
			if (error.code === 1) {
				// Cannot accept quote, pop message asking them to upgrade their account.
				const buttons = ['Close'];
				if (this.app.orgInfo.role.manage_billing) buttons.push('*Go to Billing Settings');

				this.app.dialog.pop('Accept Quote', error.message, buttons, button => {
					if (button === 1) {
						this.router.navigate(['/' + this.app.orgInfo.alias, 'settings', 'billing']);
					}
				});
			} else {
				this.app.notifications.showDanger(error.message);
			}
		});
	}

	markAsAcceptedButton() {
		this.disabled = true;

		const data = Mangler.clone(this.record);
		data.accepted_date = MySQLDateToISOPipe.dateToString(data.accepted_date);

		this.api.quote.markAsAccepted(data, () => {
			this.disabled = false;
			this.app.quotePriceChange.emit();
			this.app.quoteStageChange.emit('accepted');
			this.modal.close(true);
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});

	}

}
