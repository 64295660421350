import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlertRoutingModule } from './alert-routing.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { UIModule } from 'app/ui/ui.module';
import { SharedModule } from 'app/shared/shared.module';
import { AlertEmbedListComponent } from './alert-embed-list/alert-embed-list.component';
import { AlertListComponent } from './alert-list/alert-list.component';
import { AlertEditModalComponent } from './alert-edit-modal/alert-edit-modal.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ModalModule } from 'app/modal/modal.module';

@NgModule({
	declarations: [
		AlertEmbedListComponent,
		AlertListComponent,
		AlertEditModalComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		UIModule,
		SharedModule,
		AlertRoutingModule,
		EditorModule,
		ModalModule
	],
	exports: [
		AlertEmbedListComponent
	]
})
export class AlertModule { }
