<form>
	<ui-modal [header]="false" size="xl">
		<div class="d-flex mt-n4 mb-4">
			<div *ngFor="let t of tabList" class="px-3 py-4 flex-weight-1 selectable hoverable border-bottom d-flex align-items-center" [ngClass]="{ 'border-primary border-4': activeTab === t.id, 'border-lightgrey': activeTab !== t.id }" (click)="activeTab = t.id">
				<span class="text-uppercase text-small text-bold mr-2" [ngClass]="{ 'text-secondary': activeTab !== t.id }">{{t.title}}</span>
				<span *ngIf="t.badge" class="badge" [ngClass]="{ 'badge-primary': activeTab === t.id, 'badge-secondary': activeTab !== t.id }">{{t.badge}}</span>
			</div>
		</div>

		<ng-container *ngIf="details">

			<!-- Product Details -->
			<div *ngIf="activeTab === 'details' && details" class="tab-content">

				<div class="d-flex">
					<div class="mr-4" style="width: 15.625rem;">
						<div *ngIf="!editable && details.override?.image_id" class="text-warning mb-1 text-center text-bold">Custom image</div>
						<div class="img-container d-flex hover-parent" [style.backgroundImage]="'url(\'' + (details.override?.image_url || details.image_url || 'assets/img/image-placeholder.png') + '\')' | safeStyle">
							<div *ngIf="details.catalogue_id" class="cat-image-fixed border bg-white border-silver rounded-circle shadow-sm" [style.backgroundImage]="'url(\'' + details.catalogue_image_url + '\')' | safeStyle" [ngbTooltip]="details.catalogue_name"></div>
							<div *ngIf="app.orgInfo.role.manage_products" class="hover-child d-inline-block" style="margin: auto;">
								<button *ngIf="editable && details.image_id" type="button" class="btn btn-danger mt-3" [disabled]="disabled" (click)="removeImage()"><i class="wq wq-delete mr-2"></i>Remove Image</button>
								<button *ngIf="!editable && details.override?.image_id" type="button" class="btn btn-warning mt-3" [disabled]="disabled" (click)="removeImage()"><i class="wq wq-undo mr-2"></i>Catalogue Image</button>
								<button type="button" class="btn btn-medium mt-3" [disabled]="disabled" (click)="openMediaLibrary()"><i class="wq wq-edit mr-2"></i>Select Image</button>
								<button type="button" class="btn btn-medium mt-3" [disabled]="disabled" (click)="getImageFromProduct()"><i class="wq wq-search mr-2"></i>Image from product</button>
							</div>
						</div>
						<label class="custom-control custom-checkbox mt-2" *ngIf="app.orgInfo?.role.is_elevated">
							<input type="checkbox" class="custom-control-input" [(ngModel)]="fixedImage" [ngModelOptions]="{ standalone: true }">
							<span class="custom-control-label">Fixed Image<i class="wq wq-info ml-2" ngbTooltip="Check if the product image needs to be fixed to avoid overriding during the daily integration with live catalogues."></i></span>
						</label>
						<div class="col-form-label my-2">Product Options</div>
						<label class="custom-control custom-checkbox mt-2">
							<input type="checkbox" class="custom-control-input" [(ngModel)]="soldToCustomer" [ngModelOptions]="{ standalone: true }" [disabled]="!editable || !app.orgInfo.role.manage_products">
							<span class="custom-control-label">Sold to customer<i class="wq wq-info ml-2" ngbTooltip="Check if a product can be sold directly on quotes. Can be unchecked for internal only products, for example parts that are only used on bill of materials."></i></span>
						</label>
						<label *ngIf="app.orgInfo?.allow_stock || app.orgInfo?.allow_supplier" class="custom-control custom-checkbox">
							<input type="checkbox" class="custom-control-input" [(ngModel)]="isStocked" [ngModelOptions]="{ standalone: true }" [disabled]="!canChangeStocked || !app.orgInfo.role.manage_products">
							<span class="custom-control-label">Stocked item<i class="wq wq-info ml-2" ngbTooltip="Check if stock is kept of this product."></i></span>
						</label>
						<label class="custom-control custom-checkbox">
							<input type="checkbox" class="custom-control-input" [(ngModel)]="ignorePriceWarnings" [ngModelOptions]="{ standalone: true }" [disabled]="!editable || !app.orgInfo.role.manage_products">
							<span class="custom-control-label">Ignore price warnings<i class="wq wq-info ml-2" ngbTooltip="If checked, this product won't be flagged up for having zero cost and/or zero price when sending a quote."></i></span>
						</label>
						<label class="custom-control custom-checkbox">
							<input type="checkbox" class="custom-control-input" [(ngModel)]="specialOrder" [ngModelOptions]="{ standalone: true }" [disabled]="!editable || !app.orgInfo.role.manage_products">
							<span class="custom-control-label">Special order item<i class="wq wq-info ml-2" ngbTooltip="If checked, this product will be flagged up as non-returnable and non-refundable when sending a quote. The warning may also be passed on to the customer proposal."></i></span>
						</label>
						<label class="custom-control custom-control-danger custom-checkbox">
							<input type="checkbox" class="custom-control-input" [(ngModel)]="discontinued" [ngModelOptions]="{ standalone: true }" [disabled]="!editable || !app.orgInfo.role.manage_products">
							<span class="custom-control-label" [ngClass]="{ 'text-danger': discontinued }">Discontinued<i class="wq wq-info ml-2" ngbTooltip="Discontinued products will not appear in product lists. They will also be unlinked from all items on the Used By tab when saved."></i></span>
						</label>
					</div>
					<div class="flex-weight-1">

						<div class="d-flex">
							<div class="form-group flex-weight-1">
								<div class="col-form-label">Short Description</div>
								<div *ngIf="details.override?.short_description === null" class="input-group">
									<input class="form-control" [(ngModel)]="details.short_description" [ngModelOptions]="{ standalone: true }" [disabled]="!editable || !app.orgInfo.role.manage_products" autocomplete="chrome-off" appAutoFocus>
									<div *ngIf="!editable && details.override" class="input-group-append">
										<button type="button" class="btn btn-medium px-2" ngbTooltip="Override short description" (click)="details.override.short_description = details.short_description || ''"><i class="wq wq-edit"></i></button>
									</div>
								</div>
								<div *ngIf="details.override?.short_description !== null" class="input-group">
									<input class="form-control" [(ngModel)]="details.override.short_description" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off" appAutoFocus>
									<div class="input-group-append">
										<button type="button" class="btn btn-warning px-2" ngbTooltip="Restore short description" (click)="details.override.short_description = null"><i class="wq wq-undo"></i></button>
									</div>
								</div>
							</div>
						</div>

						<div class="d-flex">
							<div class="form-group flex-weight-1 mr-3">
								<div class="col-form-label">Manufacturer</div>
								<div class="d-flex">
									<select class="custom-select flex-weight-1" [(ngModel)]="details.manufacturer_id" (ngModelChange)="refreshManufacturerSuppliers(true)" [ngModelOptions]="{ standalone: true }" [disabled]="!editable || !app.orgInfo.role.manage_products">
										<option [ngValue]="null">Unknown</option>
										<option *ngFor="let item of list.manufacturers" [ngValue]="item.id">{{item.name}}</option>
									</select>
									<div *ngIf="editable && app.orgInfo.role.manage_products" class="dropdown dropdown-light d-inline-block ml-1">
										<button type="button" class="btn btn-outline-success p-2 dropdown-toggle" data-toggle="dropdown"><i class="wq wq-add"></i></button>
										<div class="dropdown-menu">
											<button type="button" class="dropdown-item dropdown-item-success" (click)="addNewManufacturer()">Add New Manufacturer</button>
											<a [href]="'/' + app.org + '/configure/entity'" target="_blank" class="dropdown-item dropdown-item-success">Go to Manufacturer Settings<i class="wq wq-open-new ml-2"></i></a>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group flex-weight-1">
								<div class="col-form-label">Model</div>
								<div *ngIf="details.override?.model === null" class="input-group">
									<input class="form-control" [(ngModel)]="details.model" [ngModelOptions]="{ standalone: true }" [disabled]="!editable || !app.orgInfo.role.manage_products" autocomplete="chrome-off">
									<div *ngIf="!editable && details.override" class="input-group-append">
										<button type="button" class="btn btn-medium px-2" ngbTooltip="Override model" (click)="details.override.model = details.model || ''"><i class="wq wq-edit"></i></button>
									</div>
								</div>
								<div *ngIf="details.override?.model !== null" class="input-group">
									<input class="form-control" [(ngModel)]="details.override.model" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
									<div class="input-group-append">
										<button type="button" class="btn btn-warning px-2" ngbTooltip="Restore model" (click)="details.override.model = null"><i class="wq wq-undo"></i></button>
									</div>
								</div>
							</div>
						</div>

						<div class="d-flex">
							<div class="form-group flex-weight-1 mr-3">
								<div class="col-form-label">Product SKU</div>
								<div *ngIf="details.override?.sku === null" class="input-group">
									<input class="form-control" [(ngModel)]="details.sku" [ngModelOptions]="{ standalone: true }" [disabled]="!editable || !app.orgInfo.role.manage_products" autocomplete="chrome-off">
									<div *ngIf="!editable && details.override" class="input-group-append">
										<button type="button" class="btn btn-medium px-2" ngbTooltip="Override SKU" (click)="details.override.sku = details.sku || ''"><i class="wq wq-edit"></i></button>
									</div>
								</div>
								<div *ngIf="details.override?.sku !== null" class="input-group">
									<input class="form-control" [(ngModel)]="details.override.sku" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
									<div class="input-group-append">
										<button type="button" class="btn btn-warning px-2" ngbTooltip="Restore SKU" (click)="details.override.sku = null"><i class="wq wq-undo"></i></button>
									</div>
								</div>
							</div>
							<div class="form-group flex-weight-1 mr-3">
								<div class="col-form-label">Manufacturer SKU</div>
								<input class="form-control" [(ngModel)]="details.manufacturer_sku" [ngModelOptions]="{ standalone: true }" [disabled]="!editable || !app.orgInfo.role.manage_products" autocomplete="chrome-off">
							</div>
							<div class="form-group flex-weight-1">
								<div class="col-form-label">Unit of Measure</div>
								<div [ngbTooltip]="(!canChangeUnit && editable) ? 'Cannot change unit of measure once item is in use.' : null">
									<select class="custom-select" [(ngModel)]="details.unit_id" [ngModelOptions]="{ standalone: true }" [disabled]="!canChangeUnit || !app.orgInfo.role.manage_products">
										<option [ngValue]="null">None</option>
										<option *ngFor="let item of list.base_units" [ngValue]="item.id">{{item.description}} ({{item.name}})</option>
									</select>
								</div>
							</div>
						</div>

						<div [ngClass]="{ 'border border-lightgrey rounded px-3 mb-3': multiCurrency }">
							<div *ngIf="multiCurrency" class="d-flex mx-n3 mb-3 bg-light">
								<div *ngFor="let pp of details.product_price; let index = index" class="px-3 py-2 selectable hoverable border-bottom d-flex align-items-center" [ngClass]="{ 'border-primary border-4': pp === selectedProductPrice, 'border-lightgrey': pp !== selectedProductPrice }" (click)="selectedProductPrice = pp">
									<span class="text-uppercase text-small text-bold" [ngClass]="{ 'text-secondary': pp !== selectedProductPrice && index > 0, 'text-primary': index === 0 }">{{pp.currency_id}}</span>
								</div>
								<div class="border-bottom d-flex align-items-center px-2">
									<ng-container *ngIf="currenciesNotAdded(); let clist">
										<ng-container *ngIf="clist.length">
											<div class="dropdown d-inline-block">
												<button type="button" class="btn btn-outline-success p-1 dropdown-toggle" data-toggle="dropdown" ngbTooltip="Add currency"><i class="wq wq-add"></i></button>
												<div class="dropdown-menu">
													<button *ngFor="let c of clist" type="button" class="dropdown-item dropdown-item-success" (click)="addCurrency(c)">{{c}}</button>
												</div>
											</div>
										</ng-container>
									</ng-container>
								</div>
								<div class="border-bottom flex-weight-1"></div>
								<ng-container *ngIf="!isPrimaryProductPrice()">
									<div class="border-bottom d-flex align-items-center">
										<button type="button" class="btn btn-outline-primary p-1" ngbTooltip="Set as primary currency for this product" (click)="setPrimaryProductPrice()"><i class="wq wq-arrow-left"></i></button>
									</div>
									<div class="border-bottom d-flex align-items-center px-2">
										<button type="button" class="btn btn-outline-danger p-1" ngbTooltip="Remove this currency" (click)="removeProductPrice()"><i class="wq wq-delete"></i></button>
									</div>
								</ng-container>
							</div>

							<ng-container *ngIf="selectedProductPrice">
								<div class="d-flex">
									<div *ngIf="app.orgInfo?.role.can_see_costs" class="form-group flex-weight-1 mr-3">
										<div class="col-form-label">{{organisationPricing?.cost_price_description}}</div>
										<div [ngbTooltip]="hasBOM ? 'Automatically calculated from bill of materials.' : null">
											<input class="form-control text-right" [(ngModel)]="selectedProductPrice.cost_price" [ngModelOptions]="{ standalone: true }" (input)="recalculatePricing('cost')" (change)="formatNumbers()" [disabled]="hasBOM || !editable || !app.orgInfo.role.manage_products" autocomplete="chrome-off">
										</div>
									</div>
									<div class="form-group flex-weight-1 mr-3">
										<div class="col-form-label">{{organisationPricing?.sell_price_description}}</div>
										<div [ngbTooltip]="selectedProductPrice.pricing_structure_id && pricingStructureIndex[selectedProductPrice.pricing_structure_id]?.pricing_method !== 'custom' ? 'Calculated by selected pricing level.' : null">
											<input class="form-control text-right" [(ngModel)]="selectedProductPrice.sell_price" [ngModelOptions]="{ standalone: true }" (input)="recalculatePricing()" (change)="formatNumbers()" [disabled]="selectedProductPrice.pricing_structure_id && pricingStructureIndex[selectedProductPrice.pricing_structure_id]?.pricing_method !== 'custom' || !editable || !app.orgInfo.role.manage_products" autocomplete="chrome-off">
										</div>
									</div>
									<div class="form-group flex-weight-1">
										<div class="col-form-label">MSRP</div>
										<input class="form-control text-right" [(ngModel)]="selectedProductPrice.srp_price" [ngModelOptions]="{ standalone: true }" (change)="formatNumbers()" [disabled]="!editable || !app.orgInfo.role.manage_products" autocomplete="chrome-off">
									</div>
								</div>

								<div class="d-flex">
									<div class="form-group flex-weight-1">
										<div class="col-form-label">Pricing Level</div>
										<div class="d-flex">
											<select class="custom-select" [(ngModel)]="selectedProductPrice.pricing_structure_id" [ngModelOptions]="{ standalone: true }" (change)="refreshSelections()" [disabled]="!editable || !app.orgInfo.role.manage_products">
												<option [ngValue]="null">Custom Pricing Level</option>
												<option *ngFor="let item of list.pricing_structures" [ngValue]="item.id">{{item.description}}</option>
											</select>
											<div *ngIf="editable && app.orgInfo.role.manage_products" class="dropdown dropdown-light d-inline-block ml-1">
												<button type="button" class="btn btn-outline-success p-2 dropdown-toggle" data-toggle="dropdown"><i class="wq wq-add"></i></button>
												<div class="dropdown-menu">
													<button type="button" class="dropdown-item dropdown-item-success" (click)="addNewPricingLevel()">Add New Pricing Level</button>
													<a [href]="'/' + app.org + '/configure/pricing-level'" target="_blank" class="dropdown-item dropdown-item-success">Go to Pricing Level Settings<i class="wq wq-open-new ml-2"></i></a>
												</div>
											</div>
										</div>
									</div>
									<div class="form-group flex-weight-1 ml-3">
										<div class="col-form-label">{{app.translatedVATTerm}}</div>
										<select class="custom-select" [(ngModel)]="details.vat_rate_id" [ngModelOptions]="{ standalone: true }" [disabled]="!app.orgInfo.role.manage_products">
											<option [ngValue]="null">Default</option>
											<option *ngFor="let item of list.vat_rates" [ngValue]="item.id">{{item.private_description}} ({{item.amount | decimal:0:4}}%)</option>
										</select>
									</div>
									<div class="form-group flex-weight-1 ml-3">
										<div class="col-form-label">Labour {{app.translatedVATTerm}}</div>
										<select class="custom-select" [(ngModel)]="details.labour_vat_rate_id" [ngModelOptions]="{ standalone: true }" [disabled]="!app.orgInfo.role.manage_products">
											<option [ngValue]="null">Default</option>
											<option *ngFor="let item of list.vat_rates" [ngValue]="item.id">{{item.private_description}} ({{item.amount | decimal:0:4}}%)</option>
										</select>
									</div>
								</div>
							</ng-container>
						</div>

						<div *ngIf="!isBundle && editable" class="mb-3">
							<div *ngIf="details.suppliers.length" class="mb-3">
								<div class="d-flex">
									<div class="flex-weight-5 mr-3">
										<div class="col-form-label">Supplier SKU</div>
									</div>
									<div class="flex-weight-5 mr-3">
										<div class="col-form-label">Name</div>
									</div>
									<div class="flex-weight-3 mr-3">
										<div class="col-form-label text-center">Primary</div>
									</div>
									<div><button type="button" class="btn btn-outline-secondary btn-sm border-0 px-1 invisible"><i class="wq wq-close"></i></button></div>
								</div>

								<div *ngFor="let item of details.suppliers" class="d-flex align-items-center hoverable-table p-1 m-n1 mb-2">
									<div class="flex-weight-5 mr-3">
										<input class="form-control" [(ngModel)]="item.sku" [ngModelOptions]="{ standalone: true }" [disabled]="!app.orgInfo.role.manage_products" autocomplete="chrome-off">
									</div>
									<div class="flex-weight-5 mr-3">
										<input class="form-control" [(ngModel)]="item.name" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off" disabled>
									</div>
									<div class="flex-weight-3 text-center mr-3">
										<span *ngIf="item.is_primary" class="badge badge-primary badge-sm">Primary Supplier</span>
										<button *ngIf="!item.is_primary && app.orgInfo.role.manage_products" type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="setPrimarySupplier(item.id)">Set primary</button>
										<span *ngIf="!item.is_primary && !app.orgInfo.role.manage_products" class="badge badge-secondary badge-sm">Secondary Supplier</span>
									</div>
									<div *ngIf="app.orgInfo.role.manage_products" class="text-right">
										<button type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="removeSupplier(item.id)"><i class="wq wq-close"></i></button>
									</div>
								</div>
							</div>

							<p *ngIf="!details.suppliers.length" class="text-secondary">No suppliers.</p>
							<button *ngIf="app.orgInfo.role.manage_products" type="button" class="btn btn-outline-success btn-sm mr-2" (click)="modalAddSupplier()"><i class="wq wq-add mr-2"></i>Add supplier</button>
							<button *ngIf="manufacturerSuppliers.length && app.orgInfo.role.manage_products" type="button" class="btn btn-outline-secondary btn-sm" (click)="addManufacturerSuppliers(true)"><i class="wq wq-undo mr-2"></i>Reset to manufacturer defaults</button>
						</div>

						<div class="mb-3">
							<div *ngIf="details.labour.length" class="mb-3">
								<div class="d-flex">
									<div class="flex-weight-8 mr-3">
										<div class="col-form-label">Labour</div>
									</div>
									<div class="flex-weight-2 mr-3">
										<div class="col-form-label text-center">Hours</div>
									</div>
									<div class="flex-weight-2 mr-3">
										<div class="col-form-label text-center">Days</div>
									</div>
									<div *ngIf="app.orgInfo?.role.can_see_costs" class="flex-weight-2 mr-3">
										<div class="col-form-label text-center">Cost</div>
									</div>
									<div class="flex-weight-2 mr-3">
										<div class="col-form-label text-center">Price</div>
									</div>
									<div><button type="button" class="btn btn-outline-secondary btn-sm border-0 px-1 invisible"><i class="wq wq-close"></i></button></div>
								</div>

								<div *ngFor="let labour of details.labour; let index = index" class="d-flex align-items-center hoverable-table p-1 m-n1 mb-2">
									<div class="d-flex flex-weight-8 mr-3">
										<select class="custom-select flex-weight-1" [(ngModel)]="labour.labour_type_id" [ngModelOptions]="{ standalone: true }" (change)="formatNumbers()" [disabled]="!app.orgInfo.role.manage_products">
											<option [ngValue]="null">Select Labour Type</option>
											<optgroup *ngFor="let category of list.labour_categories" label="{{category.description}}">
												<option *ngFor="let item of category.types" [ngValue]="item.id">{{item.description}}</option>
											</optgroup>
										</select>
										<div *ngIf="app.orgInfo.role.manage_products" class="dropdown dropdown-light d-inline-block ml-1">
											<button type="button" class="btn btn-outline-success p-2 dropdown-toggle" data-toggle="dropdown"><i class="wq wq-add"></i></button>
											<div class="dropdown-menu">
												<button type="button" class="dropdown-item dropdown-item-success" (click)="addNewLabourType(index)">Add New Labour Type</button>
												<a [href]="'/' + app.org + '/configure/labour'" target="_blank" class="dropdown-item dropdown-item-success">Go to Labour Settings<i class="wq wq-open-new ml-2"></i></a>
											</div>
										</div>
									</div>
									<div class="flex-weight-2 mr-3">
										<input class="form-control text-right text-bold" [(ngModel)]="labour.labour_hours" [ngModelOptions]="{ standalone: true }" (change)="formatNumbers()" (input)="labourHoursChanged(labour)" [disabled]="!app.orgInfo.role.manage_products" autocomplete="chrome-off">
									</div>
									<div class="flex-weight-2 mr-3">
										<input class="form-control text-right" [ngClass]="{ invisible: labour.hours_per_day < 0.01 }" [(ngModel)]="labour.labour_days" [ngModelOptions]="{ standalone: true }" (change)="formatNumbers()" (input)="labourDaysChanged(labour)" [disabled]="!app.orgInfo.role.manage_products" autocomplete="chrome-off">
									</div>
									<div *ngIf="app.orgInfo?.role.can_see_costs" class="flex-weight-2 mr-3">
										<input type="text" class="form-control text-right" [value]="getLabourCost(labour) | decimal:2:2:true:getLabourCurrency(labour)" readonly>
									</div>
									<div class="flex-weight-2 mr-3">
										<input type="text" class="form-control text-right" [value]="getLabourPrice(labour) | decimal:2:2:true:getLabourCurrency(labour)" readonly>
									</div>
									<div class="text-right">
										<button *ngIf="app.orgInfo.role.manage_products" type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="deleteLabour(labour)"><i class="wq wq-close"></i></button>
									</div>
								</div>
							</div>
							<p *ngIf="details.labour.length === 0" class="text-secondary">No labour.</p>
							<button *ngIf="app.orgInfo.role.manage_products" type="button" class="btn btn-outline-success btn-sm mr-2" (click)="addLabour()"><i class="wq wq-add mr-2"></i>Add labour</button>
							<button *ngIf="quoteLabour && app.orgInfo.role.manage_products" type="button" class="btn btn-outline-success btn-sm mr-2" (click)="copyQuoteLabour()"><i class="wq wq-copy mr-2"></i>Use labour from quote item</button>
						</div>

					</div>
				</div>
			</div>

			<!-- Additional Options -->
			<div *ngIf="activeTab === 'additional'" class="tab-content">

				<div class="d-flex">
					<div class="flex-weight-1">
						<div class="form-group">
							<div class="col-form-label">Product Type</div>
							<div class="d-flex">
								<select class="custom-select flex-weight-1" [(ngModel)]="details.type_id" [ngModelOptions]="{ standalone: true }" [disabled]="!editable || !app.orgInfo.role.manage_products">
									<option [ngValue]="null">None</option>
									<option *ngFor="let item of list.product_types" [ngValue]="item.id">{{item.name}}</option>
								</select>
								<div *ngIf="editable && app.orgInfo.role.manage_products" class="dropdown dropdown-light d-inline-block ml-1">
									<button type="button" class="btn btn-outline-success p-2 dropdown-toggle" data-toggle="dropdown"><i class="wq wq-add"></i></button>
									<div class="dropdown-menu">
										<button type="button" class="dropdown-item dropdown-item-success" (click)="addNewProductType()">Add New Product Type</button>
										<a [href]="'/' + app.org + '/configure/product-type'" target="_blank" class="dropdown-item dropdown-item-success">Go to Product Type Settings<i class="wq wq-open-new ml-2"></i></a>
									</div>
								</div>
							</div>
						</div>

						<div class="d-flex">
							<div class="form-group flex-weight-1 mr-3">
								<div class="col-form-label">Width (mm)</div>
								<input class="form-control" [(ngModel)]="details.width" [ngModelOptions]="{ standalone: true }" (change)="formatNumbers()" [disabled]="!editable || !app.orgInfo.role.manage_products" autocomplete="chrome-off">
							</div>
							<div class="form-group flex-weight-1 mr-3">
								<div class="col-form-label">Height (mm)</div>
								<input class="form-control" [(ngModel)]="details.height" [ngModelOptions]="{ standalone: true }" (change)="formatNumbers()" [disabled]="!editable || !app.orgInfo.role.manage_products" autocomplete="chrome-off">
							</div>
							<div class="form-group flex-weight-1">
								<div class="col-form-label">Depth (mm)</div>
								<input class="form-control" [(ngModel)]="details.depth" [ngModelOptions]="{ standalone: true }" (change)="formatNumbers()" [disabled]="!editable || !app.orgInfo.role.manage_products" autocomplete="chrome-off">
							</div>
						</div>

						<div class="d-flex">
							<div class="form-group flex-weight-1 mr-3">
								<div class="col-form-label">&nbsp;</div>
								<label class="custom-control custom-checkbox mt-2">
									<input type="checkbox" class="custom-control-input" [(ngModel)]="canSplit" [ngModelOptions]="{ standalone: true }" [disabled]="isBundle || !editable || !app.orgInfo.role.manage_products">
									<span class="custom-control-label">Splittable<i class="wq wq-info ml-2" ngbTooltip="If product is a pack of multiple items or a roll of cable, indicate how many units it contains. Bundles cannot be split."></i></span>
								</label>
							</div>
							<div class="form-group flex-weight-1 mr-3" [ngClass]="{ invisible: !canSplit }">
								<div class="col-form-label">Split quantity</div>
								<input class="form-control" [(ngModel)]="details.split_pack_quantity" [ngModelOptions]="{ standalone: true }" (change)="formatNumbers()" [disabled]="!editable || !app.orgInfo.role.manage_products" autocomplete="chrome-off">
							</div>
							<div class="form-group flex-weight-1" [ngClass]="{ invisible: !canSplit }">
								<div class="col-form-label">Split unit</div>
								<select class="custom-select" [(ngModel)]="details.split_unit_id" [ngModelOptions]="{ standalone: true }" [disabled]="!editable || !app.orgInfo.role.manage_products">
									<option [ngValue]="null">None</option>
									<option *ngFor="let item of list.base_units" [ngValue]="item.id">{{item.description}} ({{item.name}})</option>
								</select>
							</div>
						</div>

						<div class="form-group">
							<label *ngIf="editable" class="custom-control custom-checkbox d-inline-block">
								<input type="checkbox" class="custom-control-input" [(ngModel)]="hasBOM" [ngModelOptions]="{ standalone: true }" [disabled]="!canChangeAssembly || !app.orgInfo.role.manage_products">
								<span class="custom-control-label">Bill of materials<i class="wq wq-info ml-2" ngbTooltip="If checked, the cost of the product is automatically calculated by the total cost of its bill of materials."></i></span>
							</label>
						</div>
						<div class="form-group">
							<label class="custom-control custom-checkbox d-inline-block">
								<input type="checkbox" class="custom-control-input" [(ngModel)]="isBundle" [ngModelOptions]="{ standalone: true }" [disabled]="!canChangeAssembly || !app.orgInfo.role.manage_products">
								<span class="custom-control-label">Bundle<i class="wq wq-info ml-2" ngbTooltip="Make it easier to select the correct products on a quote by setting up special rules."></i></span>
							</label>
						</div>

					</div>

					<div class="form-group flex-weight-1 ml-3">
						<div class="col-form-label">Long Description</div>
						<div *ngIf="details.override?.long_description === null" class="input-group">
							<textarea class="form-control" style="height: 185px;" [(ngModel)]="details.long_description" [ngModelOptions]="{ standalone: true }" [disabled]="!editable || !app.orgInfo.role.manage_products"></textarea>
							<div *ngIf="!editable && details.override" class="input-group-append">
								<button type="button" class="btn btn-medium px-2" ngbTooltip="Override long description" (click)="details.override.long_description = details.long_description || ''"><i class="wq wq-edit"></i></button>
							</div>
						</div>
						<div *ngIf="details.override?.long_description !== null" class="input-group">
							<textarea class="form-control" style="height: 185px;" [(ngModel)]="details.override.long_description" [ngModelOptions]="{ standalone: true }"></textarea>
							<div class="input-group-append">
								<button type="button" class="btn btn-warning px-2" ngbTooltip="Restore long description" (click)="details.override.long_description = null"><i class="wq wq-undo"></i></button>
							</div>
						</div>
					</div>
				</div>

				<hr>

				<div class="mb-3">
					<div *ngIf="details.subscription.length" class="mb-3">
						<div class="d-flex">
							<div class="flex-weight-6 mr-3">
								<div class="col-form-label">Subscription</div>
							</div>
							<div class="flex-weight-2 mr-3">
								<div class="col-form-label text-center">Quantity</div>
							</div>
							<div *ngIf="app.orgInfo?.role.can_see_costs" class="flex-weight-2 mr-3">
								<div class="col-form-label text-center">Cost</div>
							</div>
							<div class="flex-weight-2 mr-3">
								<div class="col-form-label text-center">Price</div>
							</div>
							<div><button type="button" class="btn btn-outline-secondary btn-sm border-0 px-1 invisible"><i class="wq wq-close"></i></button></div>
						</div>

						<div *ngFor="let subscription of details.subscription" class="d-flex align-items-center hoverable-table p-1 m-n1 mb-2">
							<div class="flex-weight-6 mr-3">
								<select class="custom-select" [(ngModel)]="subscription.subscription_type_id" [ngModelOptions]="{ standalone: true }" (change)="refreshSelections()" [disabled]="!app.orgInfo.role.manage_products">
									<option [ngValue]="null">None</option>
									<optgroup *ngFor="let category of list.subscription_categories" label="{{category.description}}">
										<option *ngFor="let item of category.types" [ngValue]="item.id">{{item.description}}</option>
									</optgroup>
								</select>
							</div>
							<div class="flex-weight-2 mr-3">
								<input class="form-control text-right" [(ngModel)]="subscription.quantity" [ngModelOptions]="{ standalone: true }" (change)="formatNumbers()" [disabled]="!app.orgInfo.role.manage_products" autocomplete="chrome-off">
							</div>
							<div *ngIf="app.orgInfo?.role.can_see_costs" class="flex-weight-2 mr-3">
								<input type="text" class="form-control text-right" [value]="getSubscriptionCost(subscription) | decimal:2:2:true:getSubscriptionCurrency(subscription)" readonly>
							</div>
							<div class="flex-weight-2 mr-3">
								<input type="text" class="form-control text-right" [value]="getSubscriptionPrice(subscription) | decimal:2:2:true:getSubscriptionCurrency(subscription)" readonly>
							</div>
							<div class="text-right">
								<button *ngIf="app.orgInfo.role.manage_products" type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="deleteSubscription(subscription)"><i class="wq wq-close"></i></button>
							</div>
						</div>
					</div>
					<p *ngIf="details.subscription.length === 0" class="text-secondary">No subscriptions.</p>
					<button *ngIf="app.orgInfo.role.manage_products" type="button" class="btn btn-outline-success btn-sm" (click)="addSubscription()"><i class="wq wq-add mr-2"></i>Add subscription</button>
				</div>

				<hr>

				<div class="mb-3">
					<div *ngIf="details.documents.length" class="mb-3">
						<div class="d-flex">
							<div class="flex-weight-1 mr-3">
								<div class="col-form-label">Document Name</div>
							</div>
							<div class="flex-weight-2 mr-3">
								<div class="col-form-label text-center">Web URL</div>
							</div>
							<div><button type="button" class="btn btn-outline-secondary btn-sm border-0 px-1 invisible"><i class="wq wq-close"></i></button></div>
						</div>

						<div *ngFor="let doc of details.documents" class="d-flex align-items-center hoverable-table p-1 m-n1 mb-2">
							<div class="flex-weight-1 mr-3">
								<input class="form-control" [(ngModel)]="doc.name" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off" [disabled]="!doc.editable">
							</div>
							<div class="flex-weight-2 mr-3">
								<input class="form-control" [(ngModel)]="doc.url" [ngModelOptions]="{ standalone: true }" (change)="documentUrlChanged(doc)" autocomplete="chrome-off" [disabled]="!doc.editable">
							</div>
							<div class="text-right">
								<button *ngIf="app.orgInfo.role.manage_products" type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" [ngClass]="{ invisible: !doc.editable }" (click)="deleteDocument(doc)"><i class="wq wq-close"></i></button>
							</div>
						</div>
					</div>
					<p *ngIf="details.documents.length === 0" class="text-secondary">No documents.</p>
					<button *ngIf="app.orgInfo.role.manage_products" type="button" class="btn btn-outline-success btn-sm" (click)="addDocument()"><i class="wq wq-add mr-2"></i>Add document</button>
				</div>

				<hr>

				<div class="mb-3">
					<div *ngIf="details.counters.length" class="mb-3">
						<div class="d-flex">
							<div class="flex-weight-6 mr-3">
								<div class="col-form-label">Counter</div>
							</div>
							<div class="flex-weight-2 mr-3">
								<div class="col-form-label text-center">Amount</div>
							</div>
							<div><button type="button" class="btn btn-outline-secondary btn-sm border-0 px-1 invisible"><i class="wq wq-close"></i></button></div>
						</div>

						<div *ngFor="let counter of details.counters" class="d-flex align-items-center hoverable-table p-1 m-n1 mb-2">
							<div class="flex-weight-6 mr-3">
								<select class="custom-select" [(ngModel)]="counter.counter_id" [ngModelOptions]="{ standalone: true }" [disabled]="!app.orgInfo.role.manage_products">
									<option [ngValue]="null"></option>
									<optgroup *ngFor="let group of list.counter_groups" label="{{group.description}}">
										<option *ngFor="let item of group.items" [ngValue]="item.id">{{item.description}}</option>
									</optgroup>
								</select>
							</div>
							<div class="flex-weight-2 mr-3">
								<input class="form-control text-right" [(ngModel)]="counter.amount" [ngModelOptions]="{ standalone: true }" (change)="formatNumbers()" [disabled]="!app.orgInfo.role.manage_products" autocomplete="chrome-off">
							</div>
							<div class="text-right">
								<button *ngIf="app.orgInfo.role.manage_products" type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="deleteCounter(counter)"><i class="wq wq-close"></i></button>
							</div>
						</div>
					</div>
					<p *ngIf="details.counters.length === 0" class="text-secondary">No counters.</p>
					<button *ngIf="app.orgInfo.role.manage_products" type="button" class="btn btn-outline-success btn-sm" (click)="addCounter()"><i class="wq wq-add mr-2"></i>Add counter</button>
				</div>

				<hr>

				<div class="mb-3">
					<div *ngIf="details.factors.length" class="mb-3">
						<div class="d-flex">
							<div class="flex-weight-1 mr-3">
								<div class="col-form-label text-center">
									Factor Condition
									<i class="wq wq-info text-secondary ml-1" ngbTooltip="Factors are recalculated every time the quote changes. Evaluated from top to bottom, cost and sell prices will be replaced by the value of the formula of the first matching condition."></i>
								</div>
							</div>
							<div class="flex-weight-1 mr-3">
								<div class="col-form-label text-center">Cost Price Formula</div>
							</div>
							<div class="flex-weight-1 mr-3">
								<div class="col-form-label text-center">Sell Price Formula</div>
							</div>
							<div><button type="button" class="btn btn-outline-secondary btn-sm border-0 px-1 invisible"><i class="wq wq-close"></i></button></div>
						</div>

						<div class="cdk-drop-list" (cdkDropListDropped)="factorDrop($event)" cdkDropList>
							<div *ngFor="let factor of details.factors" class="d-flex align-items-center hoverable-table p-1 m-n1 mb-2 cdk-drag" cdkDrag>
								<div class="mr-1 draggable" cdkDragHandle><i class="wq wq-drag-handle text-secondary"></i></div>
								<div class="flex-weight-1 mr-3">
									<div class="d-flex">
										<div *ngIf="factor.condition" class="flex-weight-1 mr-1">
											<input class="form-control" placeholder="Formula A" [(ngModel)]="factor.a" [ngModelOptions]="{ standalone: true }" [disabled]="!app.orgInfo.role.manage_products" autocomplete="chrome-off">
										</div>
										<div class="flex-weight-1">
											<select class="custom-select text-center" [(ngModel)]="factor.condition" [ngModelOptions]="{ standalone: true }" [disabled]="!app.orgInfo.role.manage_products">
												<option [ngValue]="null">Always</option>
												<option value="&lt;">&lt;</option>
												<option value="&lt;=">&lt;=</option>
												<option value="=">=</option>
												<option value="&gt;=">&gt;=</option>
												<option value="&gt;">&gt;</option>
												<option value="!=">!=</option>
											</select>
										</div>
										<div *ngIf="factor.condition" class="flex-weight-1 ml-1">
											<input class="form-control" placeholder="Formula B" [(ngModel)]="factor.b" [ngModelOptions]="{ standalone: true }" [disabled]="!app.orgInfo.role.manage_products" autocomplete="chrome-off">
										</div>
									</div>
								</div>
								<div class="flex-weight-1 mr-3">
									<input class="form-control" placeholder="No change" [(ngModel)]="factor.cost" [ngModelOptions]="{ standalone: true }" [disabled]="!app.orgInfo.role.manage_products" autocomplete="chrome-off">
								</div>
								<div class="flex-weight-1 mr-3">
									<input class="form-control" placeholder="No change" [(ngModel)]="factor.sell" [ngModelOptions]="{ standalone: true }" [disabled]="!app.orgInfo.role.manage_products" autocomplete="chrome-off">
								</div>
								<div class="text-right">
									<button *ngIf="app.orgInfo.role.manage_products" type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="deleteFactor(factor)"><i class="wq wq-close"></i></button>
								</div>
							</div>
						</div>
					</div>
					<p *ngIf="details.factors.length === 0" class="text-secondary">
						No factors.
						<i class="wq wq-info text-secondary ml-1" ngbTooltip="Factors are recalculated every time the quote changes. Evaluated from top to bottom, cost and sell prices will be replaced by the value of the formula of the first matching condition."></i>
					</p>
					<div class="d-flex align-items-center">
						<button *ngIf="app.orgInfo.role.manage_products" type="button" class="btn btn-outline-success btn-sm" (click)="addFactor()"><i class="wq wq-add mr-2"></i>Add factor</button>
						<button type="button" class="btn btn-outline-info btn-sm ml-2" (click)="formulaHelpModal.open()"><i class="wq wq-help mr-2"></i>Formula help</button>
					</div>
				</div>

				<!-- <div *ngIf="isStocked && details.warehouses.length" class="form-group">
					<h3>Stock</h3>
					<table *ngIf="isStocked && details.warehouses.length" class="table table-hover vam w-80">
						<thead>
							<tr>
								<th>Warehouse</th>
								<th class="shrink nowrap text-right">Minimum Quantity</th>
								<th class="shrink nowrap text-right">Maximum Quantity</th>
								<th class="shrink nowrap">Default Location</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let w of details.warehouses">
								<td class="text-bold">{{w.description}}</td>
								<td class="shrink"><input class="form-control text-right" style="width: 9.375rem;" [(ngModel)]="w.min_qty" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off"></td>
								<td class="shrink"><input class="form-control text-right" style="width: 9.375rem;" [(ngModel)]="w.max_qty" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off"></td>
								<td class="shrink">
									<select class="custom-select" [(ngModel)]="w.location_id" [ngModelOptions]="{ standalone: true }">
										<option [ngValue]="null">None</option>
										<ng-container *ngFor="let o of list.warehouse_locations">
											<option *ngIf="w.id === o.warehouse_id" [ngValue]="o.id">{{o.description}}</option>
										</ng-container>
									</select>
								</td>
							</tr>
						</tbody>
					</table>
				</div> -->

			</div>

			<!-- Bill of materials -->
			<div *ngIf="activeTab === 'bom' && details && editable" class="tab-content">
				<div class="card-box">
					<p class="alert alert-info"><i class="wq wq-info mr-1"></i> The product's <b>cost</b> will be the sum of its parts'.</p>
					<table *ngIf="details.bom.length" class="table table-hover vam">
						<thead>
							<tr>
								<th colspan="3">Product</th>
								<th class="text-right shrink">Quantity</th>
								<th class="shrink">Unit</th>
								<th *ngIf="app.orgInfo?.role.can_see_costs" class="text-right">{{organisationPricing?.cost_price_description}}</th>
								<th class="text-right">{{organisationPricing?.sell_price_description}}</th>
								<th class="shrink"></th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of details.bom" [ngClass]="{ success: highlightedBom === item }">
								<td class="shrink pr-0">
									<div *ngIf="item.info.image_url" class="product-image mr-3" [style.backgroundImage]="'url(\'' + item.info.image_url + '\')' | safeStyle"></div>
								</td>
								<td class="pl-0">
									<div *ngIf="item.info.short_description" class="text-bold">{{item.info.short_description}}</div>
									<div *ngIf="item.info.manufacturer_name || item.info.model" class="text-secondary">{{item.info.manufacturer_name}} {{item.info.manufacturer_name && item.info.model ? '/' : ''}} {{item.info.model}}</div>
									<div *ngIf="item.info.sku || item.info.manufacturer_sku" class="text-secondary text-tiny">{{item.info.sku || item.info.manufacturer_sku}}</div>
								</td>

								<td class="shrink nowrap px-3">
									<ng-container *ngIf="item.info.catalogue_id">
										<span class="cat-image border bg-white border-silver rounded-circle mr-2 shadow-sm" [style.backgroundImage]="'url(\'' + item.info.catalogue_image_url + '\')' | safeStyle" [ngbTooltip]="item.info.catalogue_name"></span>
									</ng-container>
								</td>

								<td class="shrink">
									<input class="form-control text-right" style="width: 7.5rem;" [(ngModel)]="item.quantity" [ngModelOptions]="{ standalone: true }" (input)="recalculatePricing()" (change)="formatNumbers()" [disabled]="!app.orgInfo.role.manage_products" autocomplete="chrome-off">
								</td>
								<td class="shrink">
									<select class="custom-select w-auto" [(ngModel)]="item.unit_id" [ngModelOptions]="{ standalone: true }" (change)="formatNumbers()" [disabled]="!app.orgInfo.role.manage_products">
										<option *ngFor="let unit of item.info.units" [ngValue]="unit.id">{{unit.name}}</option>
									</select>
								</td>
								<td *ngIf="app.orgInfo?.role.can_see_costs" class="text-right"><b>{{item.cost | decimal:2:2:true:'GBP'}}</b></td>
								<td class="text-right"><b>{{item.sell | decimal:2:2:true:'GBP'}}</b></td>
								<td class="shrink nowrap"><button *ngIf="app.orgInfo.role.manage_products" type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="deleteBomProduct(item)"><i class="wq wq-close"></i></button></td>
							</tr>
						</tbody>
					</table>
					<p *ngIf="!details.bom.length" class="text-secondary">No bill of materials found.</p>
					<button *ngIf="app.orgInfo.role.manage_products" type="button" class="btn btn-outline-success btn-sm" (click)="addBomProduct()"><i class="wq wq-add mr-2"></i>Add product</button>
				</div>
			</div>

			<!-- Bundle -->
			<div *ngIf="activeTab === 'bundle' && details && bundle && editable" class="tab-content">

				<h3>Base products</h3>
				<div *ngIf="bundle.record.id && bundle.record.new_version" class="alert alert-info">
					<i class="wq wq-info"></i> To avoid issues with existing products, bundle options will be saved as a new revision.
				</div>
				<table *ngIf="bundle.products.length" class="table table-hover vam">
					<thead>
						<tr>
							<th colspan="2">Product</th>
							<th class="shrink text-right">Quantity</th>
							<th class="shrink nowrap"></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of bundle.products" [ngClass]="{ 'table-danger': item.quantity === 0 }">
							<td class="shrink pr-0">
								<div *ngIf="item.image_url" class="product-image mr-3" [style.backgroundImage]="'url(\'' + item.image_url + '\')' | safeStyle"></div>
							</td>
							<td class="pl-0">
								<div *ngIf="item.short_description" class="text-bold">{{item.short_description}}</div>
								<div *ngIf="item.manufacturer_name || item.model" class="text-secondary">{{item.manufacturer_name}} {{item.manufacturer_name && item.model ? '/' : ''}} {{item.model}}</div>
								<div *ngIf="item.sku || item.manufacturer_sku" class="text-secondary text-tiny">{{item.sku || item.manufacturer_sku}}</div>
							</td>
							<td class="shrink"><input class="form-control text-right" style="width: 6.25rem;" [(ngModel)]="item.quantity" [ngModelOptions]="{ standalone: true }" (change)="bundle.formatProductQuantities()" autocomplete="chrome-off"></td>
							<td class="shrink nowrap"><button type="button" class="btn btn-outline-danger btn-sm border-0 px-1" (click)="bundleRemoveProduct(item)"><i class="wq wq-close"></i></button></td>
						</tr>
					</tbody>
				</table>
				<p *ngIf="!bundle.products.length" class="text-secondary">No base products.</p>
				<button type="button" class="btn btn-outline-success btn-sm" (click)="bundleAddBaseProduct()"><i class="wq wq-add mr-2"></i>Add base products</button>
				<hr>

				<h3>Accumulators</h3>
				<table *ngIf="bundle.counters.length" class="table table-hover vam">
					<thead>
						<tr>
							<th>Description</th>
							<th>Products</th>
							<th class="shrink nowrap"></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of bundle.counters">
							<td>{{item.description}}</td>
							<td>
								<ng-container *ngFor="let p of item.products">
									<div *ngIf="p.image_url" class="product-image d-inline-block mr-2 bg-white shadow" [style.backgroundImage]="'url(\'' + p.image_url + '\')' | safeStyle"></div>
								</ng-container>
							</td>
							<td class="shrink nowrap">
								<button type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="bundleEditCounter(item)"><i class="wq wq-edit"></i></button>
								<button type="button" class="btn btn-outline-danger btn-sm border-0 px-1" (click)="bundleRemoveCounter(item)"><i class="wq wq-close"></i></button>
							</td>
						</tr>
					</tbody>
				</table>
				<p *ngIf="!bundle.counters.length" class="text-secondary">No accumulators.</p>
				<button type="button" class="btn btn-outline-success btn-sm" (click)="bundleEditCounter()"><i class="wq wq-add mr-2"></i>Add accumulator</button>
				<hr>

				<h3>Questions</h3>
				<table *ngIf="bundle.questions.length" class="table table-hover vam">
					<thead>
						<tr>
							<th>Question</th>
							<th class="shrink"></th>
							<th>Info</th>
							<th>Products</th>
							<th class="shrink"></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let q of bundle.questions" [ngClass]="{ 'table-primary': hoveredQuestion?.parent === q, 'table-warning': q.parent === hoveredQuestion, 'table-info': q.parent === hoveredQuestion?.parent, 'dimmed': q.is_hidden === 1 }" (mouseenter)="hoveredQuestion = q" (mouseleave)="hoveredQuestion = null">
							<td [style.paddingLeft.px]="8 + 20 * q.depth" style="width: 33%;">
								<div class="d-flex align-items-center">
									<span *ngIf="q.image_url" class="product-image mr-2 bg-white shadow" [style.backgroundImage]="'url(\'' + q.image_url + '\')' | safeStyle"></span>
									<span *ngIf="!q.image_url" class="product-image mr-2 bg-white shadow"></span>
									<div class="flex-weight-1">
										{{q.question}}
										<ng-container *ngIf="q.is_hidden === 1"><br><span class="badge badge-silver">Hidden</span></ng-container>
										<ng-container *ngIf="q.is_hidden === 2"><br><span class="badge badge-silver">Hidden on web quote</span></ng-container>
									</div>
								</div>
							</td>
							<td class="shrink nowrap">
								<button type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" [ngClass]="{ invisible: hoveredQuestion !== q }" (click)="bundleEditQuestion(q)"><i class="wq wq-add"></i></button>
								<button type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" [ngClass]="{ invisible: hoveredQuestion !== q || q.first_child }" (click)="bundle.moveQuestion(q,-1)"><i class="wq wq-arrow-up"></i></button>
								<button type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" [ngClass]="{ invisible: hoveredQuestion !== q || q.last_child }" (click)="bundle.moveQuestion(q,1)"><i class="wq wq-arrow-down"></i></button>
							</td>
							<td class="text-small">
								<div><b>Type:</b> {{bundleQuestionTypeName(q.type)}}</div>
								<div *ngIf="bundleQuestionDefaultValue(q); let value"><b>Default:</b> {{value}}</div>
								<div *ngIf="bundleQuestionCondition(q); let value"><b>Show if</b> {{value}}</div>
							</td>
							<td>
								<ng-container *ngFor="let p of q.products">
									<div *ngIf="p.image_url" class="product-image mr-1 mb-1 bg-white shadow" [style.backgroundImage]="'url(\'' + p.image_url + '\')' | safeStyle"></div>
								</ng-container>
							</td>
							<td class="shrink nowrap">
								<button type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="bundleEditQuestion(q.parent, q)"><i class="wq wq-edit"></i></button>
								<button type="button" class="btn btn-outline-danger btn-sm border-0 px-1" (click)="bundleRemoveQuestion(q)"><i class="wq wq-close"></i></button>
							</td>
						</tr>
					</tbody>
				</table>
				<p *ngIf="!bundle.questions.length" class="text-secondary">No questions.</p>
				<button type="button" class="btn btn-outline-success btn-sm" (click)="bundleEditQuestion()"><i class="wq wq-add mr-2"></i>Add question</button>

			</div>

			<!-- Accessories -->
			<div *ngIf="activeTab === 'accessories' && details" class="tab-content">

				<table *ngIf="details.accessories.length" class="table table-hover vam">
					<thead>
						<tr>
							<th colspan="2">Product</th>
							<th class="shrink"></th>
							<th class="shrink nowrap text-right">Default Quantity</th>
							<th class="shrink"></th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of details.accessories">
							<td class="shrink pr-0">
								<div *ngIf="item.image_url" class="product-image mr-3" [style.backgroundImage]="'url(\'' + item.image_url + '\')' | safeStyle"></div>
							</td>
							<td class="pl-0">
								<div *ngIf="item.short_description" class="text-bold">{{item.short_description}}</div>
								<div *ngIf="item.manufacturer_name || item.model" class="text-secondary">{{item.manufacturer_name}} {{item.manufacturer_name && item.model ? '/' : ''}} {{item.model}}</div>
								<div *ngIf="item.sku || item.manufacturer_sku" class="text-secondary text-tiny">{{item.sku || item.manufacturer_sku}}</div>
							</td>

							<td class="shrink nowrap px-3">
								<ng-container *ngIf="item.catalogue_id">
									<span class="cat-image border bg-white border-silver rounded-circle mr-2 shadow-sm" [style.backgroundImage]="'url(\'' + item.catalogue_image_url + '\')' | safeStyle"></span>
									{{item.catalogue_name}}
								</ng-container>
							</td>

							<td class="shrink nowrap text-right"><input class="form-control text-right" [(ngModel)]="item.default_quantity" [ngModelOptions]="{ standalone: true }" (change)="formatNumbers()" style="width: 7.5rem;" [disabled]="(!editable && item.by_owner) || !app.orgInfo.role.manage_products" autocomplete="chrome-off"></td>
							<td class="shrink nowrap">
								<button *ngIf="!(!editable && item.by_owner) && app.orgInfo.role.manage_products" type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="deleteAccessory(item)"><i class="wq wq-close"></i></button>
								<i *ngIf="!editable && item.by_owner" class="wq wq-info" ngbTooltip="Provided by catalogue"></i>
							</td>
						</tr>
					</tbody>
				</table>

				<p *ngIf="!details.accessories.length" class="text-secondary">No accessories found.</p>

				<button *ngIf="app.orgInfo.role.manage_products" type="button" class="btn btn-outline-success btn-sm" (click)="addAccessories()"><i class="wq wq-add mr-2"></i>Add accessories</button>
				<button *ngIf="filteredQuoteAccessories().length && app.orgInfo.role.manage_products" type="button" class="btn btn-outline-success btn-sm ml-2" (click)="copyQuoteAccessories()"><i class="wq wq-add mr-2"></i>Add accessories from quote item</button>

				<div *ngIf="details.override?.hide_accessories === null" class="d-flex mt-3 align-items-center">
					<label class="custom-control custom-checkbox d-inline-block my-0">
						<input type="checkbox" class="custom-control-input" [(ngModel)]="hideAccessories" [ngModelOptions]="{ standalone: true }" [disabled]="!editable || !app.orgInfo.role.manage_products">
						<span class="custom-control-label">Hide accessories on proposal<i class="wq wq-info ml-2" ngbTooltip="Controls whether to hide accessories of this product on proposals by default. Can be changed per quote line."></i></span>
					</label>
					<button *ngIf="!editable && details.override" type="button" class="btn btn-sm btn-medium px-1 ml-2" (click)="details.override.hide_accessories = details.hide_accessories || 0" ngbTooltip="Override hide accessories"><i class="wq wq-edit"></i></button>
				</div>
				<div *ngIf="details.override?.hide_accessories !== null" class="d-flex mt-3 align-items-center">
					<label class="custom-control custom-checkbox d-inline-block my-0">
						<input type="checkbox" class="custom-control-input" [(ngModel)]="overrideHideAccessories" [ngModelOptions]="{ standalone: true }">
						<span class="custom-control-label">Hide accessories on proposal<i class="wq wq-info ml-2" ngbTooltip="Controls whether to hide accessories of this product on proposals by default. Can be changed per quote line."></i></span>
					</label>
					<button type="button" class="btn btn-sm btn-warning px-1 ml-2" (click)="details.override.hide_accessories = null" ngbTooltip="Restore hide accessories"><i class="wq wq-edit"></i></button>
				</div>

			</div>

			<!-- Used by -->
			<div *ngIf="activeTab === 'used' && details" class="tab-content">
				<h3>Totals</h3>
				<div class="card-box">
					<table class="table table-hover">
						<thead>
							<tr>
								<th>Usage</th>
								<th>Total</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngIf="usedBy.system.length">
								<td>Systems</td>
								<td>{{usedBy.system.length}} {{usedBy.system.length === 1 ? 'system' : 'systems'}}</td>
							</tr>
							<tr *ngIf="usedBy.bom.length">
								<td>Bill of materials</td>
								<td>{{usedBy.bom.length}} {{usedBy.bom.length === 1 ? 'product' : 'products'}}</td>
							</tr>
						</tbody>
					</table>
				</div>

				<h3>Details</h3>
				<div class="card-box">
					<table *ngIf="usedBy.system.length" class="table table-hover">
						<thead>
							<tr>
								<th class="shrink">#</th>
								<th>System</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of usedBy.system">
								<td class="shrink">{{item.id}}</td>
								<td>{{item.description}}</td>
							</tr>
						</tbody>
					</table>
					<table *ngIf="usedBy.bom.length" class="table table-hover">
						<thead>
							<tr>
								<th>Usage</th>
								<th>SKU</th>
								<th>Manufacturer</th>
								<th>Model</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of usedBy.bom">
								<td>Bill of materials</td>
								<td>{{item.sku}}</td>
								<td>{{item.manufacturer_name}}</td>
								<td>{{item.model}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

		</ng-container>

		<button *ngIf="id !== 'new' && app.orgInfo.role.manage_products && editable && !quoteId" type="button" class="btn btn-danger" (click)="deleteProduct()" [disabled]="disabled" ui-modal-footer><i class="wq wq-delete mr-2"></i>Delete</button>
		<button *ngIf="id !== 'new' && editable && app.orgInfo.role.manage_products && !quoteId && !noClone" type="button" class="btn btn-medium" (click)="cloneProduct()" [disabled]="disabled" ui-modal-footer><i class="wq wq-copy mr-2"></i>Clone this product</button>
		<div *ngIf="!editable && hasOverride()" class="text-warning" ui-modal-footer><i class="wq wq-warning mr-1"></i> Fields that are overridden will not be kept in sync with the catalogue.</div>
		<div class="flex-weight-1" ui-modal-footer></div>
		<button *ngIf="!app.orgInfo.role.manage_products" type="button" class="btn btn-medium" (click)="modal.close()" [disabled]="disabled" ui-modal-footer>Close</button>
		<button *ngIf="app.orgInfo.role.manage_products" type="button" class="btn btn-medium" (click)="modal.close()" [disabled]="disabled" ui-modal-footer>Cancel</button>
		<button *ngIf="app.orgInfo.role.manage_products" type="submit" class="btn btn-primary" (click)="save()" [disabled]="disabled" ui-modal-footer>Save</button>
	</ui-modal>
</form>

<modal-select-product (close)="productSelectModalClosed($event)"></modal-select-product>
<modal-clone-product (close)="productCloneModalClosed($event)"></modal-clone-product>
<modal-select-entity (close)="productEntitySelectModalClosed($event)"></modal-select-entity>
<modal-edit-bundle-counter></modal-edit-bundle-counter>
<modal-edit-bundle-question></modal-edit-bundle-question>
<modal-edit-entity (close)="editEntityModalClosed($event)"></modal-edit-entity>
<modal-edit-product-type (close)="editProductTypeModalClosed($event)"></modal-edit-product-type>
<modal-edit-labour (close)="editLabourModalClosed($event)"></modal-edit-labour>
<modal-edit-pricing-structure (close)="editPricingStructureModalClosed($event)"></modal-edit-pricing-structure>
<modal-help-formula></modal-help-formula>
