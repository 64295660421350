<form>
	<ui-modal modalTitle="Delivery Address Details">
		<ng-container *ngIf="data">
			<ui-form labelWidth="10rem">
				<ui-form-control label="Address">
					<input class="form-control" [(ngModel)]="data.address_line_1" [ngModelOptions]="{ standalone: true }" [disabled]="!editable" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control>
					<input class="form-control" [(ngModel)]="data.address_line_2" [ngModelOptions]="{ standalone: true }" [disabled]="!editable" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control>
					<input class="form-control" [(ngModel)]="data.address_line_3" [ngModelOptions]="{ standalone: true }" [disabled]="!editable" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Town">
					<input class="form-control" [(ngModel)]="data.posttown" [ngModelOptions]="{ standalone: true }" [disabled]="!editable" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="County">
					<input class="form-control" [(ngModel)]="data.county" [ngModelOptions]="{ standalone: true }" [disabled]="!editable" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Postcode">
					<input class="form-control w-50" [(ngModel)]="data.postcode" [ngModelOptions]="{ standalone: true }" [disabled]="!editable" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Country">
					<input class="form-control" [(ngModel)]="data.country" [ngModelOptions]="{ standalone: true }" [disabled]="!editable" autocomplete="chrome-off">
				</ui-form-control>
				<hr>
			</ui-form>
		</ng-container>

		<button type="button" class="btn btn-secondary" (click)="modal.close()" ui-modal-footer>{{editable ? 'Cancel' : 'Close'}}</button>
		<button *ngIf="editable" type="submit" class="btn btn-primary" (click)="saveButton()" ui-modal-footer>Save</button>
	</ui-modal>
</form>
