import { Component } from '@angular/core';
import { ApiService } from 'app/api.service';
import { Pagination } from 'app/shared/pagination';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

@Component({
	selector: 'modal-select-entity',
	templateUrl: './modal-select-entity.component.html'
})
export class ModalSelectEntityComponent extends UIModalBaseComponent {

	title = '';
	list: any[] = [];
	search = '';
	pagination = new Pagination();

	constructor(
		private api: ApiService,
	) {
		super();
	}

	open(mdata: any) {
		this.title = 'Select Entity';
		if (mdata.filters.is_manufacturer) this.title = 'Select Manufacturer';
		if (mdata.filters.is_supplier) this.title = 'Select Supplier';

		this.api.entity.list(mdata.filters, data => {
			this.list = data.list;
		});

		this.modal.open();
	}

	selectItem(item) {
		this.modal.close(item);
	}

}
