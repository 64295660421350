import { Component } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

@Component({
	selector: 'modal-edit-product-type',
	templateUrl: './modal-edit-product-type.component.html'
})
export class ModalEditProductTypeComponent extends UIModalBaseComponent {

	id;
	data;

	disabled = false;

	constructor(
		private app: AppService,
		private api: ApiService,
	) {
		super();
	}

	open(mdata: any) {
		this.id = mdata.id;
		this.data = null;

		if (this.id === 'new') {
			this.disabled = true;
			this.api.product.newProductType(data => {
				this.disabled = false;
				this.data = data;
				this.modal.open();
			}, error => {
				this.disabled = false;
				this.app.notifications.showDanger(error.message);
			});
		} else {
			this.disabled = true;
			this.api.product.getProductType(this.id, data => {
				this.disabled = false;
				this.data = data;
				this.modal.open();
			}, error => {
				this.disabled = false;
				this.app.notifications.showDanger(error.message);
			});
		}
	}

	saveButton() {
		this.disabled = true;
		this.api.product.saveProductType(this.data.details, savedId => {
			this.disabled = false;
			this.app.notifications.showSuccess(this.data.details.id === 'new' ? 'Product type created.' : 'Product type updated.');
			this.modal.close(savedId);
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

	deleteButton() {
		this.disabled = true;
		this.api.product.deleteProductType(this.data.details.id, () => {
			this.disabled = false;
			this.app.notifications.showSuccess('Product type deleted.');
			this.modal.close('deleted');
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

}
