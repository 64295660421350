<div class="mr-3">
	<ui-grid-row>
		<ui-grid-col>
			<h1 class="pb-1">Quote</h1>
		</ui-grid-col>
	</ui-grid-row>
	<h2 class="pt-2 pb-2">Requests</h2>

	<ui-grid-row>
		<ui-grid-col>
			<ui-card widget header="quote/list">
				<div>
					<i>Retrieve a list of Quotes, in groups of 10, ordered by most recent first</i>
				</div>
				<div class="pt-2">
					<hr>
					<h4>Request</h4>
					<table>
						<tr>
							<td class="p-3 border-right">
								type
							</td>
							<td class="pl-2">GET</td>
						</tr>
						<tr>
							<td class="p-3 border-right">
								parameters
							</td>
							<td class="pl-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2 pr-2 border-right">Required</th>
										<th class="pl-2, pr-2 text-center">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">page</td>
										<td class="pl-2 pr-2 border-right">number</td>
										<td class="pl-2 pr-2 border-right">false</td>
										<td class="pl-3 pr-2 text-left">The page you wish to view</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td class="pl-3 border-right">
								response
							</td>
							<td class="pl-2">
								Each quote in the list is returned with the following information:
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2, pr-2 text-center ">Description</th>
									</tr>
									<tr class="border-bottom text-left">
										<td class="pr-2 border-right">id</td>
										<td class="pl-2 pr-2 text-center border-right">number</td>
										<td class="pl-3 pr-2 text-left">WeQuote's internal ID representing the Quote. This can be used with <code>quote/get</code> to access more detailed information</td>
									</tr>
									<tr class="border-bottom text-left">
										<td class="pr-2 border-right">quote_no</td>
										<td class="pl-2 pr-2 text-center border-right">string</td>
										<td class="pl-3 pr-2 text-left">The quote number associated with each quote. This was either generated by WeQuote or manually inserted by you</td>
									</tr>
									<tr class="border-bottom text-left">
										<td class="pr-2 border-right">description</td>
										<td class="pl-2 pr-2 text-center border-right">string</td>
										<td class="pl-3 pr-2 text-left">The name given to your quote. Other services may call this a title or name</td>
									</tr>
									<tr class="border-bottom text-left">
										<td class="pr-2 border-right">quote_title</td>
										<td class="pl-2 pr-2 text-center border-right">string</td>
										<td class="pl-3 pr-2 text-left">A special field generated for the list. This is a combination of the quote's number and description fields <br> e.g. <code>4: Smart TV Installation</code></td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td class="p-3 border-right">
								Errors
							</td>
							<td class="pl-2">
								For a list of possible errors, please see the errors section at the end of <a href="/developer">Getting Started</a>
							</td>
						</tr>
					</table>
				</div>
			</ui-card>
		</ui-grid-col>
	</ui-grid-row>

	<ui-grid-row>
		<ui-grid-col>
			<ui-card widget header="quote/poll_updated">
				<div>
					<i>Retrieve a list of Quotes that have been recently updated</i><br>
					This request is intended for testing <a href="developer/webhook">webhook</a> calls from services such as Zapier. <br>
					It is recommended to use <a href="/developer/webhook">webhook/subscribe</a> to receive updates in real time
				</div>
				<div class="pt-2">
					<hr>
					<h4>Request</h4>
					<table>
						<tr>
							<td class="p-3 border-right">
								type
							</td>
							<td class="pl-2">GET</td>
						</tr>
						<tr>

							<td class="p-3 border-right">
								parameters
							</td>
							<td class="pl-2">
								No parameters are required. As long as you have a valid API key, you will receive a list of the 10 most recently updated quotes
							</td>
						</tr>
						<tr>
							<td class="pl-3 border-right">
								response
							</td>
							<td class="pl-2">
								Each quote in the list is returned with the following information:
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2, pr-2 text-center ">Description</th>
									</tr>
									<tr class="border-bottom text-left">
										<td class="pr-2 border-right">quote_id</td>
										<td class="pl-2 pr-2 text-center border-right">number</td>
										<td class="pl-3 pr-2 text-left">WeQuote's internal ID representing the Quote. This can be used with <code>quote/get</code> to access more detailed information</td>
									</tr>
									<tr class="border-bottom text-left">
										<td class="pr-2 border-right">old_stage</td>
										<td class="pl-2 pr-2 text-center border-right">string</td>
										<td class="pl-3 pr-2 text-left">The previous stage of the quote, before it was updated</td>
									</tr>
									<tr class="border-bottom text-left">
										<td class="pr-2 border-right">new_stage</td>
										<td class="pl-2 pr-2 text-center border-right">string</td>
										<td class="pl-3 pr-2 text-left">The new/current stage of the Quote</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td class="p-3 border-right">
								Errors
							</td>
							<td class="pl-2">
								For a list of possible errors, please see the errors section at the end of <a href="/developer">Getting Started</a>
							</td>
						</tr>
					</table>
				</div>
			</ui-card>
		</ui-grid-col>
	</ui-grid-row>

	<ui-grid-row>
		<ui-grid-col>
			<ui-card widget header="quote/get">
				<div>
					<i>Retrieve a Quote using it's unique ID</i>
				</div>
				<div class="pt-2">
					<hr>
					<h4>Request</h4>
					<table>
						<tr>
							<td class="p-3 border-right">
								type
							</td>
							<td class="pl-2">GET</td>
						</tr>
						<tr>
							<td class="p-3 border-right">
								parameters
							</td>
							<td class="pl-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2 pr-2 border-right">Required</th>
										<th class="pl-2, pr-2 text-center">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">id</td>
										<td class="pl-2 pr-2 border-right">number</td>
										<td class="pl-2 pr-2 border-right">true</td>
										<td class="pl-3 pr-2 text-left">The quote's internal ID</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td class="pl-3 border-right">
								response
							</td>
							<td class="pl-2">
								Responses from this endpoint include all the fields sent in your request in addition to the fields listed below.
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2, pr-2 text-center ">Description</th>
									</tr>
									<tr class="border-bottom text-left">
										<td class="pr-2 border-right">Quote</td>
										<td class="pl-2 pr-2 text-center border-right">JSON</td>
										<td class="pl-3 pr-2 text-left">When a quote is retrieved, the entire Quote's latest revision is returned without any quote lines or area information</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td class="p-3 border-right">
								Errors
							</td>
							<td class="pl-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Code</th>
										<th class="pl-2 pr-2 border-right">Text</th>
										<th class="pl-2, pr-2 text-center">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right"></td>
										<td class="pl-2 pr-2 border-right">Quote ID is required</td>
										<td class="pl-3 pr-2 text-left">Your request's URL parameters did not include the <code>id</code> parameter</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right"></td>
										<td class="pl-2 pr-2 border-right">Quote not found</td>
										<td class="pl-3 pr-2 text-left">A quote with this <code>id</code> does not exist, or has been archived or deleted</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">403</td>
										<td class="pl-2 pr-2 border-right">Access Denied: Quote Belongs to another organisation.</td>
										<td class="pl-3 pr-2 text-left">The quote you are requesting belongs to an organisation not associated with the supplied Api-Key</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>

				</div>
			</ui-card>
		</ui-grid-col>
	</ui-grid-row>


	<!--quote/create-->
	<ui-grid-row class="pt-4">
		<ui-grid-col>
			<ui-card widget header="quote/create">
				<div>
					<i>Create a Quote with the supplied information</i>
				</div>
				<div class="pt-2">
					<hr>
					<h4>Request</h4>
					<table>
						<tr>
							<td class="p-3 border-right">
								type
							</td>
							<td class="pl-2">POST</td>
						</tr>
						<tr>
							<td class="p-3 border-right">
								parameters
							</td>
							<td class="pl-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2 pr-2 border-right">Required</th>
										<th class="pl-2, pr-2 text-center">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">quote_no</td>
										<td class="pl-2 pr-2 border-right">string</td>
										<td class="pl-2 pr-2 border-right">false</td>
										<td class="pl-3 pr-2 text-left">
											Your organisation's Quote Number. Can use any format you want.<br>
											Leave this blank if you want WeQuote to generate a number using your organisation's settings
										</td>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">customer_id</td>
										<td class="pl-2 pr-2 border-right">string</td>
										<td class="pl-2 pr-2 border-right">true</td>
										<td class="pl-3 pr-2 text-left">
											WeQuote's internal ID for the customer this quote belongs to. You can retrieve this with <a href="developer/customer">customer/list</a> or <a href="developer/customer">customer/find</a><br>
											This field is required to successfully create a quote
										</td>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">description</td>
										<td class="pl-2 pr-2 border-right">string</td>
										<td class="pl-2 pr-2 border-right">true</td>
										<td class="pl-3 pr-2 text-left">
											The description, or name, of the quote.
										</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td class="pl-3 border-right">
								response
							</td>
							<td class="pl-2">
								Responses from this endpoint include all the Quote information available to you in WeQuote, in addition to the fields below
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2, pr-2 text-center ">Description</th>
									</tr>
									<tr class="border-bottom text-left">
										<td class="pr-2 border-right">id</td>
										<td class="pl-2 pr-2 text-center border-right">number</td>
										<td class="pl-3 pr-2 text-left">The Quote's Internal WeQuote ID</td>
									</tr>
									<tr class="border-bottom text-left">
										<td class="pr-2 border-right">organisation_id</td>
										<td class="pl-2 pr-2 text-center border-right">number</td>
										<td class="pl-3 pr-2 text-left">The organisation associated with this Quote</td>
									</tr>
									<tr class="border-bottom text-left">
										<td class="pr-2 border-right">user_id</td>
										<td class="pl-2 pr-2 text-center border-right">number</td>
										<td class="pl-3 pr-2 text-left">The user who created this Quote. If an API request was used this will refer to the user who created the Api-Key</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td class="p-3 border-right">
								Errors
							</td>
							<td class="pl-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Code</th>
										<th class="pl-2 pr-2 border-right">Text</th>
										<th class="pl-2, pr-2 text-center">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right"></td>
										<td class="pl-2 pr-2 border-right">customer_id and description are required</td>
										<td class="pl-3 pr-2 text-left">Your request's POST body does not include a JSON object with the required fields</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right"></td>
										<td class="pl-2 pr-2 border-right">Customer not found</td>
										<td class="pl-3 pr-2 text-left">The supplied customer number does not reference an existing customer</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">403</td>
										<td class="pl-2 pr-2 border-right">Could not create quote</td>
										<td class="pl-3 pr-2 text-left">An unexpected error prevented WeQuote from creating your Quote. This is most likely an internal server error. Please reach out to us for extra support.</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>
				</div>
			</ui-card>
		</ui-grid-col>
	</ui-grid-row>
</div>
