import { Component, ElementRef, Input, NgZone, OnChanges, OnInit, ViewChild } from '@angular/core';
import { UIDataService } from './ui-data.service';

export interface UITreeNodeAction {
	icon: string;
	buttonClass?: string;
	tooltip?: string | ((any) => string);
}

export interface UITreeNodeProgress {
	color?: string;
	percent: number;
	tooltip?: string;
}

export interface UITreeData {
	hasChildren(node: any): boolean;
	getChildren(node: any): any[];
	getParent(node: any): any;

	getActions(node: any): UITreeNodeAction[];

	getDescription(node: any): string;
	getDescriptionClass(node: any): string;
	getInfo(node: any): string;
	getInfoClass(node: any): string;
	getNodeIcon?(node: any): string | null;
	getNodeClass?(node: any): string;

	hasProgress(node: any): boolean;
	getProgress(node: any): UITreeNodeProgress[];

	isExpanded(node: any): boolean;
	isSelected(node: any): boolean;
	canHover(node: any): boolean;
	canSelect(node: any): boolean;
	canDrag(node: any): boolean;
	canDrop(node: any, targetParent: any, targetPosition: number): boolean;

	expand(node: any);
	collapse(node: any);
	select(node: any, clicked: boolean);
	action(node: any, action: UITreeNodeAction);
	drop(node: any, targetParent: any, targetPosition: number);
}

@Component({
	selector: 'ui-tree',
	template:
		`<div [ngClass]="{ 'drop-cursor': service.treeDragNode }">
			<div *ngFor="let node of data.getChildren(null); let first = first; let last = last">
				<ui-tree-node [node]="node" [parent]="null" [first]="first" [last]="last" [isRoot]="true" [depth]="1"></ui-tree-node>
			</div>
			<div *ngIf="service.treeDragNode" class="drop-site drop-site-bottom drop-depth-0" [ngClass]="{ 'drag-over': service.treeDropNode === 'root' && service.treeDropPos === 'bottom' }" (dragenter)="dragEnter($event, 'bottom')" (dragleave)="dragLeave($event)" (drop)="drop($event)" #dropElement>
				<div class="d-flex align-items-center">
					<div class="bg-primary drop-indicator"></div>
					<div class="flex-weight-1 border border-primary"></div>
				</div>
			</div>
		</div>`,
	styles: [`
		.drop-cursor { cursor: move; }

		.drop-site > div { opacity: 0; }
		.drop-site.drag-over > div { opacity: 1; }

		.drop-site { position: relative; overflow: visible; height: 0; }
		.drop-site > div { position: absolute; top: -0.4rem; height: 0.6rem; width: 100%; }
		.drop-site > div > * { pointer-events: none }
		.drop-site-top > div { top: -0.4rem; }
		.drop-site-bottom > div { top: -0.65rem; }
		.drop-indicator { margin-left: 0.3rem; width: 0.4rem; height: 0.4rem; border-radius: 50%; }

		.drop-depth-0 { z-index: 2000; }
		.drop-depth-1 { z-index: 2010; }
		.drop-depth-2 { z-index: 2020; }
		.drop-depth-3 { z-index: 2030; }
		.drop-depth-4 { z-index: 2040; }
		.drop-depth-5 { z-index: 2050; }
		.drop-depth-6 { z-index: 2060; }
		.drop-depth-7 { z-index: 2070; }
		.drop-depth-8 { z-index: 2080; }
		.drop-depth-9 { z-index: 2090; }
		.drop-depth-10 { z-index: 2100; }
	`],
	providers: [UIDataService]
})
export class UITreeComponent implements OnInit, OnChanges {

	@Input() data: UITreeData;
	@Input() showActions = false;

	// DragOver event must be handled in order to allow the Drop event to fire.
	// However, it must be run outside of Angular, otherwise it will trigger change detection every few milliseconds

	private _dropElement;
	@ViewChild('dropElement', { static: false }) set dropElement(element: ElementRef) {
		if (element !== this._dropElement) {
			this._dropElement = element;
			if (element) {
				this.zone.runOutsideAngular(() => {
					element.nativeElement.addEventListener('dragover', this.dragOver);
				});
			}
		}
	}

	constructor(public service: UIDataService, private zone: NgZone) { }

	ngOnInit(): void {
		this.refresh();
	}

	ngOnChanges() {
		this.service.treeShowActions = this.showActions;
		this.refresh();
	}

	refresh() {
		this.service.treeData = this.data;
		this.service.treeDragNode = null;
		this.service.treeDropNode = null;
		this.service.treeDropPos = '';
		this.service.treeBlockLeave = false;
		this.service.treeBlockTimer = null;
		this.service.treeTargetParent = null;
		this.service.treeTargetPosition = -1;
	}

	dragEnter(e, pos) {
		e.stopPropagation();
		e.preventDefault();
		if (!this.service.treeDragNode) return;

		this.service.treeDropNode = null;

		// Make sure we ignore overlapping leave events which can fire out of order
		this.service.treeBlockLeave = true;
		clearTimeout(this.service.treeBlockTimer);
		this.service.treeBlockTimer = setTimeout(() => {
			this.service.treeBlockLeave = false;
		}, 0);

		// Resolve item drop position in parent
		let targetParent = null;
		let targetPosition = -1;
		let children = [];
		switch (pos) {
			case 'bottom':
				targetParent = null;
				targetPosition = -1;
				break;
		}

		// Check if resulting position is the same as current position
		// If so, don't allow.
		children = targetParent ? this.service.treeData.getChildren(targetParent) : this.service.treeData.getChildren(null);
		if (targetPosition === -1) {
			if (children[children.length - 1] === this.service.treeDragNode) return;
		} else {
			if (children[targetPosition] === this.service.treeDragNode) return;
		}

		// Make sure target is not parented to dragged node
		const parents = [];
		let n = targetParent;
		while (n) {
			parents.push(n);
			n = this.service.treeData.getParent(n);
		}
		if (parents.indexOf(this.service.treeDragNode) !== -1) return;

		if (!this.service.treeData.canDrop(this.service.treeDragNode, targetParent, targetPosition)) return;

		this.service.treeDropNode = 'root';
		this.service.treeDropPos = pos;
		this.service.treeTargetParent = targetParent;
		this.service.treeTargetPosition = targetPosition;
	}

	dragOver(e) {
		e.preventDefault();
	}

	dragLeave(e) {
		e.stopPropagation();
		if (!this.service.treeBlockLeave) {
			this.service.treeDropNode = null;
			this.service.treeDropPos = '';
			this.service.treeTargetParent = null;
			this.service.treeTargetPosition = -1;
		}
	}

	drop(e) {
		e.stopPropagation();

		if (this.service.treeDragNode && this.service.treeDropNode) {
			this.service.treeData.drop(this.service.treeDragNode, this.service.treeTargetParent, this.service.treeTargetPosition);
		}

		this.service.treeDragNode = null;
		this.service.treeDropNode = null;
		this.service.treeDropPos = '';
		this.service.treeBlockLeave = false;
		this.service.treeBlockTimer = null;
		this.service.treeTargetParent = null;
		this.service.treeTargetPosition = -1;
	}

}

@Component({
	selector: 'ui-tree-node',
	template:
		`<div *ngIf="!isRoot && first" class="pl-3 pt-2 ml-2 mt-n2" [ngClass]="{ 'border-secondary border-left border-dotted': nodeIcon() }">
		</div>
		<div>
			<div *ngIf="service.treeDragNode" class="drop-site drop-site-top drop-depth-{{depth}}" [ngClass]="{ 'drag-over': service.treeDropNode === node && service.treeDropPos === 'top' }" (dragenter)="dragEnter($event, 'top')" (dragleave)="dragLeave($event)" (drop)="drop($event)" #dropElementTop>
				<div class="d-flex align-items-center">
					<div class="bg-primary drop-indicator"></div>
					<div class="flex-weight-1 border border-primary"></div>
				</div>
			</div>
			<div class="d-flex align-items-center m-n1 p-1 text-medium text-bold rounded {{nodeClass()}}" [ngClass]="{ 'progress-spacing': hasProgress(), hoverable: canHover() && !service.treeDragNode, 'bg-silver-50': service.treeDragNode === node, 'bg-primary-50': service.treeDragNode && service.treeDragNode !== node && service.treeDropNode === node && service.treeDropPos === 'node' }" (mouseenter)="isHovered = true" (mouseleave)="isHovered = false" [draggable]="service.treeData.canDrag(node)" (dragstart)="dragStart($event)" (dragend)="dragEnd()" (dragenter)="dragEnter($event, 'node')" (dragleave)="dragLeave($event)" (drop)="drop($event)" #dropElement>
				<div class="mr-2 text-large selectable position-relative" (click)="toggleNode()">
					<i class="wq {{nodeIcon()}}" [ngClass]="{ 'text-primary': isSelected() }"></i>
				</div>
				<div class="text-truncate flex-weight-1 user-select-none {{service.treeData.getDescriptionClass(node)}}" style="padding: 0.125rem 0;" [ngClass]="{ selectable: canSelect() }" (click)="selectNode()" uiEllipsisTooltip>{{service.treeData.getDescription(node)}}</div>
				<div *ngIf="service.treeData.getInfo(node)" class="text-truncate user-select-none {{service.treeData.getInfoClass(node)}}" style="padding: 0.125rem 0;" [ngClass]="{ selectable: canSelect() }" (click)="selectNode()">{{service.treeData.getInfo(node)}}</div>
				<div *ngIf="(isHovered || service.treeShowActions) && !service.treeDragNode">
					<button *ngFor="let action of service.treeData.getActions(node)" class="btn btn-sm btn-outline-secondary border-0 px-1 ml-1 {{action.buttonClass || ''}}" style="margin-top: -0.063rem; margin-bottom: -0.063rem;" (click)="fireAction(action)" [ngbTooltip]="getActionTooltip(action.tooltip)" tooltipClass="text-pre"><i class="{{action.icon}}"></i></button>
				</div>
			</div>
			<div class="pl-3 pt-2 ml-2 border-secondary position-relative" [ngClass]="{ 'border-left border-dotted': !last && nodeIcon() }">
				<div *ngIf="hasProgress()" class="border-secondary mt-n1 pb-2" [ngClass]="{ selectable: canSelect() }" (click)="selectNode()">
					<div *ngIf="getProgress(); let progressList" class="progress" style="height: 0.5rem;">
						<div *ngFor="let p of progressList" class="progress-bar bg-{{p.color || 'success'}}" [style.width.%]="p.percent" [title]="p.tooltip || ''"></div>
					</div>
				</div>
				<ng-container *ngIf="service.treeData.isExpanded(node) && service.treeData.hasChildren(node)">
					<div *ngFor="let child of service.treeData.getChildren(node); let first = first; let last = last">
						<ui-tree-node [node]="child" [parent]="node" [first]="first" [last]="last" [depth]="depth + 1"></ui-tree-node>
					</div>
					<div *ngIf="service.treeDragNode" class="drop-site drop-site-bottom drop-depth-{{depth + 1}}" [ngClass]="{ 'drag-over': service.treeDropNode === node && service.treeDropPos === 'bottom' }" (dragenter)="dragEnter($event, 'bottom')" (dragleave)="dragLeave($event)" (drop)="drop($event)" #dropElementBottom>
						<div class="d-flex align-items-center">
							<div class="bg-primary drop-indicator"></div>
							<div class="flex-weight-1 border border-primary"></div>
						</div>
					</div>
				</ng-container>
			</div>
		</div>`,
	styles: [`
		.drop-site > div { opacity: 0; }
		.drop-site.drag-over > div { opacity: 1; }

		.drop-site { position: relative; overflow: visible; height: 0; }
		.drop-site > div { position: absolute; top: -0.4rem; height: 0.6rem; width: 100%; }
		.drop-site > div > * { pointer-events: none }
		.drop-site-top > div { top: -0.4rem; }
		.drop-site-bottom > div { top: -0.65rem; }
		.drop-indicator { margin-left: 0.3rem; width: 0.4rem; height: 0.4rem; border-radius: 50%; }

		.drop-depth-0 { z-index: 2000; }
		.drop-depth-1 { z-index: 2010; }
		.drop-depth-2 { z-index: 2020; }
		.drop-depth-3 { z-index: 2030; }
		.drop-depth-4 { z-index: 2040; }
		.drop-depth-5 { z-index: 2050; }
		.drop-depth-6 { z-index: 2060; }
		.drop-depth-7 { z-index: 2070; }
		.drop-depth-8 { z-index: 2080; }
		.drop-depth-9 { z-index: 2090; }
		.drop-depth-10 { z-index: 2100; }

		.progress-spacing { margin-bottom: -1.2rem !important; padding-bottom: 1.2rem !important; }
	`]
})
export class UITreeNodeComponent implements OnInit {

	@Input() node: any;
	@Input() parent: any;
	@Input() first;
	@Input() last;
	@Input() isRoot = false;
	@Input() depth: number;

	// DragOver event must be handled in order to allow the Drop event to fire.
	// However, it must be run outside of Angular, otherwise it will trigger change detection every few milliseconds

	private _dropElement;
	@ViewChild('dropElement', { static: false }) set dropElement(element: ElementRef) {
		if (element !== this._dropElement) {
			this._dropElement = element;
			if (element) {
				this.zone.runOutsideAngular(() => {
					element.nativeElement.addEventListener('dragover', this.dragOver);
				});
			}
		}
	}

	private _dropElementTop;
	@ViewChild('dropElementTop', { static: false }) set dropElementTop(element: ElementRef) {
		if (element !== this._dropElementTop) {
			this._dropElementTop = element;
			if (element) {
				this.zone.runOutsideAngular(() => {
					element.nativeElement.addEventListener('dragover', this.dragOver);
				});
			}
		}
	}

	private _dropElementBottom;
	@ViewChild('dropElementBottom', { static: false }) set dropElementBottom(element: ElementRef) {
		if (element !== this._dropElementBottom) {
			this._dropElementBottom = element;
			if (element) {
				this.zone.runOutsideAngular(() => {
					element.nativeElement.addEventListener('dragover', this.dragOver);
				});
			}
		}
	}

	isHovered = false;

	constructor(public service: UIDataService, private zone: NgZone) { }

	ngOnInit(): void {
	}

	nodeIcon() {
		if (this.service.treeData.getNodeIcon) {
			const icon = this.service.treeData.getNodeIcon(this.node);
			if (icon !== null) return icon;
		}

		if (this.service.treeData.isSelected(this.node)) {
			if (!this.service.treeData.hasChildren(this.node)) return 'wq-tree-full';
			return this.service.treeData.isExpanded(this.node) ? 'wq-tree-full-minus' : 'wq-tree-full-plus';
		} else {
			if (!this.service.treeData.hasChildren(this.node)) return 'wq-tree-empty';
			return this.service.treeData.isExpanded(this.node) ? 'wq-tree-minus' : 'wq-tree-plus';
		}
	}

	nodeClass() {
		return this.service.treeData.getNodeClass?.(this.node) || '';
	}

	toggleNode() {
		if (this.service.treeData.isExpanded(this.node)) {
			this.service.treeData.collapse(this.node);
		} else {
			this.service.treeData.expand(this.node);
		}
		if (this.service.treeData.canSelect(this.node)) {
			this.service.treeData.select(this.node, true);
		}
	}

	selectNode() {
		if (this.service.treeData.canSelect(this.node)) this.service.treeData.select(this.node, true);
	}

	hasProgress() {
		return this.service.treeData.hasProgress(this.node);
	}

	getProgress() {
		return this.service.treeData.getProgress(this.node);
	}

	canHover() {
		return this.service.treeData.canHover(this.node);
	}

	canSelect() {
		return this.service.treeData.canSelect(this.node);
	}

	isSelected() {
		return this.service.treeData.isSelected(this.node);
	}

	fireAction(action) {
		this.service.treeData.action(this.node, action);
	}

	dragStart(e) {
		e.stopPropagation();
		this.service.treeBlockLeave = false;
		this.service.treeBlockTimer = null;

		this.service.treeDragNode = this.node;
		this.service.treeDropNode = null;
		this.service.treeDropPos = '';

		const img = new Image();
		img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
		e.dataTransfer.setDragImage(img, 0, 0);
	}

	dragEnd() {
		this.service.treeDragNode = null;
		this.service.treeDropNode = null;
		this.service.treeDropPos = '';
		this.service.treeBlockLeave = false;
		this.service.treeBlockTimer = null;
		this.service.treeTargetParent = null;
		this.service.treeTargetPosition = -1;
	}

	drop(e) {
		e.stopPropagation();

		if (this.service.treeDragNode && this.service.treeDropNode) {
			this.service.treeData.drop(this.service.treeDragNode, this.service.treeTargetParent, this.service.treeTargetPosition);
		}

		this.service.treeDragNode = null;
		this.service.treeDropNode = null;
		this.service.treeDropPos = '';
		this.service.treeBlockLeave = false;
		this.service.treeBlockTimer = null;
		this.service.treeTargetParent = null;
		this.service.treeTargetPosition = -1;
	}

	dragOver(e) {
		e.preventDefault();
	}

	dragEnter(e, pos) {
		e.stopPropagation();
		e.preventDefault();
		if (!this.service.treeDragNode) return;

		this.service.treeDropNode = null;

		// Make sure we ignore overlapping leave events which can fire out of order
		this.service.treeBlockLeave = true;
		clearTimeout(this.service.treeBlockTimer);
		this.service.treeBlockTimer = setTimeout(() => {
			this.service.treeBlockLeave = false;
		}, 0);

		// Resolve item drop position in parent
		let targetParent = null;
		let targetPosition = -1;
		let children = null;
		let index = 0;
		let oldIndex = 0;
		switch (pos) {
			case 'top':
				targetParent = this.parent;
				children = targetParent ? this.service.treeData.getChildren(targetParent) : this.service.treeData.getChildren(null);
				index = children.indexOf(this.service.treeDragNode);
				if (index !== -1) {
					if (this.node === this.service.treeDragNode) {
						targetPosition = index;
					} else {
						oldIndex = index;
						index = children.indexOf(this.node);
						if (index > oldIndex) index -= 1;
						targetPosition = index;
					}
				} else {
					index = children.indexOf(this.node);
					targetPosition = index;
				}
				break;

			case 'bottom':
				targetParent = this.node;
				targetPosition = -1;
				break;

			case 'node':
				targetParent = this.node;
				targetPosition = -1;
				break;
		}

		// Check if resulting position is the same as current position
		// If so, don't allow.
		if (!children) children = targetParent ? this.service.treeData.getChildren(targetParent) : this.service.treeData.getChildren(null);
		if (targetPosition === -1) {
			if (children[children.length - 1] === this.service.treeDragNode) return;
		} else {
			if (children[targetPosition] === this.service.treeDragNode) return;
		}

		// Make sure target is not parented to dragged node
		const parents = [];
		let n = targetParent;
		while (n) {
			parents.push(n);
			n = this.service.treeData.getParent(n);
		}
		if (parents.indexOf(this.service.treeDragNode) !== -1) return;

		if (!this.service.treeData.canDrop(this.service.treeDragNode, targetParent, targetPosition)) return;

		this.service.treeDropNode = this.node;
		this.service.treeDropPos = pos;
		this.service.treeTargetParent = targetParent;
		this.service.treeTargetPosition = targetPosition;
	}

	dragLeave(e) {
		e.stopPropagation();
		if (!this.service.treeBlockLeave) {
			this.service.treeDropNode = null;
			this.service.treeDropPos = '';
			this.service.treeTargetParent = null;
			this.service.treeTargetPosition = -1;
		}
	}

	getActionTooltip(actionTooltip) {
		if (!actionTooltip) return null;
		if (typeof actionTooltip === 'function') return actionTooltip(this.node);
		return actionTooltip;
	}

}
