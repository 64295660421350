<ui-grid-container>
	<ui-grid-row weight-0>
		<ui-grid-col>
			<app-core-header></app-core-header>
		</ui-grid-col>
	</ui-grid-row>
	<ui-grid-row>
		<ui-grid-col weight-0>
			<app-core-navigation [org]="app.org"></app-core-navigation>
		</ui-grid-col>
		<ui-grid-col>
			<router-outlet *ngIf="!app.routeData?.id || (app.routeData?.id && app.introductionRouteId !== app.routeData.id && !app.blockQuotaLimitUsers)"></router-outlet>
			<app-core-block-quote-limit-users *ngIf="app.routeData?.id && app.blockQuotaLimitUsers"></app-core-block-quote-limit-users>
			<app-core-introduction *ngIf="app.routeData?.id && app.introductionRouteId === app.routeData.id && !app.blockQuotaLimitUsers" [routeId]="app.introductionRouteId"></app-core-introduction>
			<app-core-alert *ngIf="app.routeData?.id" [routeId]="app.routeData.id"></app-core-alert>
		</ui-grid-col>
	</ui-grid-row>
	<ui-grid-row *ngIf="['auth', 'signup', 'account', 'developer'].indexOf(app.routeData.module) !== -1" class="p-4 bg-dark text-silver no-print" weight-0>
		<div style="margin-left: 60px;">&copy; {{currentYear()}} WeQuote</div>
		<div class="flex-weight-1 text-right">
			<a class="text-silver ml-3" href="https://wequote.cloud/" target="_blank">About</a>
			<a class="text-silver ml-3" href="https://wequote.cloud/privacy-policy/" target="_blank">Privacy Policy</a>
			<a class="text-silver ml-3" [routerLink]="['/signup', 'terms-of-use']" target="_blank">Terms of Use</a>
		</div>
	</ui-grid-row>
</ui-grid-container>

<app-notifications></app-notifications>
<app-tutorial-widget [appPath]="app.path" [orgAlias]="app.org" [tutorialActive]="app.orgInfo?.tutorial_active && app.orgInfo?.setup_complete"></app-tutorial-widget>
<app-media-library-modal (close)="mediaLibraryModalClosed($event)" style="z-index: 10000;"></app-media-library-modal>
<app-core-dialog-modal></app-core-dialog-modal>
