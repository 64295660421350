
import { Component, ViewChild } from '@angular/core';
import { AppService } from 'app/app.service';
import { ModalBillingUpgradeAccountComponent } from 'app/modal/modal-billing-upgrade-account/modal-billing-upgrade-account.component';
import { UIGridBaseComponent } from 'app/ui/ui-grid-base.component';

@Component({
  selector: 'app-core-block-quote-limit-users',
  templateUrl: './core-block-quote-limit-users.component.html'
})
export class CoreBlockQuoteLimitUsersComponent extends UIGridBaseComponent {

  @ViewChild(ModalBillingUpgradeAccountComponent) upgradeAccountModal: ModalBillingUpgradeAccountComponent;

  constructor(
    public app: AppService
  ) {
    super();
  }

  upgradeAccount() {
    this.upgradeAccountModal.open(null);
  }

  upgradeAccountModalClosed(data) {
    if (data) window.location.reload();
  }
}
