import { Component, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { ModalAlertPopupComponent } from 'app/modal/modal-alert-popup/modal-alert-popup.component';
import { SimpleChanges } from '@angular/core';

@Component({
	selector: 'app-core-alert',
	templateUrl: './core-alert.component.html'
})
export class CoreAlertComponent implements OnDestroy, OnChanges {

	@Input() routeId;
	@ViewChild(ModalAlertPopupComponent) alertPopupModal: ModalAlertPopupComponent;

	popupTimer;

	constructor(
		public api: ApiService,
		public app: AppService
	) { }

	openAlertPopup() {
		this.popupTimer = setTimeout(() => {
			if (this.routeId)
				this.api.alert.listByLocation(this.routeId, data => {
					if (data.length > 0) this.alertPopupModal.open(data);
				});
		}, 2000);
	}

	ngOnDestroy() {
		clearTimeout(this.popupTimer);
	}

	ngOnChanges(changes: SimpleChanges) {
		if ('routeId' in changes) {
			clearTimeout(this.popupTimer);
			this.openAlertPopup();
		}
	}

}
