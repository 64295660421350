<ui-grid-col spaced class="auth-background">
	<ui-grid-header path="Quota Limit Exceeded" [back]="true" icon="wq wq-card">
	</ui-grid-header>
	<ui-card widget style="background: var(--secondary);">
		<div class="w-100 h-100 d-flex align-items-center justify-content-center">
			<div class="auth-foreground shadow p-5 text-center rounded">
				<h1 class="text-light text-center mb-4">Quota Limit Exceeded</h1>
				<p class="text-light text-large">You have exceeded the quota limit.</p>
				<p class="text-light text-large">This quote had been won outside of your quota limit.</p>
				<p class="text-primary text-large">Please upgrade your account.</p>
				<p class="text-light text-large">Alternatively wait until your quota limit resets to access this quote.</p>
				<div class="text-center">
					<button class="btn btn-primary btn-lg mt-2 w-75 text-large" (click)="upgradeAccount()">Upgrade Account</button>
				</div>
			</div>
		</div>
	</ui-card>
</ui-grid-col>
<app-modal-billing-upgrade-account (close)="upgradeAccountModalClosed($event)"></app-modal-billing-upgrade-account>