import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-quote',
	templateUrl: './developer-quote.component.html',
})
export class DeveloperQuoteComponent implements OnInit {

	constructor() { }

	ngOnInit(): void { }

}
