import { Component, Input, AfterViewInit, Output, EventEmitter, HostBinding, OnDestroy, ViewChild } from '@angular/core';

@Component({
	selector: 'ui-video',
	template:
		`<video #videoPlayer width="100%" height="auto" [controls]="showControls" autoplay (ended)="onVideoEnded()" style="border-radius: 0.25rem;">
			<source src="{{videoURL}}" type="video/mp4">
			Your browser does not support the video tag.
		</video>`
})
export class UIVideoComponent implements AfterViewInit, OnDestroy {

	@Input() videoURL = '';
	@Output() videoEnded = new EventEmitter<void>();
	@Input() showControls: boolean = true;
	@HostBinding('class') cls = 'd-flex align-items-center';
	@ViewChild('videoPlayer') videoPlayer;

	videoPlayCheckTimer: any;
	autoplayTimer: any;

	constructor() { }

	ngAfterViewInit() {
		this.autoplayTimer = setTimeout(() => {
			const videoElement: HTMLVideoElement = this.videoPlayer.nativeElement;

			if (videoElement) {
				videoElement.autoplay = true;
				videoElement.addEventListener('ended', () => this.onVideoEnded());
			}

		}, 2000);

		this.videoPlayCheckTimer = setTimeout(() => {
			const videoElement: HTMLVideoElement = this.videoPlayer.nativeElement;

			if (videoElement.paused) {
				this.onVideoNotPlaying();
			}
		}, 10000);
	}

	onVideoEnded = () => {
		this.showControls = true;
		this.videoEnded.emit();
	};

	onVideoNotPlaying() {
		this.videoEnded.emit();
	}

	ngOnDestroy() {
		const videoElement: HTMLVideoElement = this.videoPlayer.nativeElement;
		if (videoElement) videoElement.removeEventListener('ended', this.onVideoEnded);

		clearTimeout(this.autoplayTimer);
		clearTimeout(this.videoPlayCheckTimer);
	}

}
