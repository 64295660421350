import { Component, Input, OnChanges } from '@angular/core';

@Component({
	selector: 'ui-user-image',
	template: `<span [title]="title" class="ui-user-image" [style.width]="size" [style.height]="size" [style.backgroundImage]="backgroundImage" [ngClass]="{ 'border border-light': border, 'shadow-sm': shadow }"></span>`,
	styles: [
		`
			.ui-user-image {
				display: inline-block;
				border-radius: 50%;
				background-position: center center;
				background-size: cover;
				background-repeat: no-repeat;
				vertical-align: middle;
			}
		`
	]
})
export class UIUserImageComponent implements OnChanges {

	backgroundImage = '';

	@Input() email = '';
	@Input() hash = '';
	@Input() size = '2rem';
	@Input() border = true;
	@Input() shadow = true;
	@Input() color = '';
	@Input() title = '';

	ngOnChanges() {
		this.backgroundImage = 'url(\'/user-image?data=' + encodeURIComponent(this.email || '') + '&hash=' + encodeURIComponent(this.hash || '') + '&color=' + encodeURIComponent(this.color || '') + '\')';
	}

}
