import { Component } from '@angular/core';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

export interface ProductCloneFlags {
	accessories: boolean;
	bom: boolean;
	labour: boolean;
	subscription: boolean;
	warehouses: boolean;
	bundle: boolean;
	suppliers: boolean;
	documents: boolean;
	counters: boolean;
}

export interface ProductCloneOptions {
	id: string;
	sku: string;
	model: string;
	short_description: string;
	long_description: string;
	clone: ProductCloneFlags;
	allowed: ProductCloneFlags;
}

@Component({
	selector: 'modal-clone-product',
	templateUrl: './modal-clone-product.component.html'
})
export class ModalCloneProductComponent extends UIModalBaseComponent {

	options: ProductCloneOptions = null;

	open(data: any) {
		this.options = data;
		this.modal.open();
	}

	cloneButton() {
		this.modal.close(this.options);
	}

}
