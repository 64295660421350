import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-developer-getting-started',
	templateUrl: './developer-getting-started.component.html',
})
export class DeveloperGettingStartedComponent implements OnInit {

	constructor() { }

	ngOnInit(): void { }

}
