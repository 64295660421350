<form>
	<ui-modal [modalTitle]="id === 'new' ? 'Add Product Type' : 'Product Type Details'">
		<ng-container *ngIf="data?.details">
			<ui-form labelWidth="10rem">
				<ui-form-control label="Name">
					<input class="form-control" [(ngModel)]="data.details.name" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off" appAutoFocus>
				</ui-form-control>
			</ui-form>
		</ng-container>

		<button *ngIf="id !== 'new' && data && !data.product_count" type="button" class="btn btn-danger" (click)="deleteButton()" [disabled]="disabled" ui-modal-footer>Delete</button>
		<div class="flex-weight-1" ui-modal-footer></div>
		<button type="button" class="btn btn-secondary" (click)="modal.close()" [disabled]="disabled" ui-modal-footer>Cancel</button>
		<button type="submit" class="btn btn-primary" (click)="saveButton()" [disabled]="disabled" ui-modal-footer>Save</button>
	</ui-modal>
</form>
