<nav class="navbar navbar-dark bg-dark text-light px-4 no-print" [ngClass]="{ 'd-none': !app.routeData.module || app.routeData?.module === 'proposal' || app.routeData?.module === 'invoice' || app.routeData?.module === 'change-order' }">
	<ui-grid-row spaced class="m-0">
		<ui-grid-col weight-2>
			<ui-grid-row class="align-items-center">
				<ui-grid-col weight-0 [appTutorial]="1">
					<a *ngIf="!app.org" class="py-1" href="/">
						<img src="assets/img/logo/wequote{{app.activeTheme.assetSuffix}}.svg?rev=2" style="height: 2.875rem;">
					</a>
					<a *ngIf="app.org" class="py-1" [routerLink]="['/', app.org]">
						<img src="assets/img/logo/wequote-no-text{{app.activeTheme.assetSuffix}}.svg?rev=2" style="height: 2.875rem;">
					</a>
				</ui-grid-col>
				<ui-grid-col weight-0 *ngIf="app.orgInfo">
					<button *ngIf="!app.orgInfo?.is_sandbox" class="btn btn-sm btn-outline-silver" [routerLink]="['/' + app.org, 'settings', app.orgInfo?.role.manage_organisation ? 'company' : 'profile']">{{app.orgInfo.company_name}}</button>
					<ng-container *ngIf="app.orgInfo?.is_sandbox">
						<div class="d-flex mb-1">
							<button class="btn btn-sm btn-outline-silver px-3" style="flex-grow: 0;" [routerLink]="['/' + app.org, 'settings', app.orgInfo?.role.manage_organisation ? 'company' : 'profile']">Free Trial</button>
							<button class="btn btn-sm btn-primary px-3 ml-2" style="flex-grow: 0;" [routerLink]="['/' + app.org, 'account', 'activate']">Go Live<i class="wq wq-arrow-right ml-2" style="font-size: 80%;"></i></button>
						</div>
						<div *ngIf="app.orgInfo.time_left" class="text-small text-silver">You have <b>{{app.orgInfo.time_left}}</b> remaining.</div>
						<div *ngIf="!app.orgInfo.time_left" class="text-small text-silver">Your free trial has ended.</div>
					</ng-container>
				</ui-grid-col>
			</ui-grid-row>
		</ui-grid-col>

		<ui-grid-col weight-3>
			<app-core-project-header *ngIf="app.routeParams.project" [projectId]="app.routeParams.project" class="d-flex flex-row align-items-center flex-weight-1"></app-core-project-header>
			<app-core-quote-header *ngIf="app.routeParams.quote" [quoteId]="app.routeParams.quote" class="d-flex flex-row align-items-center flex-weight-1"></app-core-quote-header>
			<ui-grid-row *ngIf="!app.routeParams.quote && app.orgInfo?.is_sandbox && app.orgInfo?.has_sample_data" class="align-items-center">
				<button class="btn btn-warning btn-sm m-auto" (click)="deleteSampleData()">Delete Sample Data</button>
			</ui-grid-row>
		</ui-grid-col>

		<ui-grid-col weight-2>
			<ui-grid-row class="align-items-center">
				<ng-container *ngIf="app.userEmail">
					<ui-grid-col></ui-grid-col>
					<ui-grid-col weight-0>
						<div>
							<a *ngIf="app.orgInfo?.account_type_id === 'pro' && (app.orgInfo?.role.is_elevated || app.userEmail === 'phil@oneproduct.co.uk' || app.userEmail === 'leon@oneproduct.co.uk' || app.userEmail === 'leon@kordz.co.uk' || app.org === 'windsor-displays-ltd')" class="text-silver h2 mr-3 selectable" (click)="showGlobalSearchModal($event)" style="text-decoration: none;" ngbTooltip="Global Search"><i class="wq wq-search"></i></a>
							<a *ngIf="app.org" class="text-silver h2 mr-3 selectable" (click)="showFeedbackModal()" style="text-decoration: none;" ngbTooltip="Report a Bug"><i class="wq wq-bug"></i></a>
							<a class="text-silver h2 mr-3 selectable" style="text-decoration: none;" ngbTooltip="WeQuote Knowledgebase" (click)="showKnowledgebaseModal()"><i class="wq wq-help"></i></a>

							<div class="dropdown d-inline-block">
								<a *ngIf="app.org" class="text-silver h2 mr-3 selectable dropdown-toggle" data-toggle="dropdown" style="text-decoration: none; position: relative;" ngbTooltip="Alerts" (click)="openAlerts()">
									<i class="wq wq-alert"></i>
									<span *ngIf="app.alertCount" class="badge badge-primary p-1 rounded-circle" style="position: absolute; top: -0.125rem; right: -0.125rem; font-size: 0.6rem;">{{app.alertCount}}</span>
								</a>
								<div class="dropdown-menu dropdown-menu-right rounded bg-white shadow-sm p-2" style="border: none;">
									<app-alert-embed-list [popupAlertModal]="alertPopupModal"></app-alert-embed-list>
								</div>
							</div>

							<a *ngIf="app.org" class="text-silver h2 mr-3" [routerLink]="['/' + app.org, 'settings']" style="text-decoration: none;" ngbTooltip="Settings"><i class="wq wq-cog"></i></a>
						</div>
					</ui-grid-col>
					<ui-grid-col weight-0 class="ml-0">
						<div class="dropdown">
							<div class="dropdown-toggle selectable" data-toggle="dropdown">
								<b class="hide-mobile">
									<span class="text-silver">Hi,</span>
									{{app.userName}}
								</b>
								<ui-user-image class="ml-2" size="2.5rem" [email]="app.userEmail"></ui-user-image>
							</div>
							<div class="dropdown-menu dropdown-menu-right shadow-sm">
								<button *ngIf="app.org" class="dropdown-item" [routerLink]="['/' + app.org, 'settings', 'profile']">Profile</button>
								<button class="dropdown-item" [routerLink]="['/auth', 'logout']">Sign Out</button>
							</div>
						</div>
					</ui-grid-col>
				</ng-container>
			</ui-grid-row>
		</ui-grid-col>

	</ui-grid-row>
</nav>
<div *ngIf="showPaymentWarning && app.orgInfo?.payment_outstanding && app.orgInfo?.role.manage_billing" class="bg-danger text-light p-2 pl-3">
	<div class="d-flex align-items-center">
		<div class="flex-weight-1 text-bold">
			<i class="wq wq-warning mr-2"></i>You have an outstanding balance on your account. Please make a payment.
		</div>
		<a class="btn btn-outline-light btn-sm mr-2" [routerLink]="['/', app.org, 'settings', 'billing']"><i class="wq wq-arrow-right mr-2"></i>Go to Billing</a>
		<a class="btn btn-outline-light btn-sm" (click)="showPaymentWarning = false"><i class="wq wq-close mr-2"></i>Dismiss</a>
	</div>
</div>

<app-core-feedback-modal></app-core-feedback-modal>
<app-core-knowledgebase-modal></app-core-knowledgebase-modal>
<app-modal-global-search></app-modal-global-search>
<modal-alert-popup></modal-alert-popup>
