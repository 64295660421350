<ui-grid-row weight-0 class="position-relative" style="max-height: 1090px;">
	<ui-grid-col spaced weight-0>
		<ui-card widget style="width: 13.75rem;">
			<div class="m-nc navigation mb-5">
				<table class="table table-hover no-border">
					<tbody>
						<ng-container *ngFor="let item of navigation">
							<tr *ngIf="item.type === 'header'">
								<td class="navigation-header">
									{{item.title}}
								</td>
							</tr>
							<tr *ngIf="item.type === 'link'">
								<td class="navigation-link" [ngClass]="{ 'active': selected === item.link }">
									<a [routerLink]="['/developer/' + item.link]" (click)="selected = item.link">
										<span class="ml-2"><b>{{item.title}}</b></span>
									</a>
								</td>
							</tr>
						</ng-container>
					</tbody>
				</table>
			</div>
		</ui-card>
	</ui-grid-col>
	<ui-grid-col class="scrollable mb-4 mt-3">
		<router-outlet></router-outlet>
	</ui-grid-col>
</ui-grid-row>
