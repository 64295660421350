import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeveloperQuoteComponent } from './developer-quote/developer-quote.component';
import { DeveloperGettingStartedComponent } from './developer-getting-started/developer-getting-started.component';
import { DeveloperRoutingModule } from './developer-routing.module';
import { DeveloperComponent } from './developer/developer.component';
import { UIModule } from 'app/ui/ui.module';
import { DeveloperWebhookComponent } from './developer-webhook/developer-webhook.component';
import { DeveloperCustomerComponent } from './developer-customer/developer-customer.component';

@NgModule({
	declarations: [
		DeveloperQuoteComponent,
		DeveloperGettingStartedComponent,
		DeveloperComponent,
		DeveloperWebhookComponent,
		DeveloperCustomerComponent
	],
	imports: [
		CommonModule,
		DeveloperRoutingModule,
		UIModule
	]
})
export class DeveloperModule { }
