<form>
	<ui-modal [modalTitle]="title" size="lg">

		<ng-container *ngIf="bundle && modal.isOpen">

			<h2>Details</h2>
			<ui-form labelWidth="10rem">
				<ui-form-control label="Description">
					<input class="form-control" [(ngModel)]="counter.description" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off" appDesktopFocus>
				</ui-form-control>
				<ui-form-control label="Start Value">
					<input class="form-control w-30" [(ngModel)]="counter.start_value" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Minimum Value">
					<input class="form-control w-30" [(ngModel)]="counter.min_value" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Maximum Value">
					<input class="form-control w-30" [(ngModel)]="counter.max_value" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
					<div class="text-info">A maximum value of 0 means no limit.</div>
				</ui-form-control>
			</ui-form>

			<hr>

			<h2>Products</h2>
			<p class="alert alert-info"><i class="wq wq-info"></i> Products will only be added to the bundle if the accumulator is within the <b>range</b> (inclusive). If <b>multiply</b> is checked, the quantity will be multiplied by the accumulator.</p>

			<table *ngIf="counter.products.length" class="table table-hover vam">
				<thead>
					<tr>
						<th colspan="2">Product</th>
						<th class="shrink text-right nowrap">Quantity</th>
						<th class="shrink">Multiply?</th>
						<th class="shrink text-center">Range</th>
						<th class="shrink"></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of counter.products" [ngClass]="{ 'table-danger': item.quantity === 0 }">
						<td class="shrink pr-0">
							<div *ngIf="item.image_url" class="product-image mr-3" [style.backgroundImage]="'url(\'' + item.image_url + '\')' | safeStyle"></div>
						</td>
						<td class="pl-0">
							<div *ngIf="item.short_description" class="text-bold">{{item.short_description}}</div>
							<div *ngIf="item.manufacturer_name || item.model" class="text-secondary">{{item.manufacturer_name}} {{item.manufacturer_name && item.model ? '/' : ''}} {{item.model}}</div>
							<div *ngIf="item.sku || item.manufacturer_sku" class="text-secondary text-tiny">{{item.sku || item.manufacturer_sku}}</div>
						</td>
						<td class="shrink"><input class="form-control text-right" style="width: 5rem;" [(ngModel)]="item.quantity" [ngModelOptions]="{ standalone: true }" (change)="formatProductNumbers(item)" autocomplete="chrome-off"></td>
						<td class="shrink text-center">
							<label class="custom-control custom-checkbox mt-2">
								<input type="checkbox" class="custom-control-input" [ngModel]="!!item.multiply_by_counter" (ngModelChange)="item.multiply_by_counter = $event ? 1 : 0" [ngModelOptions]="{ standalone: true }">
								<span class="custom-control-label"></span>
							</label>
						</td>
						<td class="shrink nowrap">
							<input class="form-control text-right" style="width: 3.375rem; display: inline-block;" [(ngModel)]="item.range_start" [ngModelOptions]="{ standalone: true }" (change)="formatProductNumbers(item)" autocomplete="chrome-off">
							&mdash;
							<input class="form-control text-right" style="width: 3.375rem; display: inline-block;" [(ngModel)]="item.range_end" [ngModelOptions]="{ standalone: true }" (change)="formatProductNumbers(item)" autocomplete="chrome-off">
						</td>
						<td class="shrink nowrap"><button type="button" class="btn btn-outline-danger btn-sm border-0 px-1" (click)="removeProduct(item)"><i class="wq wq-close"></i></button></td>
					</tr>
				</tbody>
			</table>
			<p *ngIf="!counter.products.length" class="text-secondary">No products.</p>
			<button type="button" class="btn btn-outline-success btn-sm" (click)="addProducts()"><i class="wq wq-add mr-2"></i>Add products</button>

		</ng-container>

		<button type="button" class="btn btn-secondary" (click)="modal.close()" ui-modal-footer>Cancel</button>
		<button type="submit" class="btn btn-primary" (click)="saveButton()" ui-modal-footer>Save</button>

	</ui-modal>
</form>

<modal-select-product (close)="productSelectModalClosed($event)"></modal-select-product>
