import { Injectable } from '@angular/core';

declare const Mangler: any;

@Injectable({
	providedIn: 'any'
})
export class SystemService {

	expandedSystems = [];

	constructor() { }

	setSystemExpanded(id, state) {
		if (state) {
			if (!Mangler.first(this.expandedSystems, id)) this.expandedSystems.push(id);
		} else {
			Mangler.filter(this.expandedSystems, { $ne: id });
		}
		this.expandedSystems = this.expandedSystems.slice();
	}

	isSystemExpanded(id) {
		return !!Mangler.first(this.expandedSystems, id);
	}

	toggleSystemExpanded(id) {
		this.setSystemExpanded(id, !this.isSystemExpanded(id));
	}

}
