<div class="mr-3">
	<ui-grid-row>
		<h1 class="pb-1">Getting Started</h1>
	</ui-grid-row>
	<br>
	<ui-grid-row>
		<ui-grid-col>
			<ui-card widget header="How to use This documentation">
				<div>
					WeQuote's External API allows you to access some aspects of the platform from 3rd party services.
					<br>
					You can access all of the endpoints detailed in this documentation by using the base URL: <code class="p-1 text-primary">https://app.wetech.cloud/external/</code>
					<br>
					Note: <i>All our endpoints require a valid API key as detailed in Authentication below.</i>
				</div>
			</ui-card>
		</ui-grid-col>
	</ui-grid-row>
	<ui-grid-row id="create" class="pt-3">
		<ui-grid-col>
			<ui-card widget header="Authentication">
				<div>
					<i>WeQuote's external API uses an API key which can be located in your organisation's Integration settings</i>
					<br>
					You can test your API key by using the following URL: <code class="p-1 text-primary">https://app.wetech.cloud/external/auth/me</code>
				</div>
				<div class="pt-2">
					<hr>
					<table>
						<tr>
							<td class="p-3 border-right">
								Headers
							</td>
							<td class="p-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2 pr-2 border-right">Required</th>
										<th class="pl-2, pr-2 text-center">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">Api-Key</td>
										<td class="pl-2 pr-2 border-right">String</td>
										<td class="pl-2 pr-2 border-right">true</td>
										<td class="pl-3 pr-2 text-left">
											A 30-character string that is used to authenticate the request. This must be sent with any request and is used to authenticate the request and identify your organisation.
											<br>
											<b>To propect your account security you should always keep this private</b>
										</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td class="p-3 border-right">
								Responses
							</td>
							<td class="p-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2, pr-2 text-center">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">user</td>
										<td class="pl-2 pr-2 border-right">String</td>
										<td class="pl-3 pr-2 text-left">
											The full name of the user who created the key used in the request
										</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">organisation</td>
										<td class="pl-2 pr-2 border-right">String</td>
										<td class="pl-3 pr-2 text-left">
											The name of the organisation that the key belongs to
										</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">key</td>
										<td class="pl-2 pr-2 border-right">String</td>
										<td class="pl-3 pr-2 text-left">
											The name of the API key used in the request
										</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>
				</div>
			</ui-card>
		</ui-grid-col>
	</ui-grid-row>

	<ui-grid-row id="create" class="pt-3">
		<ui-grid-col>
			<ui-card widget header="Ping">
				<div>
					<i>every module in the API includes a <code>ping</code> function. You can use this function to test that you are authorised to access the module, or to verify that WeQuote is online and accepting requests</i>
					<br>
					You can ping any module by using this URL: <code class="p-1 text-primary">https://app.wetech.cloud/external/&LeftAngleBracket;module&RightAngleBracket;/ping</code>
				</div>
				<div class="pt-2">
					<hr>
					<table>
						<tr>
							<td class="p-3 border-right">
								Headers
							</td>
							<td class="p-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2 pr-2 border-right">Required</th>
										<th class="pl-2, pr-2 text-center">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">Api-Key</td>
										<td class="pl-2 pr-2 border-right">String</td>
										<td class="pl-2 pr-2 border-right">true</td>
										<td class="pl-3 pr-2 text-left">
											A 30-character string that is used to authenticate the request. This must be sent with any request and is used to authenticate the request and identify your organisation.
											<br>
											<b>To propect your account security you should always keep this private</b>
										</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td class="p-3 border-right">
								Responses
							</td>
							<td class="p-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2, pr-2 text-center">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">pong</td>
										<td class="pl-2 pr-2 border-right">String</td>
										<td class="pl-3 pr-2 text-left">
											The word "pong" is returned if the request is successful and the API Key is valid
										</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>
				</div>
			</ui-card>
		</ui-grid-col>
	</ui-grid-row>
	<ui-grid-row class="pt-3" id="errors">
		<ui-grid-col>
			<ui-card widget header="Errors">
				<div>
					<i>When making a request to the API, there are a few errors that could crop up in any module. Those are listed here.</i>
				</div>
				<div class="pt-2">
					<hr>
					<table>
						<tr>
							<td class="p-3 border-right">
								Errors
							</td>
							<td class="pl-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Code</th>
										<th class="pl-2 pr-2 border-right">Text</th>
										<th class="pl-2, pr-2 text-center">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">400</td>
										<td class="pl-2 pr-2 border-right">Invalid request.</td>
										<td class="pl-3 pr-2 text-left">The module or function you are trying to reach doesn't exist</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">401</td>
										<td class="pl-2 pr-2 border-right">No valid API key.</td>
										<td class="pl-3 pr-2 text-left">Your request did not include an API key in the headers, or your API Key is wrong or has been deleted. Please make sure you include <code>Api-Key</code> in the request headers</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">403</td>
										<td class="pl-2 pr-2 border-right">Access Denied</td>
										<td class="pl-3 pr-2 text-left">Your Api Key does not have permission to perform this action</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">500</td>
										<td class="pl-2 pr-2 border-right">An exception has occurred.</td>
										<td class="pl-3 pr-2 text-left">An unexpected error has occured. This is an internal server error. Please submit a bug report.</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>
				</div>
			</ui-card>
		</ui-grid-col>
	</ui-grid-row>
</div>
