import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeveloperQuoteComponent } from './developer-quote/developer-quote.component';
import { DeveloperComponent } from './developer/developer.component';
import { DeveloperGettingStartedComponent } from './developer-getting-started/developer-getting-started.component';
import { DeveloperWebhookComponent } from './developer-webhook/developer-webhook.component';
import { DeveloperCustomerComponent } from './developer-customer/developer-customer.component';

const routes: Routes = [
	{
		path: '', component: DeveloperComponent, children: [
			{ path: '', pathMatch: 'full', redirectTo: 'getting-started' },
			{ path: 'getting-started', component: DeveloperGettingStartedComponent },
			{ path: 'quote', component: DeveloperQuoteComponent },
			{ path: 'webhook', component: DeveloperWebhookComponent },
			{ path: 'customer', component: DeveloperCustomerComponent }
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class DeveloperRoutingModule { }
