<form>
	<ui-modal [modalTitle]="title" size="lg">

		<ng-container *ngIf="bundle && modal.isOpen">

			<h2>Details</h2>
			<ui-form labelWidth="11rem">
				<ui-form-control label="Question">
					<div class="input-group">
						<input class="form-control" [(ngModel)]="question.question" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off" appDesktopFocus>
						<span *ngIf="!question.question" class="input-group-append">
							<button type="button" class="btn btn-outline-secondary px-2" (click)="useProduct()"><i class="wq wq-search mr-2"></i>Use Product</button>
						</span>
					</div>
				</ui-form-control>
				<ui-form-control label="Type">
					<select class="custom-select w-auto" (ngModelChange)="questionTypeChanged($event)" [(ngModel)]="question.type" [ngModelOptions]="{ standalone: true }">
						<option value="numeric">Numeric</option>
						<option value="checkbox">Checkbox</option>
						<option value="select">Select</option>
						<option value="multi-select">Multi-select</option>
						<option value="product-select">Product select</option>
					</select>
				</ui-form-control>
				<ui-form-control label="Default Value">
					<ng-container *ngIf="question.type === 'numeric'">
						<input class="form-control text-right w-20" [(ngModel)]="question.default_value" (ngModelChange)="formatQuestionNumbers(question)" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
					</ng-container>
					<ng-container *ngIf="question.type === 'checkbox'">
						<label class="custom-control custom-checkbox mt-2 w-10">
							<input type="checkbox" class="custom-control-input" [ngModel]="!!question.default_value" (ngModelChange)="question.default_value = $event ? 1 : 0" [ngModelOptions]="{ standalone: true }">
							<span class="custom-control-label"></span>
						</label>
					</ng-container>
					<ng-container *ngIf="question.type === 'select'">
						<select class="custom-select w-auto" [(ngModel)]="question.default_value" [ngModelOptions]="{ standalone: true }">
							<option [ngValue]="null"></option>
							<option *ngFor="let o of question.select_options" [ngValue]="o.value">{{o.description}}</option>
						</select>
					</ng-container>
					<ng-container *ngIf="question.type === 'multi-select'">
						<button *ngFor="let o of question.select_options" type="button" class="btn btn-sm mr-1 mt-1" [ngClass]="{ 'btn-primary': getFlag(question.default_value, o.value), 'btn-secondary': !getFlag(question.default_value, o.value) }" (click)="question.default_value = toggleFlag(question.default_value, o.value)">{{o.description}}</button>
					</ng-container>
					<ng-container *ngIf="question.type === 'product-select'">
						<select class="custom-select w-auto" [(ngModel)]="question.default_value" [ngModelOptions]="{ standalone: true }">
							<option [ngValue]="null"></option>
							<option *ngFor="let o of question.products" [ngValue]="o.product_id">{{o.short_description}}</option>
						</select>
					</ng-container>
				</ui-form-control>

				<ng-container *ngIf="question.type === 'numeric'">
					<ui-form-control label="Minimum value">
						<input class="form-control text-right w-20" [(ngModel)]="question.min_value" [ngModelOptions]="{ standalone: true }" (change)="formatQuestionNumbers(question)" autocomplete="chrome-off">
					</ui-form-control>
					<ui-form-control label="Maximum value">
						<input class="form-control text-right w-20" [(ngModel)]="question.max_value" [ngModelOptions]="{ standalone: true }" (change)="formatQuestionNumbers(question)" autocomplete="chrome-off">
					</ui-form-control>
				</ng-container>

				<ui-form-control label="Required?">
					<label class="custom-control custom-checkbox mt-2 w-10">
						<input type="checkbox" class="custom-control-input" [ngModel]="!!question.is_required" (ngModelChange)="question.is_required = $event ? 1 : 0" [ngModelOptions]="{ standalone: true }">
						<span class="custom-control-label"></span>
					</label>
				</ui-form-control>

				<ng-container *ngIf="question.type === 'select' || question.type === 'multi-select' || question.type === 'product-select'">
					<ui-form-control label="Show As Swatches?">
						<label class="custom-control custom-checkbox mt-2 w-10">
							<input type="checkbox" class="custom-control-input" [ngModel]="!!question.is_swatch" (ngModelChange)="question.is_swatch = $event ? 1 : 0" [ngModelOptions]="{ standalone: true }">
							<span class="custom-control-label"></span>
						</label>
					</ui-form-control>
					<ui-form-control label="Product image layer">
						<button type="button" class="btn btn-sm mr-1" [ngClass]="{ 'btn-primary': question.image_layer === null, 'btn-secondary': question.image_layer !== null }" (click)="question.image_layer = null">No overlay</button><button *ngFor="let i of layerIterator" type="button" class="btn btn-sm mr-1" [ngClass]="{ 'btn-primary': question.image_layer === i, 'btn-secondary': question.image_layer !== i }" (click)="question.image_layer = i">{{i}}</button>
					</ui-form-control>
				</ng-container>

				<ui-form-control label="Visibility">
					<select class="custom-select w-auto" [(ngModel)]="question.is_hidden" [ngModelOptions]="{ standalone: true }">
						<option [ngValue]="0">Visible</option>
						<option [ngValue]="2">Hidden on web quote</option>
						<option [ngValue]="1">Hidden everywhere</option>
					</select>
					<i class="wq wq-info text-info ml-2" ngbTooltip="Hidden questions are not shown, but will be evaluated and they always take their default value."></i>
				</ui-form-control>

				<ng-container *ngIf="question.parent">
					<hr>
					<ui-form-control label="Parent">
						<input class="form-control" [(ngModel)]="question.parent.question" [ngModelOptions]="{ standalone: true }" readonly>
					</ui-form-control>
					<ui-form-control label="Show question if parent">
						<div class="row">
							<div class="col-sm-3">
								<select class="custom-select" [(ngModel)]="question.parent_mode" [ngModelOptions]="{ standalone: true }">
									<ng-container [ngSwitch]="question.parent.type">
										<ng-container *ngSwitchCase="'numeric'">
											<option value="set">is not 0</option>
											<option value="value">is equal to</option>
											<option value="range">is between</option>
											<option value="lt">is less than</option>
											<option value="gt">is greater than</option>
										</ng-container>
										<ng-container *ngSwitchCase="'checkbox'">
											<option value="set">is checked</option>
											<option value="value">has value of</option>
										</ng-container>
										<ng-container *ngSwitchCase="'select'">
											<option value="set">is set</option>
											<option value="value">is equal to</option>
											<option value="any">is one of</option>
										</ng-container>
										<ng-container *ngSwitchCase="'multi-select'">
											<option value="set">is set</option>
											<option value="value">is exactly</option>
											<option value="any">has any of</option>
											<option value="all">has all of</option>
										</ng-container>
										<ng-container *ngSwitchCase="'product-select'">
											<option value="set">is set</option>
											<option value="value">is equal to</option>
										</ng-container>
									</ng-container>
								</select>
							</div>

							<ng-container *ngIf="question.parent.type === 'numeric' && question.parent_mode !== 'set'">
								<div class="col-sm-2">
									<input class="form-control text-right" [(ngModel)]="question.parent_value" [ngModelOptions]="{ standalone: true }" (change)="formatQuestionNumbers(question)" autocomplete="chrome-off">
								</div>
								<ng-container *ngIf="question.parent_mode === 'range'">
									<label class="col-sm-1 mt-2 mb-0" style="text-align: center;">and</label>
									<div class="col-sm-2">
										<input class="form-control text-right" [(ngModel)]="question.parent_max_value" [ngModelOptions]="{ standalone: true }" (change)="formatQuestionNumbers(question)" autocomplete="chrome-off">
									</div>
								</ng-container>
							</ng-container>

							<div *ngIf="question.parent.type === 'checkbox' && question.parent_mode !== 'set'" class="col-sm-2">
								<label class="custom-control custom-checkbox mt-2">
									<input type="checkbox" class="custom-control-input" [ngModel]="!!question.parent_value" (ngModelChange)="question.parent_value = $event ? 1 : 0" [ngModelOptions]="{ standalone: true }">
									<span class="custom-control-label">{{!!question.parent_value ? 'checked' : 'unchecked'}}</span>
								</label>
							</div>

							<div *ngIf="question.parent.type === 'select' && question.parent_mode === 'value'" class="col-sm-5">
								<select class="custom-select w-auto" [(ngModel)]="question.parent_value" [ngModelOptions]="{ standalone: true }">
									<option [ngValue]="null"></option>
									<option *ngFor="let o of question.parent.select_options" [ngValue]="o.value">{{o.description}}</option>
								</select>
							</div>

							<div *ngIf="(question.parent.type === 'multi-select' && question.parent_mode !== 'set') || (question.parent.type === 'select' && question.parent_mode === 'any')" class="col-sm-5">
								<button *ngFor="let o of question.parent.select_options" type="button" class="btn btn-sm mr-1 mt-1" [ngClass]="{ 'btn-primary': getFlag(question.parent_value, o.value), 'btn-secondary': !getFlag(question.parent_value, o.value) }" (click)="question.parent_value = toggleFlag(question.parent_value, o.value)">{{o.description}}</button>
							</div>

							<div *ngIf="question.parent.type === 'product-select' && question.parent_mode === 'value'" class="col-sm-5">
								<select class="custom-select w-auto" [(ngModel)]="question.parent_value" [ngModelOptions]="{ standalone: true }">
									<option [ngValue]="null"></option>
									<option *ngFor="let o of question.parent.products" [ngValue]="o.product_id">{{o.short_description}}</option>
								</select>
							</div>
						</div>
					</ui-form-control>
				</ng-container>

				<hr>

				<ui-form-control label="Image">
					<div class="row">
						<div class="col-sm-3">
							<div class="img-container">
								<img *ngIf="!question.image_id" src="assets/img/image-placeholder.png" style="width: 100%;">
								<img *ngIf="question.image_id" [src]="question.image_url" style="width: 100%;">
							</div>
						</div>
						<div class="col-sm-4 text-center">
							<ng-container *ngIf="question.image_id">
								<button type="button" class="btn btn-danger mt-2 mb-2" (click)="removeImage()"><i class="wq wq-delete mr-2"></i>Remove image</button>
							</ng-container>
							<button type="button" class="btn btn-medium mt-2 mb-2" (click)="openMediaLibrary()"><i class="wq wq-edit mr-2"></i>Select image</button><br>
							<ng-container *ngIf="!question.image_id">
								<button type="button" class="btn btn-medium mb-2" (click)="getImageFromProduct()"><i class="wq wq-search mr-2"></i>Image from product</button>
							</ng-container>
						</div>
					</div>
				</ui-form-control>

				<hr>
			</ui-form>

			<ng-container *ngIf="question.type === 'select' || question.type === 'multi-select'">
				<h2>Select options</h2>
				<table *ngIf="question.select_options.length" class="table table-hover table-sm vam">
					<thead>
						<tr>
							<th class="shrink"></th>
							<th>Description</th>
							<th class="shrink"></th>
						</tr>
					</thead>
					<tbody class="cdk-drop-list" (cdkDropListDropped)="optionsDrop($event)" cdkDropList>
						<tr *ngFor="let o of question.select_options" class="cdk-drag" cdkDrag>
							<td class="shrink draggable" cdkDragHandle><i class="wq wq-drag-handle text-silver"></i></td>
							<td><input class="form-control" [(ngModel)]="o.description" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off"></td>
							<td class="shrink nowrap"><button type="button" class="btn btn-outline-danger btn-sm border-0 px-1" (click)="deleteSelectOption(o)"><i class="wq wq-close"></i></button></td>
						</tr>
					</tbody>
				</table>
				<p *ngIf="!question.select_options.length" class="text-secondary">No select options.</p>
				<button type="button" class="btn btn-outline-success btn-sm" (click)="addSelectOption()"><i class="wq wq-add mr-2"></i>Add select option</button>
				<hr>
			</ng-container>

			<h2>Products</h2>
			<p class="alert alert-info"><i class="wq wq-info"></i> Products will only be added to the bundle if the answer satisfies the condition. If a question is selected at the <b>multiply by answer</b> field, the quantity added will be multiplied by the answer for the selected question.</p>
			<table *ngIf="question.products.length" class="table table-hover vam">
				<thead>
					<tr>
						<th colspan="2">Product</th>
						<th class="shrink text-right nowrap">Quantity</th>
						<th class="shrink nowrap">Multiply by answer</th>
						<th>Condition</th>
						<th class="shrink"></th>
					</tr>
				</thead>
				<tbody>
					<ng-container *ngFor="let item of question.products">
						<tr [ngClass]="{ 'table-danger': item.quantity === 0, 'table-info': selectedProduct === item }">
							<td class="shrink nowrap pr-2">
								<div *ngIf="item.image_url" class="product-image d-inline-block mr-1" [style.backgroundImage]="'url(\'' + item.image_url + '\')' | safeStyle"></div>
								<a *ngIf="app.orgInfo?.role.is_elevated && item.image_url" href="{{item.image_url}}" target="_blank" [download]="filenameFromUrl(item.image_url)" class="btn btn-outline-purple btn-sm px-1"><i class="wq wq-download"></i></a>
							</td>
							<td class="pl-0">
								<div *ngIf="item.short_description" class="text-bold">{{item.short_description}}</div>
								<div *ngIf="item.manufacturer_name || item.model" class="text-secondary">{{item.manufacturer_name}} {{item.manufacturer_name && item.model ? '/' : ''}} {{item.model}}</div>
								<div *ngIf="item.sku || item.manufacturer_sku" class="text-secondary text-tiny">{{item.sku || item.manufacturer_sku}}</div>
							</td>
							<td class="shrink"><input class="form-control text-right" style="width: 5rem;" [(ngModel)]="item.quantity" [ngModelOptions]="{ standalone: true }" (change)="formatProductNumbers(item)" autocomplete="chrome-off"></td>
							<td>{{getQuestionMultiplyDescription(item.multiply_by_question_id)}}</td>
							<td>{{getCondition(item)}}</td>
							<td class="shrink nowrap">
								<button type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="selectedProduct = item"><i class="wq wq-edit"></i></button>
								<button type="button" class="btn btn-outline-danger btn-sm border-0 px-1" (click)="removeProduct(item)"><i class="wq wq-close"></i></button>
							</td>
						</tr>
						<tr *ngIf="selectedProduct === item" class="table-info">
							<td class="shrink"></td>
							<td colspan="4">
								<ui-form labelWidth="9rem">
									<ui-form-control label="Multiply by answer:">
										<select class="custom-select w-auto" [(ngModel)]="item.multiply_by_question_id" [ngModelOptions]="{ standalone: true }">
											<optgroup label="Shortcuts">
												<option [ngValue]="null">Don't multiply</option>
												<option [ngValue]="question.question_id">This answer</option>
											</optgroup>
											<optgroup label="All questions">
												<option *ngFor="let q of bundle.questions" [ngValue]="q.question_id === question.question_id ? -1 : q.question_id">{{'&nbsp;'.repeat(q.depth)}}{{q.question}}</option>
											</optgroup>
										</select>
									</ui-form-control>
									<ui-form-control *ngIf="question.type !== 'product-select'" label="Condition: Answer">
										<select class="custom-select d-inline-block mr-2 w-auto" [(ngModel)]="item.question_mode" [ngModelOptions]="{ standalone: true }">
											<ng-container [ngSwitch]="question.type">
												<ng-container *ngSwitchCase="'numeric'">
													<option value="set">is not 0</option>
													<option value="value">is equal to</option>
													<option value="range">is between</option>
													<option value="lt">is less than</option>
													<option value="gt">is greater than</option>
												</ng-container>
												<ng-container *ngSwitchCase="'checkbox'">
													<option value="set">is checked</option>
													<option value="value">has value of</option>
												</ng-container>
												<ng-container *ngSwitchCase="'select'">
													<option value="set">is set</option>
													<option value="value">is equal to</option>
													<option value="any">is one of</option>
												</ng-container>
												<ng-container *ngSwitchCase="'multi-select'">
													<option value="set">is set</option>
													<option value="value">is exactly</option>
													<option value="any">has any of</option>
													<option value="all">has all of</option>
												</ng-container>
											</ng-container>
										</select>

										<ng-container *ngIf="question.type === 'numeric' && item.question_mode !== 'set'">
											<input class="form-control d-inline-block text-right mr-2" style="width: 4.375rem;" [(ngModel)]="item.question_value" [ngModelOptions]="{ standalone: true }" (change)="formatProductNumbers(item)" autocomplete="chrome-off">
											<ng-container *ngIf="item.question_mode === 'range'">
												<label class="mt-2 mb-0 mr-2" style="text-align: center;">and</label>
												<input class="form-control d-inline-block text-right" style="width: 4.375rem;" [(ngModel)]="item.question_max_value" [ngModelOptions]="{ standalone: true }" (change)="formatProductNumbers(item)" autocomplete="chrome-off">
											</ng-container>
										</ng-container>

										<label *ngIf="question.type === 'checkbox' && item.question_mode !== 'set'" class="custom-control custom-checkbox mt-2 d-inline-block">
											<input type="checkbox" class="custom-control-input" [ngModel]="!!item.question_value" (ngModelChange)="item.question_value = $event ? 1 : 0" [ngModelOptions]="{ standalone: true }">
											<span class="custom-control-label">{{!!item.question_value ? 'checked' : 'unchecked'}}</span>
										</label>

										<ng-container *ngIf="question.type === 'select' && item.question_mode === 'value'">
											<select class="custom-select w-auto" [(ngModel)]="item.question_value" [ngModelOptions]="{ standalone: true }">
												<option [ngValue]="null"></option>
												<option *ngFor="let o of question.select_options" [ngValue]="o.value">{{o.description}}</option>
											</select>
										</ng-container>

										<ng-container *ngIf="(question.type === 'multi-select' && item.question_mode !== 'set') || (question.type === 'select' && item.question_mode === 'any')">
											<button *ngFor="let o of question.select_options" class="btn btn-sm mr-1 mb-1" type="button" [ngClass]="{ 'btn-primary': getFlag(item.question_value, o.value), 'btn-secondary': !getFlag(item.question_value, o.value) }" (click)="item.question_value = toggleFlag(item.question_value, o.value)">{{o.description}}</button>
										</ng-container>
									</ui-form-control>
								</ui-form>
							</td>
							<td class="shrink nowrap">
								<button type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="selectedProduct = null"><i class="wq wq-check"></i></button>
							</td>
						</tr>
					</ng-container>
				</tbody>
			</table>
			<p *ngIf="!question.products.length" class="text-secondary">No products.</p>
			<button type="button" class="btn btn-outline-success btn-sm" (click)="addProducts()"><i class="wq wq-add mr-2"></i>Add products</button>

			<ng-container *ngIf="question.counters.length">
				<hr>
				<h2>Accumulators</h2>
				<table class="table table-hover vam">
					<thead>
						<tr>
							<th>Description</th>
							<th class="shrink">Add</th>
							<th class="shrink nowrap">Multiply by answer</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let qc of question.counters">
							<td>{{qc.counter?.description}}</td>
							<td class="shrink"><input class="form-control text-right" style="width: 3.375rem;" [(ngModel)]="qc.value" [ngModelOptions]="{ standalone: true }" (change)="formatCounterNumbers(qc)" autocomplete="chrome-off"></td>
							<td>
								<select class="custom-select w-auto" [(ngModel)]="qc.multiply_by_question_id" [ngModelOptions]="{ standalone: true }">
									<optgroup label="Shortcuts">
										<option [ngValue]="null">Don't multiply</option>
										<option [ngValue]="question.question_id">This answer</option>
									</optgroup>
									<optgroup label="All questions">
										<option *ngFor="let q of bundle.questions" [ngValue]="q.question_id === question.question_id ? -1 : q.question_id">{{'&nbsp;&nbsp;&nbsp;'.repeat(q.depth)}}{{q.question}}</option>
									</optgroup>
								</select>
							</td>
						</tr>
					</tbody>
				</table>
			</ng-container>

		</ng-container>

		<button type="button" class="btn btn-secondary" (click)="modal.close()" ui-modal-footer>Cancel</button>
		<button type="submit" class="btn btn-primary" (click)="saveButton()" ui-modal-footer>Save</button>

	</ui-modal>
</form>

<app-media-library-modal (close)="mediaLibraryModalClosed($event)"></app-media-library-modal>
<modal-select-product (close)="productSelectModalClosed($event)"></modal-select-product>
