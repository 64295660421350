<ng-container *ngIf="data">
	<ui-grid-col weight-1></ui-grid-col>

	<ui-grid-col weight-0>
		<div class="dropdown d-inline-block">
			<button class="btn btn-sm dropdown-toggle" [ngClass]="{ 'btn-outline-silver': !data.max_quote_value_reached, 'btn-danger': data.max_quote_value_reached }" data-toggle="dropdown">
				<i *ngIf="data.max_quote_value_reached" class="wq wq-warning mr-2"></i>{{data.total.quote_price | decimal:2:2:true:data.currency_id}}<i class="wq wq-caret-down ml-2"></i>
			</button>
			<div class="dropdown-menu dropdown-menu-right bg-white shadow-sm p-3" style="border: none;">
				<table class="table table-sm m-0 vam">
					<thead>
						<tr>
							<td class="py-2 pr-0"><span class="badge badge-primary p-1 text-base"><i class="wq wq-product"></i></span></td>
							<td class="text-uppercase text-bold pl-2 nowrap">Product Cost</td>
							<td class="text-right text-bold pl-3 nowrap">{{data.total.equipment_cost | decimal:2:2:true:data.currency_id}}</td>
						</tr>
						<tr>
							<td class="py-2 pr-0"><span class="badge badge-purple p-1 text-base"><i class="wq wq-clock"></i></span></td>
							<td class="text-uppercase text-bold pl-2 nowrap">Labour Cost</td>
							<td class="text-right text-bold nowrap">{{data.total.labour_total_cost | decimal:2:2:true:data.currency_id}}</td>
						</tr>
						<tr>
							<td class="py-2 pr-0"><span class="badge badge-success p-1 text-base"><i class="wq wq-trending-up"></i></span></td>
							<td class="text-uppercase text-bold pl-2">Margin</td>
							<td class="text-right text-bold nowrap" [ngClass]="{ 'text-danger': data.total.quote_price < data.total.quote_cost }">{{data.total.quote_price - data.total.quote_cost | decimal:2:2:true:data.currency_id}}</td>
						</tr>
					</thead>
				</table>
				<div *ngIf="data.max_quote_value_reached" class="alert alert-danger mb-0 mt-3 p-2">
					<i class="wq wq-warning"></i> This quote is over your maximum allowed quote value of {{data.max_quote_value | decimal:2:2:true:data.currency_id}}.
					<a *ngIf="app.orgInfo?.role.manage_billing" class="btn btn-sm btn-primary btn-block mt-3" [routerLink]="['/', app.org, 'settings', 'billing']">Upgrade Account</a>
				</div>
			</div>
		</div>
	</ui-grid-col>

	<ui-grid-col weight-0>
		<h2 *ngIf="!titleEdit" class="m-0 text-silver text-center selectable hoverable-medium" style="max-height: 54px; overflow: hidden;" (click)="editTitle()">{{data.title || 'Untitled Quote'}}</h2>
		<div *ngIf="titleEdit" class="input-group">
			<input type="text" class="form-control" placeholder="Untitled Quote" [(ngModel)]="editedTitle" (keyup.enter)="saveTitle()" autocomplete="chrome-off" appAutoFocus>
			<span class="input-group-append">
				<button class="btn btn-success px-2" (click)="saveTitle()" [disabled]="disabled"><i class="wq wq-check"></i></button>
			</span>
			<span class="input-group-append">
				<button class="btn btn-secondary px-2" (click)="cancelTitle()" [disabled]="disabled"><i class="wq wq-close"></i></button>
			</span>
		</div>
	</ui-grid-col>

	<ui-grid-col weight-0>
		<div class="d-flex align-items-center">
			<div *ngIf="!showReviewResult" class="dropdown d-inline-block">
				<button class="btn btn-sm btn-{{stageColor(data.stage)}} dropdown-toggle" data-toggle="dropdown" [disabled]="disabled || titleEdit">
					{{stageDescription(data.stage)}}<i class="wq wq-caret-down ml-2"></i>
				</button>
				<div class="dropdown-menu dropdown-menu-right shadow-sm">
					<button *ngFor="let s of stageList()" [ngClass]="{ 'active': data.stage === s }" class="dropdown-item dropdown-item-{{stageColor(s)}}" (click)="changeStage(s)">{{stageDescription(s)}}</button>
				</div>
			</div>
			<button *ngIf="showReviewResult" class="btn btn-sm btn-{{stageColor(data.stage)}}" (click)="reviewResult()" [disabled]="disabled || titleEdit">
				<i class="wq wq-send mr-2"></i>Review Result
			</button>
			<i *ngIf="!data.editable && data.stage !== 'in_review' && data.stage !== 'passed_review'" class="wq wq-info ml-2 text-secondary" ngbTooltip="Create a new revision to make changes."></i>
		</div>
	</ui-grid-col>

	<ui-grid-col weight-1></ui-grid-col>
</ng-container>

<div class="text-dark">
	<app-core-quote-forward-modal></app-core-quote-forward-modal>
	<app-core-quote-accept-modal></app-core-quote-accept-modal>
	<app-core-quote-review-result-modal></app-core-quote-review-result-modal>
	<modal-quote-choose-project (close)="chooseProjectModalClosed($event)"></modal-quote-choose-project>
</div>
