<ng-container *ngIf="data">
	<ui-grid-col weight-1></ui-grid-col>

	<ui-grid-col weight-0>
		<div class="d-flex align-items-center">
			<div class="dropdown d-inline-block mr-1">
				<button class="btn btn-{{projectStageColor(data.record.status)}} dropdown-toggle" data-toggle="dropdown">
					{{projectStageDescription(data.record.status)}}<i class="wq wq-caret-down ml-2"></i>
				</button>
				<div class="dropdown-menu dropdown-menu-right shadow-sm">
					<button *ngFor="let s of projectStageList()" [ngClass]="{ 'active': data.stage === s }" class="dropdown-item dropdown-item-{{projectStageColor(s)}}" (click)="changeProjectStage(s)">{{projectStageDescription(s)}}</button>
				</div>
			</div>
		</div>
	</ui-grid-col>

	<ui-grid-col weight-1></ui-grid-col>
</ng-container>
