import { Component, ViewChild } from '@angular/core';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';
import { ApiService } from 'app/api.service';
import { ModalEditProjectComponent } from 'app/modal/modal-edit-project/modal-edit-project.component';

@Component({
	selector: 'modal-quote-choose-project',
	templateUrl: './modal-quote-choose-project.component.html'
})
export class ModalQuoteChooseProjectComponent extends UIModalBaseComponent {

	@ViewChild(ModalEditProjectComponent) editProjectModal: ModalEditProjectComponent;

	projects;
	customerId;
	currencyId;
	selectedId = null;

	open(data: any) {
		this.customerId = data?.customerId;
		this.currencyId = data?.currencyId;
		this.refresh();
	}

	constructor(private api: ApiService) {
		super();
	}

	refresh() {
		this.api.project.listCustomerProjects(this.customerId, list => {
			this.projects = list;
			this.modal.open();
		});
	}

	setProject() {
		this.modal.close(this.selectedId);
	}

	addProject() {
		if (this.customerId) {
			this.editProjectModal.open({
				customerId: this.customerId,
				currencyId: this.currencyId
			});
		}
	}

	editProjectModalClosed(data) {
		this.refresh();
		this.selectedId = data;
	}

}
