import { Component, ComponentFactoryResolver, Directive, ViewChild, ViewContainerRef } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';
import { ModalSelectEntityComponent } from '../modal-select-entity/modal-select-entity.component';

declare const Mangler: any;

@Directive({
	selector: '[modalEditEntity]',
})
export class ModalEditEntityDirective {
	constructor(public viewContainerRef: ViewContainerRef) { }
}


@Component({
	selector: 'modal-edit-entity',
	templateUrl: './modal-edit-entity.component.html'
})
export class ModalEditEntityComponent extends UIModalBaseComponent {

	@ViewChild(ModalSelectEntityComponent) entitySelectModal: ModalSelectEntityComponent;
	@ViewChild(ModalEditEntityDirective, { static: true }) editEntityHost: ModalEditEntityDirective;

	entitySelectModalCaller = '';

	id;
	details;
	manufacturerProductCount = 0;
	supplierProductCount = 0;
	disabled = false;

	get suppliesOwnItems() {
		if (!this.details.is_manufacturer || !this.details.is_supplier) return false;
		return !!Mangler.findOne(this.details.manufacturers, { id: this.details.id });
	}

	get hasNew() {
		return !!Mangler.findOne(this.details.suppliers, { added: true }) || !!Mangler.findOne(this.details.manufacturers, { added: true });
	}

	get hasRemoved() {
		return !!this.details.removed_suppliers.length || !!this.details.removed_manufacturers.length;
	}

	get hasRecords() {
		return !!this.details.suppliers.length || !!this.details.manufacturers.length;
	}

	get showUpdateOptions() {
		return this.hasNew || this.hasRemoved || this.hasRecords;
	}

	constructor(
		private app: AppService,
		private api: ApiService,
		private factory: ComponentFactoryResolver
	) {
		super();
	}

	open(mdata: any) {
		this.id = mdata.id || 'new';
		this.details = null;
		this.manufacturerProductCount = 0;
		this.supplierProductCount = 0;
		this.disabled = false;
		this.entitySelectModalCaller = '';

		const success = data => {
			this.details = data.details || {};
			this.manufacturerProductCount = data.manufacturer_product_count || 0;
			this.supplierProductCount = data.supplier_product_count || 0;

			this.details.is_manufacturer = !!this.details.is_manufacturer;
			this.details.is_supplier = !!this.details.is_supplier;

			this.details.suppliers.forEach(s => { s.is_primary = !!s.is_primary; });
			this.details.manufacturers.forEach(m => { m.is_primary = !!m.is_primary; });

			this.details.removed_manufacturers = [];
			this.details.removed_suppliers = [];

			// Update flags
			this.details.update_new = true;
			this.details.update_remove = true;
			this.details.update_primary = false;

			// Set new entity defaults from call data
			if (this.details.id === 'new' && mdata.is_manufacturer) this.details.is_manufacturer = true;
			if (this.details.id === 'new' && mdata.is_supplier) this.details.is_supplier = true;

			this.modal.open();
		};

		const fail = error => {
			this.app.notifications.showDanger(error.message);
		};

		if (this.id === 'new') {
			this.api.entity.new(success, fail);
		} else {
			this.api.entity.details(this.id, success, fail);
		}
	}

	save() {
		this.disabled = true;
		this.api.entity.save(this.details, savedId => {
			this.disabled = false;
			if (this.details.id === 'new') {
				this.app.notifications.showSuccess('Manufacturer created.');
			} else {
				this.app.notifications.showSuccess('Manufacturer updated.');
			}
			this.modal.close(savedId);
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

	archive() {
		if (this.id === 'new') return;

		this.app.dialog.pop('Archive Entity', 'This will remove this entity\'s manufacturer and supplier references from all products. Are you sure you want to archive this entity?', ['Cancel', '*Archive'], btn => {
			if (btn === 1) {
				this.disabled = true;
				this.api.entity.archive(this.id, () => {
					this.disabled = false;
					this.app.notifications.showSuccess('Entity archived.');
					this.modal.close('deleted');
				}, error => {
					this.disabled = false;
					this.app.notifications.showDanger(error.message);
				});
			}
		});
	}

	unarchive() {
		if (this.id === 'new') return;

		this.disabled = true;
		this.api.entity.unarchive(this.id, () => {
			this.disabled = false;
			this.app.notifications.showSuccess('Entity restored.');
			this.modal.close('restored');
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

	addThisEntity() {
		// To assign this entity to itself, it must be a manufacturer AND a supplier

		if (!this.details.is_manufacturer || !this.details.is_supplier) return;
		if (this.suppliesOwnItems) return;

		const primaryFlag = !this.details.suppliers.length;

		this.details.suppliers.push({
			id: this.details.id,
			name: this.details.name,
			posttown: this.details.posttown,
			postcode: this.details.postcode,
			is_primary: primaryFlag,
			added: true
		});

		this.details.suppliers = this.details.suppliers.slice();

		this.details.manufacturers.push({
			id: this.details.id,
			name: this.details.name,
			posttown: this.details.posttown,
			postcode: this.details.postcode,
			is_primary: primaryFlag,
			added: true
		});

		this.details.manufacturers = this.details.manufacturers.slice();
	}

	addSupplier(record) {
		// Handle the currently edited entity separately
		if (record.id === this.details.id) {
			this.addThisEntity();
			return;
		}

		this.details.suppliers.push({
			id: record.id,
			name: record.name,
			posttown: record.posttown,
			postcode: record.postcode,
			is_primary: !this.details.suppliers.length,
			added: true
		});

		this.details.suppliers = this.details.suppliers.slice();
	}

	addManufacturer(record) {
		// Handle the currently edited entity separately
		if (record.id === this.details.id) {
			this.addThisEntity();
			return;
		}

		// Supplier is not in the list, add
		this.details.manufacturers.push({
			id: record.id,
			name: record.name,
			posttown: record.posttown,
			postcode: record.postcode,
			is_primary: false,
			added: true
		});

		this.details.manufacturers = this.details.manufacturers.slice();
	}

	removeSupplier(id) {
		const item = Mangler.findOne(this.details.suppliers, { id });

		if (item) {
			const i = this.details.suppliers.indexOf(item);

			if (i !== -1) {
				this.details.suppliers.splice(i, 1);
				this.details.suppliers = this.details.suppliers.slice();

				if (!item.added) {
					this.details.removed_suppliers.push(item);
					this.details.removed_suppliers = this.details.removed_suppliers.slice();
				}

				if (this.details.suppliers.length && !Mangler.findOne(this.details.suppliers, { is_primary: true })) {
					this.setPrimarySupplier(this.details.suppliers[0].id);
				}

				if (id === this.details.id) this.removeManufacturer(id);
			}
		}
	}

	removeManufacturer(id) {
		const item = Mangler.findOne(this.details.manufacturers, { id });

		if (item) {
			const i = this.details.manufacturers.indexOf(item);

			if (i !== -1) {
				this.details.manufacturers.splice(i, 1);
				this.details.manufacturers = this.details.manufacturers.slice();

				if (!item.added) {
					this.details.removed_manufacturers.push(item);
					this.details.removed_manufacturers = this.details.removed_manufacturers.slice();
				}

				if (id === this.details.id) this.removeSupplier(id);
			}
		}
	}

	restoreSupplier(id) {
		const item = Mangler.findOne(this.details.removed_suppliers, { id });

		if (item) {
			const i = this.details.removed_suppliers.indexOf(item);

			this.details.removed_suppliers.splice(i, 1);
			this.details.removed_suppliers = this.details.removed_suppliers.slice();

			item.is_primary = false;
			this.details.suppliers.push(item);
			this.details.suppliers = this.details.suppliers.slice();

			if (id === this.details.id) this.restoreManufacturer(id);

			if (this.details.suppliers.length && !Mangler.findOne(this.details.suppliers, { is_primary: true })) {
				this.setPrimarySupplier(this.details.suppliers[0].id);
			}
		}
	}

	restoreManufacturer(id) {
		const item = Mangler.findOne(this.details.removed_manufacturers, { id });

		if (item) {
			const i = this.details.removed_manufacturers.indexOf(item);

			this.details.removed_manufacturers.splice(i, 1);
			this.details.removed_manufacturers = this.details.removed_manufacturers.slice();

			this.details.manufacturers.push(item);
			this.details.manufacturers = this.details.manufacturers.slice();

			if (id === this.details.id) this.restoreSupplier(id);
		}
	}

	modalAddSupplier() {
		this.entitySelectModalCaller = 'modalAddSupplier';
		this.entitySelectModal.open({
			filters: { is_supplier: true }
		});
	}

	modalAddManufacturer() {
		this.entitySelectModalCaller = 'modalAddManufacturer';
		this.entitySelectModal.open({
			filters: { is_manufacturer: true }
		});
	}

	entitySelectModalClosed(data) {
		if (!data) return;

		switch (this.entitySelectModalCaller) {
			case 'modalAddSupplier':
				const supplier = data;
				if (Mangler.findOne(this.details.suppliers, { id: supplier.id })) {
					this.app.notifications.showDanger('Supplier is already in the list.');
					return;
				}

				this.addSupplier(supplier);
				break;

			case 'modalAddManufacturer':
				const manufacturer = data;
				if (Mangler.findOne(this.details.manufacturers, { id: manufacturer.id })) {
					this.app.notifications.showDanger('Manufacturer is already in the list.');
					return;
				}

				this.addManufacturer(manufacturer);
				break;

			case 'newEntityManufacturer':
				const newManufacturer = data;
				if (Mangler.findOne(this.details.manufacturers, { id: newManufacturer.id })) {
					this.app.notifications.showDanger('Manufacturer is already in the list.');
					return;
				}

				this.addManufacturer(newManufacturer);
				break;

			case 'newEntitySupplier':
				const newSupplier = data;
				if (Mangler.findOne(this.details.suppliers, { id: newSupplier.id })) {
					this.app.notifications.showDanger('Supplier is already in the list.');
					return;
				}

				this.addSupplier(newSupplier);
				break;
		}
	}

	setPrimarySupplier(id) {
		this.details.suppliers.forEach(s => {
			s.is_primary = (s.id === id);
		});

		// Update primary state of this entity's record in the manufacturer table as well
		this.details.manufacturers.forEach(m => {
			if (m.id === this.details.id) m.is_primary = (m.id === id);
		});
	}

	openNewEntity(entityType) {
		// Dynamically load a new instance of this component to avoid a circular reference

		const editorFactory = this.factory.resolveComponentFactory(ModalEditEntityComponent);
		const viewContainerRef = this.editEntityHost.viewContainerRef;
		viewContainerRef.clear();

		const componentRef = viewContainerRef.createComponent<ModalEditEntityComponent>(editorFactory);

		componentRef.instance.close.subscribe(value => {
			if (value) {
				this.api.entity.details(value, entity => {
					this.entitySelectModalClosed(entity.details);
				}, error => {
					this.app.notifications.showDanger(error.message);
				});
			}

			componentRef.destroy();
		});

		const data = { id: 'new', is_manufactuer: false, is_supplier: false };

		if (entityType === 'manufacturer') {
			this.entitySelectModalCaller = 'newEntityManufacturer';
			data.is_manufactuer = true;
		}
		if (entityType === 'supplier') {
			this.entitySelectModalCaller = 'newEntitySupplier';
			data.is_supplier = true;
		}

		componentRef.instance.open(data);
	}

}
