<ui-modal modalTitle="Upgrade Account">

	<p class="text-large text-bold">Select a package below to upgrade your WeQuote account.</p>
	<div>
		<i class="wq wq-check text-success mr-1"></i> You will instantly receive the benefits of your new subscription.<br>
		<i class="wq wq-check text-success mr-1"></i> Your new monthly premium will be applied from your next bill.
	</div>

	<div class="my-3">
		<button *ngFor="let p of data?.packages" class="btn text-left h-auto w-100 my-2" [ngClass]="{ 'btn-light': selectedPackage !== p, 'btn-primary': selectedPackage === p }" (click)="selectedPackage = p">
			<span *ngIf="p.is_current" class="badge float-right" [ngClass]="{ 'badge-primary': selectedPackage !== p, 'badge-light': selectedPackage === p }">Current Package</span>
			<div class="text-uppercase">{{p.name}}</div>
			<div><span class="h2">{{p.line_price | decimal:0:2:true:'GBP'}}</span> <span *ngIf="p.cycle > 0" class="text-no-transform ml-1" [ngClass]="{ 'text-secondary': selectedPackage !== p }">/ {{p.cycle > 1 ? p.cycle : ''}} {{p.cycle_type + (p.cycle > 1 ? 's' : '')}}</span></div>
			<div *ngIf="p.value_quota" class="mt-2">
				<div class="text-no-transform">Up to {{p.value_quota | decimal:0:0:true:'GBP'}} per month in accepted quotes</div>
				<div class="text-no-transform" [ngClass]="{ 'text-secondary': selectedPackage !== p }">Quota rolls over monthly up to {{p.value_quota_rollover | decimal:0:0:true:'GBP'}}</div>
				<div *ngIf="p.max_quote_value > 0" class="text-no-transform" [ngClass]="{ 'text-secondary': selectedPackage !== p }">{{p.max_quote_value | decimal:0:0:true:'GBP'}} maximum quote value</div>
			</div>
			<div *ngIf="!p.value_quota" class="mt-2">
				<div class="text-no-transform">Unlimited accepted quotes</div>
			</div>
		</button>
	</div>

	<p *ngIf="data?.packages.length === 1" class="text-large text-bold">You're already on the largest package available.</p>

	<p class="text-secondary"><i>If you need any help or would like to discuss your package, please contact us.</i></p>


	<div class="text-small text-secondary" ui-modal-footer>All prices exclude {{app.translatedVATTerm}}.</div>
	<div class="flex-weight-1" ui-modal-footer></div>
	<button class="btn btn-medium" (click)="modal.close()" [disabled]="disabled" ui-modal-footer>Cancel</button>
	<button *ngIf="selectedPackage && selectedPackage !== currentPackage" class="btn btn-primary" (click)="upgradeButton()" [disabled]="disabled" ui-modal-footer>Upgrade to {{selectedPackage.name}}</button>

</ui-modal>
