<ui-grid-col spaced>
	<ui-card widget *ngIf="introPage">

		<ng-container *ngIf="isPreview">
			<div style="text-align: right;">
				<button class="btn btn-primary" (click)="closePreview()"> Close Preview</button>
			</div>
			<hr>
		</ng-container>

		<ui-grid-row>
			<ui-grid-col weight-2>

				<div class="py-5 pl-5">
					<div class="mb-4 text-primary" style="font-size: 3.2rem; font-weight: bold; line-height: 3.5rem;">{{introPage.title}}</div>
					<div class="mb-4" [innerHTML]="introPage.summary | safeHtml" style="font-size: 1.3rem;"></div>
					<div class="mb-5">
						<ng-container *ngFor="let bullet of introPage.bullets">
							<div class="d-flex align-items-center mb-4">
								<div style="width: 15%;">
									<div class="rounded-circle border mr-3 d-flex align-items-center" [style.background]="bullet.icon_color || 'red'" style="color: white; width: 2.5rem; height: 2.5rem; font-size: 1rem;">
										<i style="margin: 0 auto;" class="wq {{bullet.icon.length > 0 ? bullet.icon : 'wq-check'}}"></i>
									</div>
								</div>
								<div style="font-size: 1.2rem; width: 85%;" [style.color]="bullet.text_color || 'grey'">{{bullet.text}}</div>
							</div>
						</ng-container>
					</div>

					<div *ngIf="!introPage.undismissable" class="mb-5" style="width: 30%; height: 0.1rem; background-color: red;">&nbsp;</div>
					<button *ngIf="!introPage.undismissable" class="btn btn-primary btn-lg pl-5 pr-5" [ngClass]="{ 'disabled not-allowed': !enableDismissButton }" (click)="dismissIntroPage()" style="font-size: 1.2rem;">{{introPage.button_text}}</button>
				</div>
			</ui-grid-col>

			<ui-grid-col weight-3>
				<div *ngIf="introPage" class="py-5 pr-5">
					<ui-video [videoURL]="introPage.video_url" (videoEnded)="onVideoEnded()" [showControls]="false"></ui-video>
					<div class="mt-2">
						<ng-container *ngFor="let extended_video of introPage.extended_videos">
							<button *ngIf="extended_video.video_url && extended_video.button_text" class="btn btn-primary btn-lg pl-2 pr-2 mt-2 mr-2" [ngClass]="{ 'disabled not-allowed': !enableDismissButton }" (click)="watchExtendedVideo(extended_video.video_url)" style="font-size: 0.7rem;">{{extended_video.button_text}}</button>
						</ng-container>
					</div>

				</div>
			</ui-grid-col>
		</ui-grid-row>

	</ui-card>
</ui-grid-col>
