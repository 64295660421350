<form>
	<ui-modal [modalTitle]="title" size="lg">
		<ui-alert color="info" flag="contentMenuTip">To access the system context menu within the editor (e.g. <i>Copy</i>, <i>Paste</i>, <i>Spell Check</i>), press <b>Ctrl + Right Click</b>.</ui-alert>

		<ng-container *ngIf="data && modal.isOpen">
			<ui-form labelWidth="10rem">

				<ui-form-control label="Name" *ngIf="app.orgInfo?.role.is_elevated">
					<input class="form-control" [(ngModel)]="data.name" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off" appAutoFocus>
				</ui-form-control>

				<ui-form-control label="Title">
					<input class="form-control" [(ngModel)]="data.title" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>

				<ui-form-control label="Subtitle">
					<input class="form-control" [(ngModel)]="data.subtitle" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>

				<ui-form-control label="Content">
					<editor [init]="app.tinyInit" [(ngModel)]="data.content" [ngModelOptions]="{ standalone: true }"></editor>
					<p class="text-info mt-1"><i class="wq wq-info mr-2"></i>Content is shown when users click the alert notification.</p>
				</ui-form-control>

				<ui-form-control label="Image">
					<button *ngIf="!data.image_url" type="button" class="btn btn-medium" (click)="openMediaLibrary()"><i class="wq wq-edit mr-2"></i>Select Image</button>
					<div *ngIf="data.image_url" class="img-container d-flex hover-parent" [style.backgroundImage]="'url(\'' + data.image_url + '\')' | safeStyle">
						<div class="hover-child d-inline-block" style="margin: auto;">
							<button type="button" class="btn btn-medium mr-1" (click)="openMediaLibrary()"><i class="wq wq-edit mr-2"></i>Select Image</button>
							<button type="button" class="btn btn-danger" (click)="removeImage()"><i class="wq wq-delete mr-2"></i>Remove Image</button>
						</div>
					</div>
				</ui-form-control>

				<ui-form-control label="Button Text">
					<input class="form-control" [(ngModel)]="data.button_text" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>

				<ui-form-control label="Button URL">
					<input class="form-control" [(ngModel)]="data.button_url" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>

				<ui-form-control label="Location">
					<select class="custom-select" [(ngModel)]="data.location_id" [ngModelOptions]="{ standalone: true }">
						<option [ngValue]="null">Any Location</option>
						<option *ngFor="let route of routesList" [ngValue]="route.id">{{route.description}}</option>
					</select>
				</ui-form-control>

				<ui-form-control label="Account Type">
					<ng-container *ngFor="let account of accountType">
						<button type="button" class="btn btn-lg mr-2 {{typeColor(account)}}" (click)="toggleAccountType(account)" [ngModelOptions]="{ standalone: true }">
							{{ account.name }}
						</button>
					</ng-container>
				</ui-form-control>

				<ui-form-control label="Organisation Level">
					<label class="custom-control custom-checkbox mt-2">
						<input type="checkbox" class="custom-control-input" [(ngModel)]="data.is_org_level" [ngModelOptions]="{ standalone: true }">
						<span class="custom-control-label">Checking this will create an organisation level alert</span>
					</label>
				</ui-form-control>

				<hr>

				<ui-form-control label="Visibility">
					<label class="custom-control custom-checkbox mt-2">
						<input type="checkbox" class="custom-control-input" [(ngModel)]="data.is_global" [ngModelOptions]="{ standalone: true }">
						<span class="custom-control-label">Global broadcast</span>
					</label>
					<label class="custom-control custom-checkbox mt-2">
						<input type="checkbox" class="custom-control-input" [ngModel]="!!data.archived" (ngModelChange)="data.archived = $event ? 1 : 0" [ngModelOptions]="{ standalone: true }">
						<span class="custom-control-label">Archived</span>
					</label>
				</ui-form-control>

				<div *ngIf="!data.is_global" class="alert alert-success"><i class="wq wq-info mr-1"></i> Alert will be shown to <b>everyone</b> in the <b>current account</b>.</div>
				<div *ngIf="data.is_global" class="alert alert-warning"><i class="wq wq-info mr-1"></i> Global alerts will be shown to <b>all users</b> across <b>all accounts</b>.</div>
			</ui-form>
		</ng-container>

		<button *ngIf="id !== 'new'" type="button" class="btn btn-danger" (click)="deleteButton()" [disabled]="disabled" ui-modal-footer>Delete</button>
		<div class="flex-weight-1" ui-modal-footer></div>
		<button type="button" class="btn btn-secondary" (click)="modal.close()" [disabled]="disabled" ui-modal-footer>Cancel</button>
		<button type="submit" class="btn btn-primary" (click)="saveButton()" [disabled]="disabled" ui-modal-footer>Save</button>
	</ui-modal>
</form>
