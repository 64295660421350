<ui-modal size="xl">
	<ui-grid-row *ngIf="alert" [@fadeInOut]>

		<ui-grid-col weight-0>
			<div class="d-flex align-items-center mr-2" style="font-size: 1.2rem; height: 100%;">
				<i class="wq wq-arrow-left" [ngClass]="{'disabled': currentIndex === 0}" (click)="prevAlert()"
					style="cursor: pointer;"></i>
			</div>
		</ui-grid-col>
		<ui-grid-col weight-2 class="mr-5">
			<div class="py-3 pl-3">
				<div class="mb-2" style="font-size: 1.2rem; font-weight: bold;">{{alert.subtitle}}</div>
				<div class="mb-3" style="font-size: 3.2rem; font-weight: bold; line-height: 3.5rem;">{{alert.title}}
				</div>
				<div *ngIf="alert.content" class="mb-3" [innerHTML]="alert.content | safeHtml"
					style="font-size: 1.2rem;"></div>
			</div>
		</ui-grid-col>

		<ui-grid-col weight-3 *ngIf="alert.image_url">
			<div class="mr-3 mb-3" *ngIf="alert.image_url" style="background-image: url('{{alert.image_url}}'); 
			background-position: left center; background-repeat: no-repeat; background-size: contain; height: 30rem; 
			border-radius: 0.25rem;"></div>
		</ui-grid-col>

		<ui-grid-col weight-0>
			<div class="d-flex align-items-center ml-2" style="font-size: 1.2rem; height: 100%; ">
				<i (click)="nextAlert()" [ngClass]="{'disabled': currentIndex === (data.length - 1)}"
					class="wq wq-arrow-right" style="cursor: pointer;"></i>
			</div>
		</ui-grid-col>
	</ui-grid-row>

	<ui-grid-row *ngIf="alert" class="d-flex align-items-center mt-4 px-5">
		<ui-grid-col weight-2>
			<div *ngIf="alert.button_text && alert.button_url" style="text-align: left;">
				<button class="btn btn-primary px-5"
					(click)="openButton(alert.button_url)">{{alert.button_text}}</button>
			</div>
		</ui-grid-col>

		<ui-grid-col weight-1>
			<div class="alert-dots d-flex align-items-center" *ngIf="data && data.length > 1 && !alert.is_org_level"
				style="margin: 0 auto;">
				<span *ngFor="let alert of data; let i = index" class="mr-3" (click)="goToAlert(i)"
					[class.active]="currentIndex === i"></span>
			</div>
		</ui-grid-col>

		<ui-grid-col weight-2>
			<div style="text-align: right;" *ngIf="!alert.is_org_level">
				<button *ngIf="app.alertCount" class="btn btn-secondary mr-2" (click)="dismissAllByLocation()"><i
						class="wq wq-check mr-2"></i>Dismiss All</button>
				<button class="btn btn-primary px-5" *ngIf="app.orgInfo?.role.is_elevated" (click)="viewAllAlerts()"
					style="position: relative;">
					<i class="wq wq-alert mr-3"></i>
					<span *ngIf="app.alertCount" class="badge badge-primary p-1 rounded-circle"
						style="position: absolute; top: 7%; left: 26%; font-size: 0.6rem;">{{app.alertCount}}</span>
					View All Alerts
				</button>
			</div>
		</ui-grid-col>

	</ui-grid-row>
</ui-modal>