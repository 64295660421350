<form>
	<ui-modal [modalTitle]="recordEditor ? 'Pricing Level' : (id === 'new' ? 'New Pricing Level' : 'Pricing Level Details')" size="lg">
		<ng-container *ngIf="details">
			<ui-form *ngIf="!recordEditor" labelWidth="10rem">
				<ui-form-control label="Description">
					<input class="form-control" [(ngModel)]="details.description" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off" appAutoFocus>
				</ui-form-control>
				<hr>
			</ui-form>
			<table class="table vam">
				<thead>
					<tr>
						<th class="shrink nowrap">Price tier</th>
						<th class="border-left">Base price</th>
						<th colspan="2" class="border-left">Calculation method</th>
						<th class="border-left">Decimal places</th>
						<th class="border-left">Minimum price</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td class="text-bold shrink nowrap">Sell Price</td>

						<td class="border-left shrink">
							<select class="custom-select" [(ngModel)]="details.base_price" [ngModelOptions]="{ standalone: true }" style="width: auto;" (change)="formatNumbers()">
								<option value="cost_price">Cost Price</option>
								<option value="srp_price">MSRP</option>
							</select>
						</td>
						<td class="border-left shrink">
							<select class="custom-select" [(ngModel)]="details.pricing_method" [ngModelOptions]="{ standalone: true }" style="width: auto;" (change)="formatNumbers()">
								<option *ngIf="!recordEditor" value="custom">Custom price</option>
								<option *ngIf="!recordEditor" value="bom">BOM</option>
								<option value="markup">Markup %</option>
								<option value="margin">Margin %</option>
								<option value="profit">Profit</option>
							</select>
						</td>
						<td class="pl-0">
							<input *ngIf="['markup', 'margin', 'profit'].indexOf(details.pricing_method) !== -1" class="form-control text-right" [(ngModel)]="details.pricing_value" [ngModelOptions]="{ standalone: true }" (input)="refreshSample()" (change)="formatNumbers()" autocomplete="chrome-off">
						</td>
						<td class="border-left">
							<input class="form-control text-right" [(ngModel)]="details.decimal_places" [ngModelOptions]="{ standalone: true }" (input)="refreshSample()" (change)="formatNumbers()" autocomplete="chrome-off">
						</td>
						<td class="border-left">
							<input *ngIf="details.pricing_method !== 'custom'" class="form-control text-right" [(ngModel)]="details.minimum_price" [ngModelOptions]="{ standalone: true }" (input)="refreshSample()" (change)="formatNumbers()" autocomplete="chrome-off">
						</td>
					</tr>
				</tbody>
			</table>
			<p *ngIf="!recordEditor" class="text-info mb-0">
				<i class="wq wq-info mr-2"></i>If the calculation method is set to BOM, it is the sum of all bill of material items.
			</p>

			<hr>

			<h3>Test your pricing level</h3>

			<table class="table vam">
				<thead>
					<tr>
						<th class="text-center shrink nowrap">Cost Price</th>
						<th class="text-center">Sell Price</th>
						<th class="text-center shrink nowrap">MSRP</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td class="text-center shrink"><input class="form-control text-right" style="width: 15.625rem;" [(ngModel)]="sample.cost_price" [ngModelOptions]="{ standalone: true }" (input)="refreshSample()" (change)="formatNumbers()" autocomplete="chrome-off"></td>
						<td *ngIf="sample.sell_message" class="subtitle text-center">{{sample.sell_message}}</td>
						<td *ngIf="!sample.sell_message" class="text-center"><b>{{sample.sell_price}}</b></td>
						<td class="text-center shrink"><input class="form-control text-right" style="width: 15.625rem;" [(ngModel)]="sample.srp_price" [ngModelOptions]="{ standalone: true }" (input)="refreshSample()" (change)="formatNumbers()" autocomplete="chrome-off"></td>
					</tr>
				</tbody>
			</table>
		</ng-container>

		<button *ngIf="details && id !== 'new' && !linkCount && !recordEditor" type="button" class="btn btn-danger" (click)="delete()" [disabled]="disabled" ui-modal-footer>Delete</button>
		<div class="flex-weight-1" ui-modal-footer></div>
		<button type="button" class="btn btn-medium" (click)="modal.close()" [disabled]="disabled" ui-modal-footer>Cancel</button>
		<button type="submit" class="btn btn-primary" (click)="save()" [disabled]="disabled" ui-modal-footer>Save</button>
	</ui-modal>
</form>
