import { Component } from '@angular/core';
import { AppService } from 'app/app.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

declare const Mangler: any;

@Component({
	selector: 'modal-edit-contact-delivery-address',
	templateUrl: './modal-edit-contact-delivery-address.component.html'
})
export class ModalEditContactDeliveryAddressComponent extends UIModalBaseComponent {

	data;
	editable = true;

	constructor(
		public app: AppService
	) {
		super();
		this.editable = !this.app.orgInfo.disallow_contact_edit;
	}

	open(mdata: any) {
		this.data = Mangler.clone(mdata);
		this.modal.open();
	}

	saveButton() {
		if (!this.data.address_line_1 && !this.data.address_line_2 && !this.data.postcode && !this.data.country) {
			this.app.notifications.showDanger('Please enter the address.');
			return;
		}
		this.modal.close(this.data);
	}

}
