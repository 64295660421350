<div class="mr-3">
	<ui-grid-row>
		<ui-grid-col>
			<h1 class="pb-1">Webhooks</h1>
			<ui-card widget header="What is a webhook?">
				WeQuote allows you to "subscribe" a 3rd-party service to receive changes. When a subscribed event is triggered WeQuote will queue the event to notify any subscribed 3rd-party services.<br>
				It may take a few minutes to fire your event depending on how long the queue is.
			</ui-card>
		</ui-grid-col>
	</ui-grid-row>
	<h2 class="pt-2 pb-2">Requests</h2>
	<ui-grid-row>
		<ui-grid-col>
			<ui-card widget header="webhook/subscribe">
				<div>
					<i>Notify WeQuote that a 3rd-party service is listening for changes</i>
				</div>
				<div class="pt-2">
					<hr>
					<h4>Request</h4>
					<table>
						<tr>
							<td class="p-3 border-right">
								type
							</td>
							<td class="pl-2">POST</td>
						</tr>
						<tr>
							<td class="p-3 border-right">
								parameters
							</td>
							<td class="pl-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2 pr-2 border-right">Required</th>
										<th class="pl-2, pr-2 text-center">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">url</td>
										<td class="pl-2 pr-2 border-right">string</td>
										<td class="pl-2 pr-2 border-right">true</td>
										<td class="pl-3 pr-2 text-left">The URL where WeQuote should send changes</td>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">type</td>
										<td class="pl-2 pr-2 border-right">string</td>
										<td class="pl-2 pr-2 border-right">true</td>
										<td class="pl-3 pr-2 text-left">The event you want this webhook to listen for</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td class="pl-3 border-right">
								response
							</td>
							<td class="pl-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2, pr-2 text-center ">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">id</td>
										<td class="pl-2 pr-2 border-right">number</td>
										<td class="pl-3 pr-2 text-left">The id number of your newly created subscription</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td class="p-3 border-right">
								Errors
							</td>
							<td class="pl-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Code</th>
										<th class="pl-2 pr-2 border-right">Text</th>
										<th class="pl-2, pr-2 text-center">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right"></td>
										<td class="pl-2 pr-2 border-right">Missing Parameters</td>
										<td class="pl-3 pr-2 text-left">Your request's POST body did not include a JSON object with all the required parameters</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>

				</div>
			</ui-card>
		</ui-grid-col>
	</ui-grid-row>
	<ui-grid-row class="pt-3">
		<ui-grid-col>
			<ui-card widget header="webhook/unsubscribe">
				<div>
					<i>Notify WeQuote that a service is no longer listening for events</i>
				</div>
				<div class="pt-2">
					<hr>
					<h4>Request</h4>
					<table>
						<tr>
							<td class="p-3 border-right">
								type
							</td>
							<td class="pl-2">POST</td>
						</tr>
						<tr>
							<td class="p-3 border-right">
								parameters
							</td>
							<td class="pl-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2 pr-2 border-right">Required</th>
										<th class="pl-2, pr-2 text-center">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">id</td>
										<td class="pl-2 pr-2 border-right">number</td>
										<td class="pl-2 pr-2 border-right">true</td>
										<td class="pl-3 pr-2 text-left">
											The id of the subscribed webhook you wish to unsubscribe
										</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td class="pl-3 border-right">
								response
							</td>
							<td class="pl-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Name</th>
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2, pr-2 text-center ">Description</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">hook_id</td>
										<td class="pl-2 pr-2 border-right">number</td>
										<td class="pl-3 pr-2 text-left">The id number of your newly created subscription</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td class="p-3 border-right">
								Errors
							</td>
							<td class="pl-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pr-2 border-right">Code</th>
										<th class="pl-2 pr-2 border-right">Text</th>
										<th class="pl-2, pr-2 text-center">Description</th>
									</tr>
									<tr class="border-bottom">
										<td class="pr-2 border-right"></td>
										<td class="pl-2 pr-2 border-right">Missing id</td>
										<td class="pl-3 pr-2 text-left">Your request's POST body did not include a JSON object with the <code>hook_id</code></td>
									</tr>
									<tr class="border-bottom">
										<td class="pr-2 border-right"></td>
										<td class="pl-2 pr-2 border-right">Subscription not found</td>
										<td class="pl-3 pr-2 text-left">The hook_id you used does not exist on our system</td>
									</tr>
									<tr class="border-bottom">
										<td class="pr-2 border-right">403</td>
										<td class="pl-2 pr-2 border-right">Access Denied: Key belongs to another organisation</td>
										<td class="pl-3 pr-2 text-left">The Api-Key in your request's header does not belong to your organisation</td>
									</tr>

									<tr class="border-bottom">
										<td class="pr-2 border-right">403</td>
										<td class="pl-2 pr-2 border-right">Access Denied: Invalid API Key</td>
										<td class="pl-3 pr-2 text-left">The Api-Key in your request's header is no longer valid. It may have been deleted by someone else in your organisation</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>
				</div>
			</ui-card>
		</ui-grid-col>
	</ui-grid-row>
	<ui-grid-row class="pt-3">
		<ui-grid-col>
			<ui-card widget header="Events">
				<div>
					<i>Here you can find a list of event types that can be used when subscribing to a webhook</i><br>
					This list is expanding. If you need an event not listed here, please reach out with a feature request
				</div>
				<div class="pt-2">
					<hr>
					<h4>Events</h4>
					<table>
						<tr>
							<td class="p-3 border-right">
								parameters
							</td>
							<td class="pl-2">
								<table class="table">
									<tr class="border-bottom">
										<th class="pl-2 pr-2 border-right">Type</th>
										<th class="pl-2, pr-2 text-center border-right">Description</th>
										<th class="pl-2, pr-2, text-center">Payload</th>
									</tr>
									<tr class="border-bottom text-center">
										<td class="pr-2 border-right">quote_status_updated</td>
										<td class="pl-3 pr-2 text-left border-right">
											This event is fired when the status of a quote is changed
										</td>
										<td class="text-left">
											<pre><code>&lcub; <br>    'quote_id': 1,<br>    'old_stage': 'in_progress', <br>    'new_stage': 'in_progress', <br>    'quote_status': 'sent'<br>&rcub;
										</code></pre>
										</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>
				</div>
			</ui-card>
		</ui-grid-col>
	</ui-grid-row>
</div>
