import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';
import { ModalEditCustomerComponent } from '../modal-edit-customer/modal-edit-customer.component';
import { ModalSelectCustomerComponent } from '../modal-select-customer/modal-select-customer.component';

declare const Mangler: any;

@Component({
	selector: 'modal-edit-project',
	templateUrl: './modal-edit-project.component.html'
})
export class ModalEditProjectComponent extends UIModalBaseComponent {

	@ViewChild(ModalEditCustomerComponent) customerEditModal: ModalEditCustomerComponent;
	@ViewChild(ModalSelectCustomerComponent) selectCustomerModal: ModalSelectCustomerComponent;
	@ViewChild('multiselectdropdown') multiselectdropdown!: ElementRef;

	id;
	customerId;
	currencyId;
	data;
	disabled = false;
	userList = [];

	selectedLabels = [];
	labelList = [];
	isDropdownOpen = false;
	constructor(
		public app: AppService,
		private api: ApiService,
	) {
		super();
	}

	open(mdata: any) {
		this.id = mdata.id || 'new';
		this.customerId = mdata.customerId || null;
		this.currencyId = mdata.currencyId || null;
		this.data = null;
		this.disabled = false;
		this.isDropdownOpen = false;

		const success = data => {
			this.data = data;
			this.userList = data.users;

			if (this.currencyId) {
				this.data.project.currency_id = this.currencyId;
			}

			this.selectedLabels = Mangler.clone(data.project.labels);
			this.labelList = Mangler.clone(data.label_list);
			// Filter out labels from label_list that are already selected
			this.labelList = this.labelList.filter(label => (!this.selectedLabels.some(selectedLabel => selectedLabel.id === label.id)));
			this.modal.open();
		};

		const fail = error => {
			this.app.notifications.showDanger(error.message);
		};

		if (this.id === 'new') {
			this.api.project.new(this.customerId, success, fail);
		} else {
			this.api.project.get(this.id, success, fail);
		}
	}

	toggleSelection(label: any) {
		const index = this.selectedLabels.findIndex(selectedLabel => selectedLabel.id === label.id);
		if (index !== -1) {
			// Remove the label from selectedLabels and add it back to labelList
			this.selectedLabels.splice(index, 1);
			this.labelList.push(label); // Corrected variable name
		} else {
			// Add the label to selectedLabels and remove it from labelList
			this.selectedLabels.push(label);
			const labelIndex = this.labelList.findIndex(item => item.id === label.id); // Find index of label in label_list
			if (labelIndex !== -1) {
				this.labelList.splice(labelIndex, 1); // Remove label from label_list
			}
		}
	}

	toggleDropdown() {
		this.isDropdownOpen = !this.isDropdownOpen;
	}

	@HostListener('document:click', ['$event'])
	handleClickOutside(event: Event) {
		if (this.multiselectdropdown && !this.multiselectdropdown.nativeElement.contains(event.target)) {
			this.isDropdownOpen = false;
		}
	}

	deleteButton() {
		this.app.dialog.pop('Delete Project', 'Are you sure you want to delete this project?', ['Cancel', '*Delete'], button => {
			if (button === 1) {
				this.disabled = true;
				this.api.project.delete(this.id, () => {
					this.disabled = false;
					this.app.notifications.showSuccess('Project deleted.');
					this.modal.close('deleted');
				}, error => {
					this.disabled = false;
					this.app.notifications.showDanger(error.message);
				});
			}
		});
	}

	saveButton() {
		if (!this.data) return;

		this.data.project.labels = this.selectedLabels;

		this.disabled = true;
		this.api.project.save(this.data.project, newId => {
			this.disabled = false;
			this.app.notifications.showSuccess('Project saved.');
			this.modal.close(newId);
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

	refreshCustomerName() {
		this.data.customer_name = '';
		if (!this.data.project.customer_id) return;

		this.api.customer.details(this.data.project.customer_id, data => {
			if (this.data.project.customer_id === data.record.id) {
				this.data.customer_name = data.record.name;
			}
		});

		this.app.tutorial?.validateStep(403);
	}

	addCustomer() {
		this.customerEditModal.open({ id: 'new' });
	}

	customerEditModalClosed(id) {
		if (id) {
			this.data.project.customer_id = id;
			this.refreshCustomerName();
		}
	}

	selectCustomerModalClosed(data) {
		if (!data) return;

		this.data.project.customer_id = data.id;
		this.data.customer_name = data.name;
	}

}
