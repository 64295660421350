import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	selector: '[uiEllipsisTooltip]'
})
export class UIEllipsisTooltipDirective {

	constructor(private elementRef: ElementRef) { }

	@HostListener('mouseenter')
	onMouseEnter(): void {
		const element = this.elementRef?.nativeElement;
		if (element && (element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight)) {
			element.title = element.textContent.trim();
		} else if (element.title) {
			element.removeAttribute('title');
		}
	}

}
