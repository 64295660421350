<div class="alert-container">
	<ui-wait *ngIf="!list" class="m-3"></ui-wait>

	<ng-container *ngIf="list?.length">
		<div style="max-height: calc(100vh - 120px); overflow-y: auto;">
			<table class="table table-hover m-0">
				<tbody>
					<tr *ngFor="let item of list" class="selectable" (click)="openAlert(item.id)">
						<td>
							<span *ngIf="!item.dismissed" class="badge badge-primary float-right mt-1 ml-2">New</span>
							<div class="text-large" [ngClass]="{ 'text-bold': !item.dismissed }">{{item.title}}</div>
							<div class="text-small text-secondary">{{item.created_datetime | mySQLDateToISO | localizedDate:'shortDate'}} {{item.created_datetime | mySQLDateToISO | localizedDate:'shortTime'}}</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		
		<div class="d-flex mt-2">
			<button class="btn btn-secondary flex-weight-1 mr-2" (click)="dismissAll()"><i class="wq wq-check mr-2"></i>Dismiss All</button>
			<button class="btn btn-primary flex-weight-1" [routerLink]="['/', app.org, 'alert']"><i class="wq wq-search mr-2"></i>View All Alerts</button>
		</div>
	</ng-container>

	<div *ngIf="list && !list.length" class="text-secondary p-3">No alerts.</div>
	<div *ngIf="list && !list.length && app.orgInfo?.role.is_elevated" class="d-flex mt-2">
		<button class="btn btn-purple flex-weight-1" [routerLink]="['/', app.org, 'alert']"><i class="wq wq-search mr-2"></i>View All Alerts</button>
	</div>
</div>
