import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';

@Component({
	selector: 'app-alert-embed-list',
	templateUrl: './alert-embed-list.component.html',
	styleUrls: ['./alert-embed-list.component.scss']
})
export class AlertEmbedListComponent implements OnInit {

	@Input() popupAlertModal;
	list = null;

	constructor(
		public app: AppService,
		private api: ApiService,
		private router: Router
	) { }

	ngOnInit(): void {
	}

	refresh(clear = true) {
		if (clear) this.list = null;
		this.api.alert.listRecent(list => {
			this.list = list;
		});
	}

	dismissAll() {
		this.api.alert.dismissAll(() => {
			this.api.refreshAlerts();
			this.refresh(false);
		});
	}

	openAlert(id) {
		this.api.alert.details(id, data => {
			this.api.alert.dismiss(id, () => {
				this.api.refreshAlerts();
			});

			switch (data.alert_type) {
				case 'wequote':
					this.popupAlertModal.open([data]);
					break;

				case 'new_snagg_user':
					this.app.dialog.pop('New Snagg User', data.title, ['Close', '*View Snagg Users'], button => {
						if (button === 1) {
							this.router.navigate(['/', this.app.org, 'settings', 'integration']);
						}
					});
					break;

				case 'catalogue_approve':
					this.app.dialog.pop('Catalogue Request Approved', data.title, ['Close', '*View Linked Catalogues'], button => {
						if (button === 1) {
							this.router.navigate(['/', this.app.org, 'catalogue', 'linked']);
						}
					});
					break;

				case 'catalogue_reject':
					this.app.dialog.pop('Catalogue Request Rejected', data.title, ['Close', '*View Linked Catalogues'], button => {
						if (button === 1) {
							this.router.navigate(['/', this.app.org, 'catalogue', 'linked']);
						}
					});
					break;

				case 'catalogue_request':
					this.app.dialog.pop('Catalogue Request', data.title, ['Close', '*View Catalogue Requests'], button => {
						if (button === 1) {
							this.router.navigate(['/', this.app.org, 'catalogue', 'subscriptions']);
						}
					});
					break;

				case 'quote_accept':
					this.app.dialog.pop('Quote Accepted', data.title, ['Close', '*View Quote Summary'], button => {
						if (button === 1) {
							this.router.navigate(['/', this.app.org, 'quote', data.quote_id]);
						}
					});
					break;

				case 'quote_review_approve':
					this.app.dialog.pop('Quote Approved', data.title, ['Close', '*View Quote Summary'], button => {
						if (button === 1) {
							this.router.navigate(['/', this.app.org, 'quote', data.quote_id]);
						}
					});
					break;

				case 'quote_review_reject':
					this.app.dialog.pop('Quote Rejected', data.title, ['Close', '*View Quote Summary'], button => {
						if (button === 1) {
							this.router.navigate(['/', this.app.org, 'quote', data.quote_id]);
						}
					});
					break;

				case 'quote_comment':
					this.app.dialog.pop('Customer Comment on Quote', data.title, ['Close', '*View Quote Summary'], button => {
						if (button === 1) {
							this.router.navigate(['/', this.app.org, 'quote', data.quote_id]);
						}
					});
					break;
			}
		});
	}

}
